import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import Input from 'antd/lib/input/Input';
import get from 'lodash.get';
import { useState } from 'react';
import {
  Cardtable,
  errorNotification,
  successNotification,
} from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { cancellationReasonsActions } from '../../../redux/actions';
import { formatDate } from '../../../util';

const StoreCancellation = () => {
  const { reasons, reasonsLoader, postLoader } = useAppSelector(
    (state: any) => state.cancellationReasons
  );
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [param, setParam] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState();

  const getData = (params: any) => {
    dispatch(cancellationReasonsActions.getCancellationReasons(params));
    setParam(params);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const formSubmit = (value: any) => {
    new Promise((resolve, reject) => {
      if (isEdit) {
        value.id = id;
        resolve(dispatch(cancellationReasonsActions.updateReason(value)));
      } else {
        resolve(dispatch(cancellationReasonsActions.postReason(value)));
      }
      reject();
    })
      .then(() => {
        setVisible(false);
        successNotification({
          message: 'Success!!',
          description: 'Form submitted successfully!',
          placement: 'bottomLeft',
        });
        form.resetFields();
        getData(param);
      })
      .catch(err => {
        errorNotification({
          message: 'Error!!',
          description: err,
        });
      });
  };

  const heading = () => (
    <Row justify="space-between">
      <Col>
        <Typography.Text strong>Cancellation Reasons</Typography.Text>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => {
            form.setFieldsValue({ type: 'employee' });
            setIsEdit(false);
            setVisible(true);
          }}
          icon={<PlusOutlined />}
        >
          Add More
        </Button>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      type: 'input',
      showSearch: true,
      width: 80,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      type: 'input',
      showSearch: true,
      width: 100,
      render: (text: string, row: any) => (
        <Button
          type="link"
          style={{ whiteSpace: 'normal', padding: '0', textAlign: 'left' }}
          onClick={() => {
            form.setFieldsValue(row);
            setIsEdit(true);
            setVisible(true);
            setId(row.id);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      type: 'select',
      showSearch: true,
      width: 80,
      options: ['employee', 'retailer', 'customer', 'system'],
    },
    {
      title: 'Position',
      dataIndex: 'position',
      type: 'input',
      showSearch: true,
      width: 80,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      type: 'select',
      showSearch: true,
      render: (val: boolean) => (val ? 'Active' : 'Inactive'),
      width: 80,
      options: [
        { id: '1', name: 'Active' },
        { id: '0', name: 'Inactive' },
      ],
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      type: 'input',
      sorter: true,
      showSearch: true,
      width: 100,
      render: (text: string) => formatDate(text, 'lll'),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      type: 'input',
      sorter: true,
      showSearch: true,
      width: 100,
      render: (text: string) => formatDate(text, 'lll'),
    },
  ];
  return (
    <>
      <Cardtable
        total={get(reasons, 'count', 0)}
        getData={getData}
        // @ts-ignore
        title={heading}
        loading={reasonsLoader}
        rowKey={(row: any) => row.id}
        dataSource={get(reasons, 'results', [])}
        assignedKeys={get(reasons, 'assignedKeys', [])}
        columns={columns}
        showPagination
      />
      <Drawer
        title={`${isEdit ? 'Update' : 'Add'} Cancellation Reason`}
        placement="right"
        destroyOnClose
        width={720}
        closable
        onClose={onClose}
        visible={visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Button
            onClick={() => {
              form.submit();
            }}
            type="primary"
            htmlType="submit"
            loading={postLoader}
          >
            {isEdit ? 'Update' : 'Add'}
          </Button>
        }
      >
        <Form layout="vertical" form={form} onFinish={formSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter title',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the type',
                  },
                ]}
              >
                <Select placeholder="Please choose the type" disabled>
                  <Select.Option value="employee">employee</Select.Option>
                  <Select.Option value="retailer">retailer</Select.Option>
                  <Select.Option value="customer">customer</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="position"
                label="Position"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the position',
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please enter position"
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: 'Please enter status',
                  },
                ]}
              >
                <Select
                  placeholder="Please choose the status"
                  disabled={isEdit}
                >
                  <Select.Option value={0}>inactive</Select.Option>
                  <Select.Option value={1}>active</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default StoreCancellation;
