import RULE_API from '../../../api/ruleAPI';
import { RuleActionTypes } from '../ActionTypes';

const getAllRules = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.GET_RULES,
    });
    const res = await RULE_API.getRules(params);
    dispatch({
      type: RuleActionTypes.GET_RULES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateCoupon = (data: any) => async (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.UPDATE_COUPON,
  });
  return RULE_API.postUpdateCoupon(data).then(response => {
    dispatch({
      type: RuleActionTypes.UPDATE_COUPON_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

const getRule = (ruleId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.GET_RULE,
    });
    const res = await RULE_API.getRule(ruleId);
    dispatch({
      type: RuleActionTypes.GET_RULE_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.GET_RULE_FAILURE,
      payload: e,
    });
  }
};

const createRule = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.CREATE_RULE,
    });
    const res = await RULE_API.createRule(data);
    if (res) {
      dispatch({
        type: RuleActionTypes.CREATE_RULE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: RuleActionTypes.CREATE_RULE_FAILED,
        payload: res,
      });
    }
  } catch (e) {
    dispatch({
      type: RuleActionTypes.CREATE_RULE_FAILED,
      payload: e,
    });
  }
};

const updateRule = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.UPDATE_RULE,
    });
    const res = await RULE_API.updateRule(data);
    if (res) {
      dispatch({
        type: RuleActionTypes.UPDATE_RULE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: RuleActionTypes.UPDATE_RULE_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: RuleActionTypes.UPDATE_RULE_FAILED,
      payload: e,
    });
  }
};

const getRuleCoupons = (ruleId: any, params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.GET_RULE_COUPON,
    });
    const res = await RULE_API.getRuleCoupon(ruleId, params);
    dispatch({
      type: RuleActionTypes.GET_RULE_COUPON_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateRuleCoupon = (data: any) => async (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.UPDATE_RULE_COUPON,
  });
  return RULE_API.postUpdateCoupon(data).then(response => {
    dispatch({
      type: RuleActionTypes.UPDATE_RULE_COUPON_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

const exportRules = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.EXPORT_CSV,
    });
    const res = await RULE_API.exportRules(params);
    dispatch({
      type: RuleActionTypes.EXPORT_CSV_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.EXPORT_CSV_FAILURE,
      payload: e,
    });
  }
};

const exportCoupon = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.EXPORT_COUPON_CSV,
    });
    const res = await RULE_API.exportCoupon(params);
    dispatch({
      type: RuleActionTypes.EXPORT_COUPON_CSV_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.EXPORT_COUPON_CSV_FAILURE,
      payload: e,
    });
  }
};

// const updateRuleCoupon = (data: any) => async (dispatch: any) => {
//   dispatch({
//     type: RuleActionTypes.UPDATE_RULE_COUPON_SUCCESS,
//     payload: data,
//   });
// };

const resetRule = (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.RESET_RULE,
  });
};

const resetCoupon = (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.RESET_COUPON,
  });
};

const resetExport = (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.RESET_EXPORT,
  });
};

const resetCouponExport = (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.RESET_COUPON_EXPORT,
  });
};

// eslint-disable-next-line import/prefer-default-export
export {
  getAllRules,
  updateCoupon,
  createRule,
  updateRule,
  resetRule,
  getRule,
  resetCoupon,
  getRuleCoupons,
  updateRuleCoupon,
  exportRules,
  exportCoupon,
  resetExport,
  resetCouponExport,
};
