import { StockAuditActionType } from '../../actions/ActionTypes';

interface StockAuditState {
  allStockAudits: any;
  stockAuditItems: any;
  loader: boolean;
  error: boolean;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
  createStock: object;
  createLoader: boolean;
  updateStock: object;
}

const initialState: StockAuditState = {
  allStockAudits: {},
  stockAuditItems: {},
  loader: false,
  error: false,
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
  createStock: {},
  createLoader: false,
  updateStock: {},
};

const reducer = (state: StockAuditState = initialState, action: any = {}) => {
  switch (action.type) {
    case StockAuditActionType.START_FETCH_ALL_STOCK_AUDITS: {
      return {
        ...state,
        loader: true,
      };
    }
    case StockAuditActionType.FINISH_FETCH_ALL_STOCK_AUDITS: {
      return {
        ...state,
        allStockAudits: action.payload,
        loader: false,
      };
    }

    case StockAuditActionType.START_FETCH_STOCK_AUDIT_ITEMS: {
      return {
        ...state,
        loader: true,
        stockAuditItems: {},
      };
    }
    case StockAuditActionType.FINISH_FETCH_STOCK_AUDIT_ITEMS: {
      return {
        ...state,
        stockAuditItems: action.payload,
        loader: false,
      };
    }
    case StockAuditActionType.UPDATE_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case StockAuditActionType.UPDATE_STATUS_SUCCESS: {
      const index = state.allStockAudits.results.findIndex(
        (posuser: any) => posuser.id === action.payload.id
      );
      action.payload.isNew = true;
      const results = [...state.allStockAudits.results];
      results[index] = action.payload;
      return {
        ...state,
        allStockAudits: {
          ...state.allStockAudits,
          results,
        },
        loader: false,
      };
    }
    case StockAuditActionType.UPDATE_STATUS_FAILURE: {
      return {
        ...state,
        isError: true,
        loader: false,
        error: action.payload,
      };
    }
    case StockAuditActionType.EXPORT_STOCK_AUDIT:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case StockAuditActionType.EXPORT_STOCK_AUDIT_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case StockAuditActionType.EXPORT_STOCK_AUDIT_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case StockAuditActionType.CREATE_STOCK_AUDIT:
      return {
        ...state,
      };
    case StockAuditActionType.CREATE_STOCK_AUDIT_SUCCESS:
      return {
        ...state,
        createStock: action.payload,
        createLoader: false,
      };
    case StockAuditActionType.CREATE_STOCK_AUDIT_ERROR:
      return {
        ...state,
        createLoader: false,
      };

    case StockAuditActionType.UPDATE_STOCK_AUDIT:
      return {
        ...state,
      };

    case StockAuditActionType.UPDATE_STOCK_AUDIT_SUCCESS: {
      const index = state.allStockAudits.results.findIndex(
        (store: any) => store.id === action.payload.id
      );
      action.payload.isNew = true;
      const results = [...state.allStockAudits.results];
      results[index] = action.payload;
      return {
        ...state,
        allStockAudits: { ...state.allStockAudits, results },
      };
    }

    case StockAuditActionType.RESET_EXPORT:
      return {
        ...state,
        exportSuccess: false,
        exportFailure: {},
      };
    default: {
      return state;
    }
  }
};

export default reducer;
