import { DashboardActionType } from '../../actions/ActionTypes';

interface DashboardState {
  orderStats: any;
  storeStats: any;
  customerStats: any;
  orderLoader: boolean;
  storeLoader: boolean;
  customerLoader: boolean;
  storeGraphList: Object;
  storeGraphLoader: Boolean;
  customersGraphLoader: Boolean;
  customersGraphList: Object;
  error: boolean;
  orderGraph: Object;
  orderGraphLoader: Boolean;
  orderStatusGraphLoader: Boolean;
  orderStatusGraphList: Object;
  averageOrder: Object;
  averageOrderLoader: Boolean;
}

const initialState: DashboardState = {
  orderStats: {},
  storeStats: {},
  customerStats: {},
  orderGraph: {},
  orderLoader: false,
  storeLoader: false,
  customerLoader: false,
  storeGraphList: [],
  storeGraphLoader: false,
  customersGraphLoader: false,
  customersGraphList: [],
  error: false,
  orderGraphLoader: false,
  orderStatusGraphLoader: false,
  orderStatusGraphList: [],
  averageOrder: [],
  averageOrderLoader: false,
};

const reducer = (state: DashboardState = initialState, action: any = {}) => {
  switch (action.type) {
    case DashboardActionType.START_FETCH_ORDER_STATS: {
      return {
        ...state,
        orderLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_ORDER_STATS: {
      return {
        ...state,
        orderStats: action.payload[0],
        orderLoader: false,
      };
    }
    case DashboardActionType.START_FETCH_CUSTOMER_STATS: {
      return {
        ...state,
        customerLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_CUSTOMER_STATS: {
      return {
        ...state,
        customerStats: action.payload[0],
        customerLoader: false,
      };
    }
    case DashboardActionType.START_FETCH_STORE_STATS: {
      return {
        ...state,
        storeLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_STORE_STATS: {
      return {
        ...state,
        storeStats: action.payload[0],
        storeLoader: false,
      };
    }
    case DashboardActionType.START_FETCH_ORDER_GRAPH: {
      return {
        ...state,
        orderGraphLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_ORDER_GRAPH: {
      return {
        ...state,
        orderGraphLoader: false,
        orderGraph: action.payload,
      };
    }

    case DashboardActionType.START_FETCH_STORE_GRAPH_DATA: {
      return {
        ...state,
        storeGraphLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_STORE_GRAPH_DATA: {
      return {
        ...state,
        storeGraphLoader: false,
        storeGraphList: action.payload,
      };
    }

    case DashboardActionType.START_FETCH_CUSTOMERS_GRAPH_DATA: {
      return {
        ...state,
        customersGraphLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_CUSTOMERS_GRAPH_DATA: {
      return {
        ...state,
        customersGraphLoader: false,
        customersGraphList: action.payload,
      };
    }

    case DashboardActionType.START_FETCH_ORDER_STATUS_GRAPH_DATA: {
      return {
        ...state,
        orderStatusGraphLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_ORDER_STATUS_GRAPH_DATA: {
      return {
        ...state,
        orderStatusGraphLoader: false,
        orderStatusGraphList: action.payload,
      };
    }
    case DashboardActionType.START_FETCH_AVERAGE_ORDER_GRAPH: {
      return {
        ...state,
        averageOrderLoader: true,
      };
    }
    case DashboardActionType.FINISH_FETCH_AVERAGE_ORDER_GRAPH: {
      return {
        ...state,
        averageOrder: action.payload,
        averageOrderLoader: false,
      };
    }
    case DashboardActionType.LOAD_FAILED: {
      return {
        ...state,
        customersGraphLoader: false,
        storeGraphLoader: false,
        averageOrderLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
