import { ChainsActionType } from '../../actions/ActionTypes';

interface chainsState {
  chains: any;
  chainsLoader: boolean;
  isError: boolean;
  submitted: boolean;
}
const initialState: chainsState = {
  chains: {},
  chainsLoader: false,
  isError: false,
  submitted: false,
};

const reducer = (state: chainsState = initialState, action: any = {}) => {
  switch (action.type) {
    case ChainsActionType.GET_CHAINS: {
      return {
        ...state,
        chainsLoader: true,
      };
    }
    case ChainsActionType.LOAD_SUCCESS: {
      return {
        ...state,
        chains: action.payload,
        chainsLoader: false,
      };
    }
    case ChainsActionType.LOAD_FAILED: {
      return {
        ...state,
        agent: {
          ...state.chains,
        },
        isError: true,
        chainsLoader: false,
      };
    }
    case ChainsActionType.UPDATING_CHAINS_INFORMATION: {
      return {
        ...state,
        chainsLoader: true,
      };
    }

    case ChainsActionType.UPDATE_CHAINS_INFORMATION_SUCCESS: {
      return {
        ...state,
        chains: action.payload,
        chainsLoader: false,
        isError: false,
        submitted: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
