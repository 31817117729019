/**
 * This file contains all app related actions
 */
import AUTH_API from '../../../api/authAPI';
import { eraseCookie, setCookie } from '../../../util';
import { AppActionType } from '../ActionTypes';

export interface AppState {
  isLoggedInUser: boolean;
  loading: boolean;
  users?: {
    userId: string;
    mobileNumber: string;
    accessToken: string;
    refreshToken: string;
    designation: string;
    preferredUserName: string;
    email: string;
    givenName: string;
    familyName: string;
    phoneNumber: string;
    name: string;
    middleName: string;
    lastLogin: string;
  };
}

const loginUser = (data: object) => (dispatch: any) => {
  dispatch({
    type: AppActionType.LOGIN_USER,
  });
  return AUTH_API.postLoginUser(data).then(res => {
    if (res) {
      setCookie('jwt', res.data.data.token, 30);
      localStorage.setItem('JWT_TOKEN', res.data.data.token);
      localStorage.setItem('USER', JSON.stringify(res.data.data.user));
      window.location.reload();
    }
    dispatch({
      type: AppActionType.LOGIN_USER_SUCCESS,
      payload: res.data,
    });
    return res;
  });
};

const validateUser = () => (dispatch: any) => {
  dispatch({
    type: AppActionType.VALIDATING_USER,
  });
  return AUTH_API.validateUser().then(res => {
    if (res) {
      localStorage.setItem('USER', JSON.stringify(res.data.data));
    }
    dispatch({
      type: AppActionType.LOGIN_USER_SUCCESS,
      payload: res.data,
    });
    return res;
  });
};

const googleLogin = (params: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: AppActionType.GOOGLE_LOGIN,
    });
    const res = await AUTH_API.socialAuth(params);
    if (res.data.data) {
      setCookie('jwt', res.data.data.token, 30);
      localStorage.setItem('JWT_TOKEN', res.data.data.token);
      localStorage.setItem('USER', JSON.stringify(res.data.data.user));
      localStorage.setItem('ISGOOGLE', 'true');
      window.location.reload();
    }
    dispatch({
      type: AppActionType.GOOGLE_LOGIN_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: AppActionType.GOOGLE_LOGIN_FAILURE,
      payload: e,
    });
  }
};

const logoutUser = () => async (dispatch: any) => {
  try {
    const res = await AUTH_API.logoutUser();
    if (res.data.data) {
      eraseCookie('jwt');
      localStorage.removeItem('JWT_TOKEN');
      localStorage.removeItem('ISGOOGLE');
      dispatch({
        type: AppActionType.LOGOUT_USER,
      });
    }
  } catch (e) {
    dispatch({
      type: AppActionType.USERS_ERROR,
      payload: e,
    });
  }
};

const generateResetToken = (data: object) => (dispatch: any) => {
  dispatch({
    type: AppActionType.GENERATE_RESET_TOKEN,
  });
  return AUTH_API.generateResetToken(data).then(res => {
    if (res) {
      localStorage.setItem('resetToken', res.data.data);
      dispatch({
        type: AppActionType.GENERATE_RESET_TOKEN_SUCCESS,
        payload: 'TOKEN GENERATED SUCCESSFULLY',
      });
    } else {
      dispatch({
        type: AppActionType.GENERATE_RESET_TOKEN_FAILURE,
        payload: 'User Not Found',
      });
    }
    return res;
  });
};

const resetPassword = (data: object) => (dispatch: any) => {
  dispatch({
    type: AppActionType.RESET_PASSWORD,
  });
  return AUTH_API.resetPassword(data).then(res => {
    if (res) {
      dispatch({
        type: AppActionType.RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: AppActionType.RESET_PASSWORD_FAILURE,
      });
    }
    return res;
  });
};

export {
  loginUser,
  logoutUser,
  validateUser,
  googleLogin,
  generateResetToken,
  resetPassword,
};
