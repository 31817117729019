import axios from './axios';
import config from './config';

const CHAIN_API = {
  getChainsDetails: (chainsId: number | undefined) =>
    axios({
      url: `${config.endpoint.chains}/${chainsId}`,
      method: 'GET',
    }),
  postUpdateChainsInformation: (data: object) =>
    axios({
      url: `${config.endpoint.chains}/update`,
      method: 'POST',
      data,
    }),
};
export default CHAIN_API;
