import { RuleActionTypes } from '../../actions/ActionTypes';

interface CouponsState {
  coupons: any;
  couponsLoader: boolean;
  CSVLoader: boolean;
  CSVSuccess: boolean;
  CSVFailure: object;
}

const initialState: CouponsState = {
  coupons: {},
  couponsLoader: false,
  CSVLoader: false,
  CSVSuccess: false,
  CSVFailure: {},
};

const reducer = (state: CouponsState = initialState, action: any = {}) => {
  switch (action.type) {
    // Get coupons grid
    case RuleActionTypes.GET_COUPONS: {
      return {
        ...state,
        couponsLoader: true,
      };
    }
    case RuleActionTypes.GET_COUPONS_SUCCESS: {
      return {
        ...state,
        coupons: action.payload,
        couponsLoader: false,
      };
    }

    // Update coupon status
    case RuleActionTypes.UPDATE_COUPON: {
      return {
        ...state,
        couponsLoader: true,
      };
    }
    case RuleActionTypes.EXPORT_COUPONS_CSV:
      return {
        ...state,
        CSVLoader: true,
        CSVFailure: {},
      };
    case RuleActionTypes.EXPORT_COUPONS_CSV_SUCCESS:
      return {
        ...state,
        CSVLoader: false,
        CSVSuccess: true,
        CSVFailure: {},
      };
    case RuleActionTypes.EXPORT_COUPONS_CSV_FAILURE:
      return {
        ...state,
        CSVLoader: false,
        CSVFailure: action.payload,
      };
    case RuleActionTypes.RESET_COUPONS_EXPORT:
      return {
        ...state,
        CSVSuccess: false,
      };
    case RuleActionTypes.UPDATE_COUPON_SUCCESS: {
      const index = state.coupons.results.findIndex(
        (storePrice: any) => storePrice.id === action.payload.id
      );
      const results = [...state.coupons.results];
      results[index].is_visible = action.payload.is_visible ? 1 : 0;
      return {
        ...state,
        coupons: {
          ...state.coupons,
          results,
        },
        couponsLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
