/* eslint-disable react/destructuring-assignment */
import { Typography } from 'antd';
import UTIL_API from '../../api/utilApi';
import { errorNotification } from '../Notifications';

const downloadFile = (name: string) => {
  UTIL_API.getSignedURL(name)
    .then(response => {
      if (response.data) {
        window.open(response.data.data.url);
      }
    })
    .catch(err =>
      errorNotification({
        message: 'Error!',
        description: `${err.message}`,
        placement: 'bottomLeft',
      })
    );
};

const Download = (children: any) => (
  <Typography.Link onClick={() => downloadFile(children.name)}>
    {`${children.showName ? children.name : `Download`}`}
  </Typography.Link>
);

export default Download;
