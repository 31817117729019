/* eslint-disable func-names */

import STORE_API from '../../../api/storeAPI';
import { StoreActionType } from '../ActionTypes';

const getStores = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORES,
    });
    const res = await STORE_API.getStores(params);
    dispatch({
      type: StoreActionType.GET_STORES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_STORES_ERROR,
      payload: e,
    });
  }
};

const getStore = (storeId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORE,
    });
    const res = await STORE_API.getStore(storeId);
    dispatch({
      type: StoreActionType.GET_STORE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_STORE_ERROR,
      payload: e,
    });
  }
};

const createStore = (data: Object) => async (dispatch: any) => {
  dispatch({
    type: StoreActionType.CREATE_STORE,
  });
  return STORE_API.createStore(data).then(response => {
    if (response) {
      dispatch({
        type: StoreActionType.CREATE_STORE_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: StoreActionType.CREATE_STORE_FAILURE,
        payload: response,
      });
    }
    return response;
  });
};

const updateStore = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.UPDATE_STORE,
    });
    const res = await STORE_API.updateStore(data);
    dispatch({
      type: StoreActionType.UPDATE_STORE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.UPDATE_STORE_FAILURE,
      payload: e,
    });
  }
};

const getAttributes = () => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_ATTRIBUTES,
    });
    const res = await STORE_API.getAttributes();
    dispatch({
      type: StoreActionType.GET_ATTRIBUTES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_ATTRIBUTES_FAILURE,
      payload: e,
    });
  }
};

const updateAttributes = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.UPDATE_ATTRIBUTES,
    });
    const res = await STORE_API.updateAttributes(data);
    dispatch({
      type: StoreActionType.UPDATE_ATTRIBUTES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.UPDATE_ATTRIBUTES_FAILURE,
      payload: e,
    });
  }
};

const exportStore = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.EXPORT_ORDER,
    });
    const res = await STORE_API.exportStore(params);
    dispatch({
      type: StoreActionType.EXPORT_ORDER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.EXPORT_ORDER_ERROR,
      payload: e,
    });
  }
};

const getStoreInventoryById = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORE_INVENTORY,
    });
    const res = await STORE_API.getStoreInventory(params);
    dispatch({
      type: StoreActionType.GET_STORE_INVENTORY_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getStoreInventoryShipmentById = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORE_INVENTORY_SHIPMENT,
    });
    const res = await STORE_API.getStoreInventoryShipment(params);
    dispatch({
      type: StoreActionType.GET_STORE_INVENTORY_SHIPMENT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getStoreReturnById = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORE_RETURN,
    });
    const res = await STORE_API.getStoreReturn(params);
    dispatch({
      type: StoreActionType.GET_STORE_RETURN_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const verifyInventoryShipment = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.VERIFY_INVENTORY_SHIPMENT,
    });
    const res = await STORE_API.verifyInventoryShipment(params);
    if (res) {
      dispatch({
        type:
          res.data && res.data.data.inventory_updated
            ? StoreActionType.VERIFY_INVENTORY_SHIPMENT_SUCCESS
            : StoreActionType.VERIFY_INVENTORY_SHIPMENT_SUCCESS_FAIL,
        payload: res.data,
      });
    }
  } catch (e) {
    dispatch({
      type: StoreActionType.VERIFY_INVENTORY_SHIPMENT_FAILED,
      payload: e,
    });
  }
};

const verifyReturn = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.VERIFY_RETURN,
    });
    const res = await STORE_API.verifyReturn(params);
    if (res) {
      dispatch({
        type:
          res.data && res.data.data.inventory_updated
            ? StoreActionType.VERIFY_RETURN_SUCCESS
            : StoreActionType.VERIFY_RETURN_SUCCESS_FAIL,
        payload: res.data,
      });
    }
  } catch (e) {
    dispatch({
      type: StoreActionType.VERIFY_RETURN_FAILED,
      payload: e,
    });
  }
};

const getStockLedger =
  (storeId: string, productID: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: StoreActionType.GET_STOCK_LEDGER,
      });
      const res = await STORE_API.getStockLedger(storeId, productID);
      dispatch({
        type: StoreActionType.GET_STOCK_LEDGER_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: StoreActionType.GET_STOCK_LEDGER_ERROR,
        payload: e,
      });
    }
  };
const PosPaymentLogs = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_POS_PAYMENT_LOGS,
    });
    const res = await STORE_API.getPosPaymentLog(params);

    dispatch({
      type: StoreActionType.GET_POS_PAYMENT_LOGS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_POS_PAYMENT_LOGS_ERROR,
      payload: e,
    });
  }
};
const clearStockLedger = () => (dispatch: any) => {
  dispatch({
    type: StoreActionType.CLEAR_STOCK_LEDGER,
  });
};
const getInventoryCsv = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.EXPORT_CSV,
    });
    const res = await STORE_API.getInventoryCsv(params);
    dispatch({
      type: StoreActionType.EXPORT_CSV_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.EXPORT_CSV_ERROR,
      payload: e,
    });
  }
};
const resetExport = (dispatch: any) => {
  dispatch({
    type: StoreActionType.RESET_EXPORT,
  });
};
const resetStore = (dispatch: any) => {
  dispatch({
    type: StoreActionType.RESET_STORE,
  });
};
const resetShipment = (dispatch: any) => {
  dispatch({
    type: StoreActionType.RESET_SHIPMENT,
  });
};
const resetReturn = (dispatch: any) => {
  dispatch({
    type: StoreActionType.RESET_RETURN,
  });
};
const getStoreLedger = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_STORE_LEDGER,
    });
    const res = await STORE_API.getStoreLedger(params);
    dispatch({
      type: StoreActionType.GET_STORE_LEDGER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_STORE_LEDGER_ERROR,
      payload: e,
    });
  }
};

const getSaleSummary = (storeId: {}, params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_SALE_SUMMARY,
    });
    const res = await STORE_API.getSaleSummary(storeId, params);
    dispatch({
      type: StoreActionType.GET_SALE_SUMMARY_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_SALE_SUMMARY_ERROR,
      payload: e,
    });
  }
};
const getIncomeSummary = (storeId: {}, params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_INCOME_SUMMARY,
    });
    const res = await STORE_API.getIncomeSummary(storeId, params);
    dispatch({
      type: StoreActionType.GET_INCOME_SUMMARY_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_INCOME_SUMMARY_ERROR,
      payload: e,
    });
  }
};

const getStorePaymentInfo = (storeId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StoreActionType.GET_PAYMENT_INFO,
    });
    const res = await STORE_API.getStorePaymentInfo(storeId);
    dispatch({
      type: StoreActionType.GET_PAYMENT_INFO_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StoreActionType.GET_PAYMENT_INFO_ERROR,
      payload: e,
    });
  }
};

export {
  getStore,
  createStore,
  updateStore,
  getStores,
  getAttributes,
  updateAttributes,
  resetStore,
  exportStore,
  getStoreInventoryById,
  getStockLedger,
  clearStockLedger,
  getInventoryCsv,
  resetExport,
  getStoreInventoryShipmentById,
  verifyInventoryShipment,
  verifyReturn,
  resetShipment,
  resetReturn,
  getStoreReturnById,
  PosPaymentLogs,
  getStoreLedger,
  getSaleSummary,
  getIncomeSummary,
  getStorePaymentInfo,
};
