import CUSTOMER_API from '../../../api/customerApi';
import { CustomerActionType } from '../ActionTypes';

const getOverallStats = (customerId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CustomerActionType.START_FETCH_OVERALL_STATS,
    });
    const res: any = await CUSTOMER_API.getStats(customerId);

    dispatch({
      type: CustomerActionType.FINISH_FETCH_OVERALL_STATS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CustomerActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getAllCustomers = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CustomerActionType.START_FETCH_ALL_CUSTOMERS,
    });
    const res = await CUSTOMER_API.getCustomers(params);
    dispatch({
      type: CustomerActionType.FINISH_FETCH_ALL_CUSTOMERS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CustomerActionType.LOAD_FAILED,
      payload: e,
    });
  }
};
/* eslint-disable camelcase */
const getAssociatedDevicesId =
  (customer_id: any, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ASSOCIATED_DEVICES_ID,
      });
      const res = await CUSTOMER_API.getAssociatedDeviceId(customer_id, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES_ID,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const updateAssociatedDeviceId =
  (data: object, device_id: any, customer_id: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.UPDATE_ASSOCIATED_DEVICES_ID,
      });
      await CUSTOMER_API.updateAssociatedDeviceId(data, device_id, customer_id);
      dispatch({
        type: CustomerActionType.UPDATE_ASSOCIATED_DEVICES_ID,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAssociatedDevicesIdDetail =
  (customer_id: any, device_id: any, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ASSOCIATED_DEVICES_ID_DETAIL,
      });
      const res = await CUSTOMER_API.getAssociatedDeviceIdDetail(
        customer_id,
        device_id,
        params
      );
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES_ID_DETAIL,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAccountInformation = (customerId: String) => async (dispatch: any) => {
  try {
    dispatch({
      type: CustomerActionType.START_FETCH_ACCOUNT_INFO,
    });
    const res = await CUSTOMER_API.getCustomerDetails(customerId);
    dispatch({
      type: CustomerActionType.FINISH_FETCH_ACCOUNT_INFO,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CustomerActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateAccountInformation = (data: Object) => async (dispatch: any) => {
  dispatch({
    type: CustomerActionType.START_UPDATE_ACCOUNT_INFO,
  });
  return CUSTOMER_API.updateAccountInformation(data).then(response => {
    dispatch({
      type: CustomerActionType.FINISH_UPDATE_ACCOUNT_INFO,
      payload: response.data,
    });
    return response;
  });
};

const getLoyaltyPoints =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_LOYALTY_POINTS,
      });
      const res: any = await CUSTOMER_API.getLoyaltyPoints(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_LOYALTY_POINTS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getMonthlyLoyaltyPoints =
  (customerId: any, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_MONTHLY_LOYALTY_POINTS,
      });
      const res: any = await CUSTOMER_API.getMonthlyLoyaltyPoints(
        customerId,
        params
      );
      dispatch({
        type: CustomerActionType.FINISH_FETCH_MONTHLY_LOYALTY_POINTS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAllOrders =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ALL_ORDERS,
      });
      const res = await CUSTOMER_API.getAllOrders(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ALL_ORDERS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getUsedCoupons =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_USED_COUPONS,
      });
      const res: any = await CUSTOMER_API.getUsedCoupons(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_USED_COUPONS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAssociatedDevices =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ASSOCIATED_DEVICES,
      });
      const res: any = await CUSTOMER_API.getAssociatedDevices(
        customerId,
        params
      );
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAssociatedTags =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ASSOCIATED_TAGS,
      });
      const res = await CUSTOMER_API.getAssociatedTags(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ASSOCIATED_TAGS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAllCarts =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ALL_CARTS,
      });
      const res = await CUSTOMER_API.getAllCarts(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ALL_CARTS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getAssociatedStores =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_ASSOCIATED_STORES,
      });
      const res = await CUSTOMER_API.getAssociatedStores(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_ASSOCIATED_STORES,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getSupportTickets =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_SUPPORT_TICKETS,
      });
      const res: any = await CUSTOMER_API.getSupportTickets(customerId, params);
      dispatch({
        type: CustomerActionType.FINISH_FETCH_SUPPORT_TICKETS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getCustomerAddress =
  (customerId: String, params: Object = {}) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.START_FETCH_CUSTOMER_ADDRESS,
      });
      const res: any = await CUSTOMER_API.getCustomerAddress(
        customerId,
        params
      );
      dispatch({
        type: CustomerActionType.FINISH_FETCH_CUSTOMER_ADDRESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const exportLoyaltyPoints =
  (customerId: any, params: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: CustomerActionType.EXPORT_LOYALTY_POINTS,
      });
      const res = await CUSTOMER_API.exportLoyaltyPoints(customerId, params);
      dispatch({
        type: CustomerActionType.EXPORT_LOYALTY_POINTS_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CustomerActionType.EXPORT_LOYALTY_POINTS_ERROR,
        payload: e,
      });
    }
  };

const getCartDetail = (cartId: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: CustomerActionType.GET_CART_DETAILS,
    });
    const res = await CUSTOMER_API.getCartDetails(cartId);
    dispatch({
      type: CustomerActionType.GET_CART_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: CustomerActionType.GET_CART_DETAILS_ERROR,
      payload: e,
    });
  }
};

const reset = () => ({
  type: 'RESET',
});

export {
  getOverallStats,
  getAllCustomers,
  getAssociatedDevicesId,
  getAssociatedDevicesIdDetail,
  updateAssociatedDeviceId,
  getAccountInformation,
  updateAccountInformation,
  getLoyaltyPoints,
  getAllOrders,
  getUsedCoupons,
  getAssociatedDevices,
  getAssociatedTags,
  getAllCarts,
  getAssociatedStores,
  getSupportTickets,
  getCustomerAddress,
  exportLoyaltyPoints,
  getCartDetail,
  getMonthlyLoyaltyPoints,
  reset,
};
