import axios from './axios';
import config from './config';

const DASHBOARD_API = {
  getDashboardStats: (params: any) =>
    axios({
      url: `${config.endpoint.dashboard}/stats`,
      method: 'GET',
      params,
    }),
  getDashboardGraph: (params: any) =>
    axios({
      url: `${config.endpoint.dashboard}/graph`,
      method: 'GET',
      params,
    }),
  getAverageOrderGraph: (params: any) =>
    axios({
      url: `${config.endpoint.dashboard}/average-order-count`,
      method: 'GET',
      params,
    }),
};

export default DASHBOARD_API;
