import RULE_API from '../../../api/ruleAPI';
import { RuleActionTypes } from '../ActionTypes';

const getAllCoupons = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.GET_COUPONS,
    });
    const res = await RULE_API.getCoupons(params);
    dispatch({
      type: RuleActionTypes.GET_COUPONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.LOAD_FAILED,
      payload: e,
    });
  }
};

const exportCoupons = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RuleActionTypes.EXPORT_COUPONS_CSV,
    });
    const res = await RULE_API.exportCoupons(params);
    dispatch({
      type: RuleActionTypes.EXPORT_COUPONS_CSV_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RuleActionTypes.EXPORT_COUPONS_CSV_FAILURE,
      payload: e,
    });
  }
};

const resetExport = (dispatch: any) => {
  dispatch({
    type: RuleActionTypes.RESET_COUPONS_EXPORT,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { getAllCoupons, exportCoupons, resetExport };
