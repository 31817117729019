// @ts-nocheck
import { AppComponentActionTypes } from '../../actions/ActionTypes';

interface AppComponentState {
  newScreenSuccess: Boolean;
  updateScreenSuccess: Boolean;
  screens: Object;
  screenGridLoader: Boolean;
  screenGridError: Object;

  newScreenLoader: Boolean;
  newScreenError: Object;

  updateScreenLoader: Boolean;
  updateScreenError: Object;

  selectedScreen: Object;
  screenByIdLoader: Boolean;
  screenByIdError: Object;

  componentsList: Object;
  componentsListLoader: Boolean;
  componentsListError: Object;

  addFilterLoader: Boolean;
  addFilterError: Object;
  addFilterSuccess: Boolean;

  updateFilterLoader: Boolean;
  updateFilterError: Object;
  updateFilterSuccess: Boolean;

  selectedComponent: Object;
  newComponentSuccess: Boolean;
  newComponentFailure: Boolean;
  updateComponentSuccess: Object;
  updateComponentFailure: Object;
  componentsTypes: Object;
  uploadImageSuccess: Boolean;
  uploadImageFailure: Boolean;
  deleteImageSuccess: Boolean;
  deleteImageFailure: Boolean;
  updateImageSuccess: Boolean;
  updateImageFailure: Boolean;
  listLoading: Boolean;
  createComponentUnitSuccess: Boolean;
  createComponentUnitFailure: Boolean;
  updateComponentUnitSuccess: Boolean;
  updateComponentUnitFailure: Boolean;
  componentActions: Object;
  loader: Boolean;
  error: Object;
  invalidateSuccess: Boolean;
  invalidateLoader: Boolean;
  unitComponentsStatus: Boolean;
}

const initialState: AppComponentState = {
  newScreenSuccess: false,
  updateScreenSuccess: false,
  screens: {},
  screenGridLoader: false,
  screenGridError: {},

  newScreenLoader: false,
  newScreenError: {},

  updateScreenLoader: false,
  updateScreenError: {},

  selectedScreen: {
    id: 1,
    name: 'home',
    slug: 'home',
    created_at: '2021-06-23T10:50:24.000Z',
    updated_at: '2021-06-23T10:50:24.000Z',
  },
  screenByIdLoader: false,
  screenByIdError: {},

  componentsList: {},
  componentsListLoader: false,
  componentsListError: {},

  addFilterLoader: false,
  addFilterError: {},
  addFilterSuccess: false,

  updateFilterLoader: false,
  updateFilterError: {},
  updateFilterSuccess: false,

  selectedComponent: {},
  newComponentSuccess: false,
  newComponentFailure: false,
  updateComponentSuccess: false,
  updateComponentFailure: false,
  uploadImageSuccess: false,
  uploadImageFailure: false,
  deleteImageSuccess: false,
  deleteImageFailure: false,
  updateImageSuccess: false,
  updateImageFailure: false,
  createComponentUnitSuccess: false,
  createComponentUnitFailure: false,
  updateComponentUnitSuccess: false,
  updateComponentUnitFailure: false,
  componentsTypes: [],
  componentActions: [],
  listLoading: false,
  loader: false,
  error: {},
  invalidateSuccess: false,
  invalidateLoader: false,
  unitComponentsStatus: true,
};

const reducer = (state: AppComponentState = initialState, action: any = {}) => {
  const { screens } = state;

  switch (action.type) {
    // Screens Grid
    case AppComponentActionTypes.FETCH_SCREENS: {
      return {
        ...state,
        screenGridLoader: true,
      };
    }
    case AppComponentActionTypes.FETCH_SCREENS_SUCCESS: {
      return {
        ...state,
        screens: action.payload,
        screenGridLoader: false,
        screenGridError: {},
      };
    }
    case AppComponentActionTypes.FETCH_SCREENS_FAILURE: {
      return {
        ...state,
        screenGridLoader: false,
        screenGridError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    // Add New Screen
    case AppComponentActionTypes.ADD_NEW_SCREEN: {
      return {
        ...state,
        newScreenLoader: true,
      };
    }
    case AppComponentActionTypes.ADD_NEW_SCREEN_SUCCESS: {
      const screensState = { ...screens };
      const { results, hasNext, count } = screens;

      if (!hasNext) {
        screensState.results = [...results, action.payload];
        screensState.count = count + 1;
      }

      return {
        ...state,
        screens: screensState,
        newScreenSuccess: true,
        newScreenLoader: false,
        newScreenError: {},
      };
    }
    case AppComponentActionTypes.ADD_NEW_SCREEN_FAILURE: {
      return {
        ...state,
        newScreenLoader: false,
        newScreenSuccess: false,
        newScreenError: { status: true, message: action.payload.message },
      };
    }

    // Update Screen
    case AppComponentActionTypes.UPDATE_SCREEN: {
      return {
        ...state,
        updateScreenLoader: false,
      };
    }
    case AppComponentActionTypes.UPDATE_SCREEN_SUCCESS: {
      const screensState = { ...screens };
      const results = [...screensState.results];
      const index = results.findIndex(
        (screen: any) => screen.id === action.payload.id
      );
      results[index] = { ...results[index], ...action.payload };

      return {
        ...state,
        screens: { ...screensState, results },
        updateScreenSuccess: true,
        updateScreenLoader: false,
        updateScreenError: {},
      };
    }
    case AppComponentActionTypes.UPDATE_SCREEN_FAILURE: {
      return {
        ...state,
        updateScreenLoader: false,
        updateScreenSuccess: false,
        updateScreenError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    // Fetching Screen By Id
    case AppComponentActionTypes.FETCH_SCREEN_BY_ID: {
      return {
        ...state,
        screenByIdLoader: true,
        screenByIdError: {},
      };
    }
    case AppComponentActionTypes.FETCH_SCREEN_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedScreen: action.payload.results[0],
        screenByIdLoader: false,
        screenByIdError: {},
      };
    }
    case AppComponentActionTypes.FETCH_SCREEN_BY_ID_FAILURE: {
      return {
        ...state,
        screenByIdLoader: false,
        screenByIdError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    // Get All Components Associated with Screen Id
    case AppComponentActionTypes.FETCH_COMPONENTS_LIST: {
      return {
        ...state,
        componentsListLoader: true,
        componentsListError: {},
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENTS_LIST_SUCCESS: {
      return {
        ...state,
        componentsList: action.payload,
        componentsListLoader: false,
        componentsListError: {},
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENTS_LIST_FAILURE: {
      return {
        ...state,
        componentsListLoader: false,
        componentsListError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    case AppComponentActionTypes.REORDER_COMPONENT: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.REORDER_COMPONENT_SUCCESS: {
      return {
        ...state,
        error: {},
      };
    }
    case AppComponentActionTypes.REORDER_COMPONENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.REORDER_COMPONENT_UNITS: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.REORDER_COMPONENT_UNITS_SUCCESS: {
      return {
        ...state,
        error: {},
      };
    }
    case AppComponentActionTypes.REORDER_COMPONENT_UNITS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.CREATE_COMPONENT: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.CREATE_COMPONENT_SUCCESS: {
      return {
        ...state,
        newComponentSuccess: true,
        error: {},
      };
    }
    case AppComponentActionTypes.CREATE_COMPONENT_FAILURE: {
      return {
        ...state,
        newComponentFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.UPDATE_COMPONENT: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.UPDATE_COMPONENT_SUCCESS: {
      return {
        ...state,
        updateComponentSuccess: true,
        error: {},
      };
    }
    case AppComponentActionTypes.UPDATE_COMPONENT_FAILURE: {
      return {
        ...state,
        updateComponentFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.UPLOAD_IMAGE: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        uploadImageSuccess: true,
        error: {},
      };
    }
    case AppComponentActionTypes.UPLOAD_IMAGE_FAILURE: {
      return {
        ...state,
        uploadImageFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.FETCH_COMPONENTS_TYPES: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENTS_TYPES_SUCCESS: {
      return {
        ...state,
        componentsTypes: action.payload,
        error: {},
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENTS_TYPES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.ADD_DATA_TO_COMPONENTS_LIST: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case AppComponentActionTypes.ADD_DATA_TO_COMPONENTS_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        componentsList: { ...state.componentsList, results: action.payload },
      };
    }
    case AppComponentActionTypes.ADD_DATA_TO_COMPONENTS_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.DELETE_IMAGE: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.DELETE_IMAGE_SUCCESS: {
      return {
        ...state,
        deleteImageSuccess: true,
      };
    }
    case AppComponentActionTypes.DELETE_IMAGE_FAILURE: {
      return {
        ...state,
        deleteImageFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.FETCH_COMPONENT_BY_ID: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENT_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedComponent: action.payload.results[0],
      };
    }
    case AppComponentActionTypes.FETCH_COMPONENT_BY_ID_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.UPDATE_IMAGE: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.UPDATE_IMAGE_SUCCESS: {
      return {
        ...state,
        updateImageSuccess: true,
      };
    }
    case AppComponentActionTypes.UPDATE_IMAGE_FAILURE: {
      return {
        ...state,
        updateImageFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.GET_COMPONENT_ACTIONS: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.GET_COMPONENT_ACTIONS_SUCCESS: {
      return {
        ...state,
        componentActions: action.payload,
      };
    }
    case AppComponentActionTypes.GET_COMPONENT_ACTIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.CREATE_COMPONENT_UNIT: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.CREATE_COMPONENT_UNIT_SUCCESS: {
      return {
        ...state,
        createComponentUnitSuccess: true,
        error: {},
      };
    }
    case AppComponentActionTypes.CREATE_COMPONENT_UNIT_FAILURE: {
      return {
        ...state,
        createComponentUnitFailure: true,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.UPDATE_COMPONENT_UNIT: {
      return {
        ...state,
      };
    }
    case AppComponentActionTypes.UPDATE_COMPONENT_UNIT_SUCCESS: {
      return {
        ...state,
        updateComponentUnitSuccess: true,
        error: {},
      };
    }
    case AppComponentActionTypes.UPDATE_COMPONENT_UNIT_FAILURE: {
      return {
        ...state,
        updateComponentUnitFailure: false,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.ADD_FILTER: {
      return {
        ...state,
        addFilterLoader: true,
      };
    }
    case AppComponentActionTypes.ADD_FILTER_SUCCESS: {
      return {
        ...state,
        addFilterLoader: false,
        addFilterSuccess: true,
        addFilterError: {},
      };
    }
    case AppComponentActionTypes.ADD_FILTER_FAILURE: {
      return {
        ...state,
        addFilterLoader: false,
        addFilterError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    case AppComponentActionTypes.UPDATE_FILTER: {
      return {
        ...state,
        updateFilterLoader: true,
      };
    }
    case AppComponentActionTypes.UPDATE_FILTER_SUCCESS: {
      return {
        ...state,
        updateFilterLoader: false,
        updateFilterSuccess: true,
        updateFilterError: {},
      };
    }
    case AppComponentActionTypes.UPDATE_FILTER_FAILURE: {
      return {
        ...state,
        updateFilterLoader: false,
        updateFilterError: {
          status: true,
          message: action.payload.message,
        },
      };
    }

    case AppComponentActionTypes.INVALIDATE: {
      return {
        ...state,
        invalidateLoader: true,
      };
    }
    case AppComponentActionTypes.INVALIDATE_SUCCESS: {
      return {
        ...state,
        invalidateSuccess: true,
        invalidateLoader: false,
      };
    }
    case AppComponentActionTypes.INVALIDATE_FAILURE: {
      return {
        ...state,
        invalidateSuccess: false,
        invalidateLoader: false,
      };
    }

    case AppComponentActionTypes.LOAD_FAILED: {
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    }

    case AppComponentActionTypes.CHANGE_UNIT_COMPONENTS_STATUS: {
      return {
        ...state,
        unitComponentsStatus: action.payload.status,
      };
    }

    case AppComponentActionTypes.RESET: {
      return {
        ...state,
        newComponentSuccess: false,
        newComponentFailure: false,
        updateComponentSuccess: false,
        updateComponentFailure: false,
        uploadImageSuccess: false,
        uploadImageFailure: false,
        deleteImageSuccess: false,
        deleteImageFailure: false,
        updateImageSuccess: false,
        updateImageFailure: false,
        createComponentUnitSuccess: false,
        createComponentUnitFailure: false,
        updateComponentUnitSuccess: false,
        updateComponentUnitFailure: false,
        listLoading: false,
        addFilterLoader: false,
        addFilterSuccess: false,
        updateFilterLoader: false,
        updateFilterSuccess: false,
        loader: false,
        invalidateSuccess: false,
        invalidateLoader: false,

        addFilterError: {},
        updateFilterError: {},
        error: {},
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
