import { useState } from 'react';
import get from 'lodash.get';
import { Col, Input, Row, Typography, Button, Form, Alert } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux';
import stockValidationAction from '../../redux/actions/stock-validation/stockValidationAction';
import {
  Cardtable,
  errorNotification,
  successNotification,
} from '../../components';
import { formatDate, isEmptyObject } from '../../util';

const columns = [
  {
    title: 'Store Id',
    key: 's_id',
    dataIndex: 's_id',
    width: 80,
  },
  {
    title: 'Store Name',
    key: 'store_name',
    dataIndex: 'store_name',
    width: 100,
  },
  {
    title: 'Product Id',
    key: 'product_id',
    dataIndex: 'product_id',
    width: 80,
  },
  {
    title: 'Product Name',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: 'Invoice Date',
    key: 'invoice_date',
    dataIndex: 'invoice_date',
    type: 'input',
    width: 100,
  },
  {
    title: 'Invoice ',
    key: 'invoice',
    dataIndex: 'invoice',
    type: 'input',
    width: 100,
  },
  {
    title: 'Order Id',
    key: 'order_id',
    dataIndex: 'order_id',
    type: 'input',
    width: 80,
  },
  {
    title: 'Order Date',
    key: 'order_date',
    dataIndex: 'order_date',
    render: (text: string) => formatDate(text, 'YYYY-MMM-DD'),
    type: 'input',
    width: 100,
  },
  {
    title: 'Quantity Delivered',
    key: 'quantity',
    dataIndex: 'quantity',
    type: 'input',
    width: 100,
  },
  {
    title: 'Quantity Returned',
    key: 'returned_quantity',
    dataIndex: 'returned_quantity',
    type: 'input',
    width: 100,
  },
  {
    title: 'Credit Note',
    key: 'credit_note',
    dataIndex: 'credit_note',
    type: 'input',
    width: 100,
  },
];

const StockValidation = () => {
  const dispatch = useAppDispatch();
  const {
    storeProductItems,
    loader,
    defaultStoreId,
    defaultProductId,
    CSVloader,
    CSVsuccess,
    CSVfailure,
  } = useAppSelector(state => state.stockValidation);
  const [storeId, setStoreId] = useState<any>(defaultStoreId);
  const [productId, setProductId] = useState<any>(defaultProductId);
  const [form] = Form.useForm();

  const getData = () => {
    if (storeId && productId) {
      dispatch(
        stockValidationAction.getRecords({
          store_id: storeId,
          product_id: productId,
        })
      );
    }
  };

  const IsValid =
    parseInt(storeProductItems.current_pos_stock, 10) ===
    parseInt(storeProductItems.prensent_at_store, 10);

  const resetInput = () => {
    dispatch(stockValidationAction.resetInputs());
    form.setFields([
      { name: 'StoreId', value: '' },
      { name: 'ProductId', value: '' },
    ]);
    setProductId(null);
    setStoreId(null);
  };

  const exportCSV = () => {
    dispatch(
      stockValidationAction.exportCSV({
        store_id: storeId,
        product_id: productId,
      })
    );
  };

  if (!isEmptyObject(CSVfailure)) {
    const key = 'error';
    const args = {
      message: CSVfailure?.name,
      key,
      description: CSVfailure?.message,
      duration: 2,
    };
    errorNotification(args);
  }

  if (CSVsuccess) {
    const key = 'Success';
    const args = {
      message: 'Success',
      key,
      description: 'Exported successfully',
      duration: 2,
    };
    successNotification(args);

    dispatch(stockValidationAction.resetExport());
  }

  const heading = () => (
    <Row justify="space-between">
      <Col>
        <Typography.Text strong>Stock Validation</Typography.Text>
      </Col>
    </Row>
  );

  const hasData =
    (storeProductItems.net_delivered ||
      storeProductItems.audited_stock ||
      storeProductItems.pos_sales ||
      storeProductItems.prensent_at_store) !== 0;

  const summary = () => (
    <div>
      {!isEmptyObject(storeProductItems) && hasData && (
        <Row>
          <Col span={10}>
            {IsValid ? (
              <Alert
                message="Calculated present at store and current system stock is matched."
                type="success"
              />
            ) : (
              <Alert
                message={`Calculated present at store(${storeProductItems.prensent_at_store}) and 
                  current system stock(${storeProductItems.current_pos_stock}) is not matched.`}
                type="error"
              />
            )}
          </Col>
          <Col span={8} style={{ textAlign: 'right' }} offset={6}>
            <h3>Net delivered: {storeProductItems.net_delivered}</h3>
            <h3>Audited Stock: {storeProductItems.audited_stock}</h3>
            <h3>Total Pos Sales: {storeProductItems.pos_sales}</h3>
            <h3>Present at Store: {storeProductItems.prensent_at_store}</h3>
            <h3>Current POS Stock: {storeProductItems.current_pos_stock}</h3>
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form onSubmitCapture={getData} form={form} layout="vertical">
            <Input.Group compact>
              <Form.Item name="StoreId" label="Store Id">
                <Input
                  placeholder="Store Id"
                  type="number"
                  style={{ width: '200px', marginRight: '10px' }}
                  defaultValue={defaultStoreId}
                  onChange={e => {
                    setStoreId(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item name="ProductId" label="Product Id">
                <Input
                  placeholder="Product Id"
                  type="number"
                  style={{ width: '200px', marginRight: '10px' }}
                  defaultValue={defaultProductId}
                  onChange={e => {
                    setProductId(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loader}
                  disabled={
                    !storeId ||
                    !productId ||
                    (storeId && storeId < 1) ||
                    (productId && productId < 1) ||
                    loader ||
                    CSVloader
                  }
                  style={{ marginRight: '10px' }}
                >
                  Get Report
                </Button>
                <Button
                  htmlType="button"
                  onClick={resetInput}
                  disabled={(!storeId && !productId) || loader || CSVloader}
                  style={{ marginRight: '10px' }}
                >
                  Reset
                </Button>
                <Button
                  onClick={exportCSV}
                  disabled={
                    (!storeId && !productId) ||
                    loader ||
                    CSVloader ||
                    isEmptyObject(storeProductItems) ||
                    storeProductItems.units.length === 0
                  }
                  loading={CSVloader}
                  style={{ marginRight: '10px' }}
                >
                  Generate CSV
                </Button>
              </Form.Item>
            </Input.Group>
          </Form>
        </Col>
      </Row>
      <Cardtable
        // @ts-ignore
        title={heading}
        rowKey={(row: any) => row.id}
        dataSource={get(storeProductItems, 'units', [])}
        loading={loader}
        columns={columns}
        showPagination={false}
        footer={summary}
      />
    </>
  );
};

export default StockValidation;
