/* eslint-disable consistent-return */
import { snakeToCamel } from '.';

export const formatPermissions = () => {
  const user = localStorage.getItem('USER');
  if (user) {
    const { permissions } = JSON.parse(user);
    const scopes = permissions.reduce(
      (x: any, v: any) => ({ ...x, [snakeToCamel(v)]: v }),
      {}
    );
    return scopes;
  }
};

export const formatRoleWisePermissions = () => {
  const user = localStorage.getItem('USER');
  if (user) {
    const { roles, permissions } = JSON.parse(user);
    const newPermissions = permissions.map((value: string) => value);
    return { [roles[0]]: newPermissions };
  }
};

export const SCOPES: any = formatPermissions();

export const PERMISSIONS: any = formatRoleWisePermissions();
