import ROLE_API from '../../../api/roleAPI';
import { RoleActionType } from '../ActionTypes';

const getRoles = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RoleActionType.GET_ROLES,
    });
    const res = await ROLE_API.getRoles(params);
    dispatch({
      type: RoleActionType.GET_ROLES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RoleActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getAllRoles = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RoleActionType.GET_ALL_ROLES,
    });
    const res = await ROLE_API.getAllRoles();
    dispatch({
      type: RoleActionType.GET_ALL_ROLES_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: RoleActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getPermissions = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RoleActionType.GET_PERMISSIONS,
    });
    const res = await ROLE_API.getPermissions();
    dispatch({
      type: RoleActionType.GET_PERMISSIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: RoleActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getRoleById = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: RoleActionType.GET_ROLE,
    });
    const res = await ROLE_API.getRole(userId);
    dispatch({
      type: RoleActionType.GET_ROLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: RoleActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const createRole = (data: object) => async (dispatch: any) => {
  dispatch({
    type: RoleActionType.CREATE_ROLE,
  });
  return ROLE_API.creeateRole(data).then(response => {
    dispatch({
      type: RoleActionType.CREATE_ROLE_SUCCESS,
    });
    return response;
  });
};

const updateRole = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: RoleActionType.UPDATE_ROLE,
    });
    await ROLE_API.updateRole(data);
    dispatch({
      type: RoleActionType.UPDATE_ROLE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: RoleActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

export {
  getRoles,
  getAllRoles,
  getPermissions,
  getRoleById,
  createRole,
  updateRole,
};
