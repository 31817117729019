import { RecommendationActionTypes } from '../../actions/ActionTypes';

interface RecommendationState {
  recommendations: object;
  recommendation: object;
  isError: object;
  error: boolean;
  Loader: boolean;
  detailLoader: boolean;
  recommendationsCreated: boolean;
  recommendationError: object;

  recommendationsUpdate: boolean;
}

const initialState: RecommendationState = {
  recommendations: {},
  recommendation: {},
  isError: {},
  error: false,
  Loader: false,
  detailLoader: false,
  recommendationsCreated: false,
  recommendationError: {},

  recommendationsUpdate: false,
};

const reducer = (
  state: RecommendationState = initialState,
  action: any = {}
) => {
  switch (action.type) {
    // Get rules grid
    case RecommendationActionTypes.GET_RECOMMENDATION: {
      return {
        ...state,
        Loader: true,
      };
    }
    case RecommendationActionTypes.GET_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        recommendations: action.payload,
        Loader: false,
      };
    }
    case RecommendationActionTypes.GET_RECOMMENDATION_FAILURE: {
      return {
        ...state,
        error: true,
        Loader: false,
        isError: action.payload,
      };
    }
    case RecommendationActionTypes.GET_RECOMMENDATION_DETAIL: {
      return {
        ...state,
        Loader: true,
        error: false,
      };
    }
    case RecommendationActionTypes.GET_RECOMMENDATION_DETAIL_SUCCESS: {
      return {
        ...state,

        Loader: false,
        recommendation: action.payload.data,
      };
    }
    case RecommendationActionTypes.GET_RECOMMENDATION_DETAIL_FAILURE: {
      return {
        ...state,

        Loader: false,
        error: action.payload,
      };
    }

    case RecommendationActionTypes.CREATE_RECOMMENDATION: {
      return {
        ...state,
        recommendationsCreated: false,
        recommendationError: {},
      };
    }
    case RecommendationActionTypes.CREATE_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        recommendationsCreated: true,
        recommendationError: {},
      };
    }

    case RecommendationActionTypes.CREATE_RECOMMENDATION_FAILURE: {
      return {
        ...state,
        recommendationError: action.payload,
        recommendationsCreated: false,
      };
    }

    case RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        recommendationsCreated: true,
      };
    }

    case RecommendationActionTypes.UPDATE_RECOMMENDATION_FAILURE: {
      return {
        ...state,
        recommendationError: action.payload,
      };
    }

    case RecommendationActionTypes.RESET_RECOMMENDATION: {
      return {
        ...state,
        recommendationError: {},
        recommendationsCreated: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
