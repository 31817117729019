const config = {
  endpoint: {
    auth: '/auth',
    user: '/users',
    login: '/login',
    product: '/product',
    order: '/order',
    tag: '/tag',
    search: '/search',
    pos: '/pos-user',
    posRole: '/pos-role',
    store: '/store',
    cancellationReasons: '/cancellation-reasons',
    agent: '/agent',
    customer: '/customer',
    p2rdiscount: '/payment-display-cards',
    users: '/users',
    role: '/role',
    permission: '/permission',
    attributes: '/attributes',
    stockAudit: '/stock-audit',
    stockValidate: '/stock-product-report',
    taskApproval: '/task-approval',
    importerActions: '/import',
    chains: '/chains',
    rule: '/rule',
    util: '/utilities',
    report: '/report-downloads',
    transactions: '/transactions',
    refundTransactions: '/refund-transactions',
    disputes: '/disputes',
    acceptDisputes: '/disputes/accept',
    contestDisputes: '/disputes/contest',
    disputeEvidence: '/documents',
    downloadDocumentById: '/documents',
    getUpdatePaymentStatus: 'get-update-payment-status',
    downtimes: '/payment/downtime',
    qrCodes: '/qrcode',
    paymentLink: '/payment_links',
    qrCodesForStore: '/qrcode/store',
    closeQR: '/qrcode/cancel',
    dashboard: '/dashboard',
    logout: '/logout',
    appComponent: {
      default: '/app-component',
      components: '/app-components',
      componentsUnits: 'app-component-units',
      filter: 'app-components-filter',
    },
    generateToken: '/generate-token',
    resetPassword: '/reset-password',
    syncErrorLogs: '/sync-error-log',
    getDistinctStores: '/getDistinctStores',
    stories: '/stories',
    cart: '/cart',
    stockProductSalesReport: '/stock-product-sales-report',
    feedback: '/feedback',
    recommendation: '/recommendation',
    categories: '/categories',
    transactionByPayment: '/get-transaction-by-payment-id',
    campaigns: '/campaigns',
    states: '/states',
    cities: '/cities',
    pincodes: '/pincodes',
  },
};

export default config;
