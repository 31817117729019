import axios from './axios';
import config from './config';

const SYNC_ERROR_LOG_API = {
  getSyncErrorLogs: (params: any) =>
    axios({
      url: `${config.endpoint.syncErrorLogs}/`,
      method: 'GET',
      params,
    }),
};

// eslint-disable-next-line import/prefer-default-export
export default SYNC_ERROR_LOG_API;
