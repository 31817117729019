import CANCELLATION_REASON_API from '../../../api/cancellationReasonsAPI';
import { CancellationReasonsType } from '../ActionTypes';

const getCancellationReasons = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CancellationReasonsType.GET_CANCELLATION_REASONS,
    });
    const res = await CANCELLATION_REASON_API.getCancellationReasons(params);
    dispatch({
      type: CancellationReasonsType.GET_CANCELLATION_REASONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CancellationReasonsType.GET_CANCELLATION_REASONS_FAILURE,
      payload: e,
    });
  }
};

const postReason = (data: {}) => async (dispatch: any) => {
  dispatch({
    type: CancellationReasonsType.POST_CANCELLATION_REASON,
  });
  return CANCELLATION_REASON_API.postReason(data).then(response => {
    dispatch({
      type: CancellationReasonsType.POST_CANCELLATION_REASON_SUCCESS,
      payload: response.data,
    });
    return response;
  });
};

const updateReason = (data: {}) => async (dispatch: any) => {
  dispatch({
    type: CancellationReasonsType.POST_CANCELLATION_REASON,
  });
  return CANCELLATION_REASON_API.updateReason(data).then(response => {
    dispatch({
      type: CancellationReasonsType.POST_CANCELLATION_REASON_SUCCESS,
      payload: response.data,
    });
    return response;
  });
};

export { getCancellationReasons, postReason, updateReason };
