import POS_API from '../../../api/posAPI';
import { PosActionType } from '../ActionTypes';

const getAllPosRoles = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_ALL_POS_ROLES,
    });
    const res = await POS_API.getAllPosRoles(params);
    dispatch({
      type: PosActionType.GET_ALL_POS_ROLES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.GET_ALL_POS_ROLES_FAILURE,
      payload: e,
    });
  }
};

const createPosRoles = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.CREATE_POS_ROLE,
    });
    const res = await POS_API.createPosRole(data);
    dispatch({
      type: PosActionType.CREATE_POS_ROLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.CREATE_POS_ROLE_FAILURE,
      payload: e,
    });
  }
};

const getPosRoles = () => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_POS_ROLES,
    });
    const res = await POS_API.getPosRoles();
    dispatch({
      type: PosActionType.GET_POS_ROLES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.GET_POS_ROLES_FAILURE,
      payload: e,
    });
  }
};

const getPermissions = () => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_POS_PERMISSIONS,
    });
    const res = await POS_API.getPermissions();
    dispatch({
      type: PosActionType.GET_POS_PERMISSIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getRoleById = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_POS_ROLE,
    });
    const res = await POS_API.getRole(userId);
    dispatch({
      type: PosActionType.GET_POS_ROLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateRole = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.UPDATE_POS_ROLE,
    });
    await POS_API.updateRole(data);
    dispatch({
      type: PosActionType.UPDATE_POS_ROLE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

export {
  getPermissions,
  getRoleById,
  updateRole,
  getAllPosRoles,
  createPosRoles,
  getPosRoles,
};
