import POS_API from '../../../api/posAPI';
import { PosActionType } from '../ActionTypes';

const getPosUser = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_POS_USER,
    });
    const res = await POS_API.getPos(userId);
    dispatch({
      type: PosActionType.GET_POS_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.GET_POS_USER_FAILURE,
      payload: e,
    });
  }
};

const getAllPosUser = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.GET_ALL_POS_USER,
    });
    const res = await POS_API.getAllPos(params);
    dispatch({
      type: PosActionType.GET_ALL_POS_USER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.GET_ALL_POS_USER_FAILURE,
      payload: e,
    });
  }
};

const createPosUser = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.CREATE_POS,
    });
    const res = await POS_API.createPos(data);
    dispatch({
      type: PosActionType.CREATE_POS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.CREATE_POS_FAILURE,
      payload: e,
    });
  }
};

const updatePosUser = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.UPDATE_POS,
    });
    const res = await POS_API.updatePos(data);
    dispatch({
      type: PosActionType.UPDATE_POS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.UPDATE_POS_FAILURE,
      payload: e,
    });
  }
};

const resetPosAuth = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PosActionType.RESET_POS_AUTH,
    });
    const res = await POS_API.resetPosAuth(userId);
    dispatch({
      type: PosActionType.RESET_POS_AUTH_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PosActionType.RESET_POS_AUTH_FAILURE,
      payload: e,
    });
  }
};

const resetPos = (dispatch: any) => {
  dispatch({
    type: PosActionType.RESET_POS,
  });
};

export {
  getPosUser,
  createPosUser,
  updatePosUser,
  resetPos,
  getAllPosUser,
  resetPosAuth,
};
