import axios from './axios';
import config from './config';

const IMPORTER_ACTIONS_API = {
  getAllImporters: (params: Object) =>
    axios({
      url: `${config.endpoint.importerActions}/all`,
      method: 'GET',
      params,
    }),

  addNewImport: (data: Object) =>
    axios({
      url: `${config.endpoint.importerActions}`,
      method: 'POST',
      data,
    }),
};

export default IMPORTER_ACTIONS_API;
