import axios from './axios';
import config from './config';

const CANCELLATION_REASON_API = {
  getCancellationReasons: (params: any) =>
    axios({
      url: `${config.endpoint.cancellationReasons}`,
      method: 'GET',
      params,
    }),
  postReason: (data: any) =>
    axios({
      url: `${config.endpoint.cancellationReasons}/post`,
      method: 'POST',
      data,
    }),
  updateReason: (data: any) =>
    axios({
      url: `${config.endpoint.cancellationReasons}/{id}/update`,
      method: 'POST',
      data,
    }),
};
export default CANCELLATION_REASON_API;
