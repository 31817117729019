import CHAIN_API from '../../../api/chain.API';
import { ChainsActionType } from '../ActionTypes';

const getChainsById = (chainsId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: ChainsActionType.GET_CHAINS,
    });
    const res = await CHAIN_API.getChainsDetails(chainsId);
    dispatch({
      type: ChainsActionType.LOAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: ChainsActionType.LOAD_FAILED,
      payload: e,
    });
  }
};
const updateChainsInformation = (data: object) => (dispatch: any) => {
  dispatch({
    type: ChainsActionType.UPDATING_CHAINS_INFORMATION,
  });
  return CHAIN_API.postUpdateChainsInformation(data).then(response => {
    dispatch({
      type: ChainsActionType.UPDATE_CHAINS_INFORMATION_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};
export { getChainsById, updateChainsInformation };
