import ORDER_API from '../../../api/orderAPI';
import { getCookie } from '../../../util';
import { OrderActionType, ActivityActionType } from '../ActionTypes';

export interface OrderState {
  orderLoader: boolean;
  isError: boolean;
  order?: {};
  orders?: {};
}

const getOrders = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.GET_ORDERS,
    });
    const res = await ORDER_API.getOrders(params);
    dispatch({
      type: OrderActionType.GET_ORDERS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.GET_ORDERS_ERROR,
      payload: e,
    });
  }
};

const getCancelRequests = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.GET_CANCELLATION_REQUESTS,
    });
    const res = await ORDER_API.getCancelRequests(params);
    dispatch({
      type: OrderActionType.GET_CANCELLATION_REQUESTS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.GET_GET_CANCELLATION_REQUESTS_ERROR,
      payload: e,
    });
  }
};

const getOrder = (orderId: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.GET_ORDER,
    });
    const res = await ORDER_API.getOrderDetails(orderId);
    dispatch({
      type: OrderActionType.GET_ORDER_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.GET_ORDER_ERROR,
      payload: e,
    });
  }
};

const cancelOrder = (orderId: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.CANCEL_ORDER,
    });
    const res = await ORDER_API.cancelOrder(orderId);
    dispatch({
      type: OrderActionType.CANCEL_ORDER_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.CANCEL_ORDER_ERROR,
      payload: e,
    });
  }
};

const exportOrder = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.EXPORT_ORDER,
    });
    const res = await ORDER_API.exportOrder(params);
    dispatch({
      type: OrderActionType.EXPORT_ORDER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.EXPORT_ORDER_ERROR,
      payload: e,
    });
  }
};

const resetExport = (dispatch: any) => {
  dispatch({
    type: OrderActionType.RESET_EXPORT,
  });
};

const getOrderActivity =
  (orderId: string | undefined, type: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: ActivityActionType.GET_ACTIVITIES,
      });
      const res = await ORDER_API.getOrderActivity(orderId, type);
      dispatch({
        type: ActivityActionType.GET_ACTIVITIES_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: ActivityActionType.GET_ACTIVITIESS_ERROR,
        payload: e,
      });
    }
  };

const exportCancellationRequests = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: OrderActionType.EXPORT_CSV,
    });
    const res = await ORDER_API.exportCancellationRequests(params);
    dispatch({
      type: OrderActionType.EXPORT_CSV_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: OrderActionType.EXPORT_CSV_ERROR,
      payload: e,
    });
  }
};
const resetCSV = (dispatch: any) => {
  dispatch({
    type: OrderActionType.RESET_CSV,
  });
};

const fastForwardOrder = (orderID: any) => async (dispatch: any) => {
  dispatch({
    type: OrderActionType.FASTFORWARD_TIME,
  });
  return ORDER_API.fastForwardUpdatedAt(orderID).then(response => {
    dispatch({
      type: OrderActionType.FASTFORWARD_TIME_SUCCESS,
      payload: response.data,
    });
    return response;
  });
};
const getInvoiceDetailById =
  (orderId: any, refID: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: OrderActionType.GET_INVOICE_DETAILS,
      });
      const resData = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/order/invoice/${orderId}`,
        {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${getCookie('jwt')}`,
          }),
        }
      );
      if (resData.status === 200) {
        resData.arrayBuffer().then(res => {
          const content = new Blob([new Uint8Array(res)]);
          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `INVOICE-${refID}.pdf`);
          link.click();
          dispatch({
            type: OrderActionType.INVOICE_DOWNLOAD_SUCCESS,
            payload: res,
          });
        });
      } else {
        dispatch({
          type: OrderActionType.INVOICE_DOWNLOAD_FAILED,
          payload: resData,
        });
      }
    } catch (e) {
      dispatch({
        type: OrderActionType.INVOICE_DOWNLOAD_FAILED,
        payload: e,
      });
    }
  };

// eslint-disable-next-line import/prefer-default-export
export {
  getOrder,
  getOrders,
  exportOrder,
  resetExport,
  cancelOrder,
  getOrderActivity,
  getCancelRequests,
  exportCancellationRequests,
  resetCSV,
  fastForwardOrder,
  getInvoiceDetailById,
};
