import { RuleActionTypes } from '../../actions/ActionTypes';

interface RulesState {
  rules: object;
  ruleLoader: boolean;
  ruleCreated: boolean;
  ruleError: boolean;
  rule: object;
  ruleUpdate: boolean;
  coupons: any;
  couponsLoader: boolean;
  // newcoupon: any;
  ruleCoupon: boolean;
  CSVLoader: boolean;
  CSVSuccess: boolean;
  CSVFailure: object;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
}

const initialState: RulesState = {
  rules: {},
  ruleLoader: false,
  ruleCreated: false,
  ruleError: false,
  ruleUpdate: false,
  rule: {},
  coupons: {},
  couponsLoader: false,
  // newcoupon: [],
  ruleCoupon: false,
  CSVLoader: false,
  CSVSuccess: false,
  CSVFailure: {},
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
};

const reducer = (state: RulesState = initialState, action: any = {}) => {
  switch (action.type) {
    // Get rules grid
    case RuleActionTypes.GET_RULES: {
      return {
        ...state,
        ruleLoader: true,
      };
    }
    case RuleActionTypes.GET_RULES_SUCCESS: {
      return {
        ...state,
        rules: action.payload,
        ruleLoader: false,
      };
    }
    case RuleActionTypes.CREATE_RULE: {
      return {
        ...state,
        ruleLoader: true,
      };
    }
    case RuleActionTypes.CREATE_RULE_SUCCESS: {
      return {
        ...state,
        ruleCreated: true,
        ruleLoader: false,
        rule: action.payload.data,
      };
    }
    case RuleActionTypes.CREATE_RULE_FAILED: {
      return {
        ...state,
        ruleError: true,
        ruleLoader: false,
      };
    }
    case RuleActionTypes.UPDATE_RULE: {
      return {
        ...state,
        ruleLoader: true,
      };
    }
    case RuleActionTypes.UPDATE_RULE_SUCCESS: {
      return {
        ...state,
        ruleUpdate: true,
        ruleLoader: false,
        ruleCoupon: true,
      };
    }
    case RuleActionTypes.UPDATE_RULE_FAILED: {
      return {
        ...state,
        ruleLoader: false,
      };
    }
    case RuleActionTypes.RESET_RULE: {
      return {
        ...state,
        ruleError: false,
        ruleCreated: false,
        ruleUpdate: false,
        ruleLoader: false,
      };
    }
    case RuleActionTypes.RESET_COUPON: {
      return {
        ...state,
        ruleCoupon: false,
        ruleLoader: false,
      };
    }
    case RuleActionTypes.RESET_EXPORT: {
      return {
        ...state,
        CSVSuccess: false,
      };
    }
    case RuleActionTypes.RESET_COUPON_EXPORT: {
      return {
        ...state,
        exportSuccess: false,
      };
    }
    case RuleActionTypes.GET_RULE_SUCCESS: {
      return {
        ...state,
        rule: action.payload,
      };
    }
    case RuleActionTypes.GET_RULE_COUPON: {
      return {
        ...state,
        couponsLoader: true,
      };
    }
    case RuleActionTypes.GET_RULE_COUPON_SUCCESS: {
      return {
        ...state,
        coupons: action.payload,
        couponsLoader: false,
      };
    }
    case RuleActionTypes.UPDATE_RULE_COUPON: {
      return {
        ...state,
        couponsLoader: true,
      };
    }
    case RuleActionTypes.EXPORT_CSV:
      return {
        ...state,
        CSVLoader: true,
        CSVFailure: {},
      };
    case RuleActionTypes.EXPORT_CSV_SUCCESS:
      return {
        ...state,
        CSVLoader: false,
        CSVSuccess: true,
        CSVFailure: {},
      };
    case RuleActionTypes.EXPORT_CSV_FAILURE:
      return {
        ...state,
        CSVLoader: false,
        CSVFailure: action.payload,
      };
    case RuleActionTypes.EXPORT_COUPON_CSV:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case RuleActionTypes.EXPORT_COUPON_CSV_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case RuleActionTypes.EXPORT_COUPON_CSV_FAILURE:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case RuleActionTypes.UPDATE_RULE_COUPON_SUCCESS: {
      const index = state.coupons.results.findIndex(
        (storePrice: any) => storePrice.id === action.payload.id
      );
      const results = [...state.coupons.results];
      results[index].is_visible = action.payload.is_visible ? 1 : 0;
      return {
        ...state,
        coupons: {
          ...state.coupons,
          results,
        },
        couponsLoader: false,
      };
    }
    // case RuleActionTypes.UPDATE_RULE_COUPON_SUCCESS: {
    //   const index = state.coupons.results.findIndex(
    //     (coupon: any) => coupon.id === action.payload.id
    //   );
    //   const results = [...state.coupons.results];
    //   results[index].is_visible = action.payload.is_visible ? 1 : 0;
    //   const newcoupons = [...state.newcoupon, results[index]];
    //   const arr: any = [];
    //   newcoupons.forEach(datum => {
    //     if (!arr.find((item: any) => item.code === datum.code)) {
    //       arr.push(datum);
    //     }
    //   });
    //   return {
    //     ...state,
    //     coupons: {
    //       ...state.coupons,
    //       results,
    //     },
    //     newcoupon: arr,
    //   };
    // }
    default: {
      return state;
    }
  }
};

export default reducer;
