import { Button, Col, Row, Select, Typography } from 'antd';
import get from 'lodash.get';
import { useState } from 'react';
import { RetweetOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../redux';
import { Cardtable } from '../../components';
import { formatDate } from '../../util';
import { getDowntimes } from '../../redux/actions/payment/paymentAction';

const Downtimes = () => {
  const { downtimes, downtimesLoader } = useAppSelector(
    state => state.transactions
  );

  const [driverId, setDriverId] = useState('1');

  const dispatch = useAppDispatch();

  const handleChange = (id: string) => {
    dispatch(getDowntimes(id));
    setDriverId(driverId);
  };

  const getData = (params: any) => {
    dispatch(getDowntimes(driverId));
  };

  const columns = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      type: 'input',
      width: 150,
    },
    {
      title: 'Scheduled',
      dataIndex: 'scheduled',
      type: 'input',
      render: (flag: boolean) => (flag ? 'Yes' : 'No'),
      width: 100,
    },
    {
      title: 'Method',
      dataIndex: 'method',
      type: 'input',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      type: 'input',
      width: 100,
    },
    {
      title: 'Begin',
      dataIndex: 'begin',
      type: 'input',
      render: (text: string) => formatDate(text, 'lll'),
      width: 150,
    },
    {
      title: 'Updated At',
      dataIndex: 'created_at',
      type: 'input',
      render: (text: string) => formatDate(text, 'lll'),
      width: 150,
    },
  ];

  const heading = () => (
    <Row justify="space-between">
      <Row>
        <Col>
          <Typography.Text strong>Downtimes</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Button
          style={{ marginRight: '20px' }}
          onClick={() => {
            getData({});
          }}
        >
          <RetweetOutlined /> Refresh
        </Button>
        <Select
          defaultValue="1"
          onChange={handleChange}
          options={[
            {
              value: '1',
              label: 'Razorpay Driver 1',
            },
            {
              value: '2',
              label: 'Razorpay Driver 2',
            },
          ]}
        />
      </Row>
    </Row>
  );

  return (
    <Cardtable
      columns={columns}
      total={get(downtimes, 'count', 0)}
      getData={getData}
      // @ts-ignore
      title={heading}
      loading={downtimesLoader}
      rowKey="id"
      dataSource={get(downtimes, 'items', [])}
      showPagination
      expandable={{
        // eslint-disable-next-line react/no-unstable-nested-components
        expandedRowRender: (record: any) => (
          <Typography.Paragraph style={{ textTransform: 'capitalize' }}>
            {Object.keys(record.instrument).map(element => (
              <Typography.Paragraph>
                {element} : {record.instrument[element]}
              </Typography.Paragraph>
            ))}
            <Typography.Paragraph>Id : {record.id}</Typography.Paragraph>
            <Typography.Paragraph>
              End :{' '}
              {record.end
                ? formatDate(String(record.begin), 'lll')
                : record.end}
            </Typography.Paragraph>
          </Typography.Paragraph>
        ),
      }}
    />
  );
};

export default Downtimes;
