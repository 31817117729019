import axios from './axios';
import config from './config';

const ROLE_API = {
  getRoles: (params: any) =>
    axios({
      url: `${config.endpoint.role}`,
      method: 'GET',
      params,
    }),

  getAllRoles: () =>
    axios({
      url: `${config.endpoint.role}/all`,
      method: 'GET',
    }),

  getPermissions: () =>
    axios({
      url: `${config.endpoint.role}/permission`,
      method: 'GET',
    }),

  getRole: (roleId: string) =>
    axios({
      url: `${config.endpoint.role}/${roleId}`,
      method: 'GET',
    }),

  creeateRole: (data: object) =>
    axios({
      url: `${config.endpoint.role}/create`,
      method: 'POST',
      data,
    }),

  updateRole: (data: object) =>
    axios({
      url: `${config.endpoint.role}/update`,
      method: 'POST',
      data,
    }),
};

export default ROLE_API;
