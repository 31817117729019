import axios from './axios';
import config from './config';

const CAMPAIGN_API = {
  getCampaigns: (params: any) =>
    axios({
      url: `${config.endpoint.campaigns}`,
      method: 'GET',
      params,
    }),

  getCampaignDetails: (campaignId: string | undefined) =>
    axios({
      url: `${config.endpoint.campaigns}/${campaignId}`,
      method: 'GET',
    }),
  postCampaignData: (data: object) =>
    axios({
      url: `${config.endpoint.campaigns}/create`,
      method: 'POST',
      data,
    }),
  updateCampaignData: (data: object, campaignId: number) =>
    axios({
      url: `${config.endpoint.campaigns}/update/${campaignId}`,
      method: 'POST',
      data,
    }),
};
export default CAMPAIGN_API;
