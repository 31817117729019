import AGENT_API from '../../../api/agentAPI';
import { AgentActionType } from '../ActionTypes';

const getAgents = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: AgentActionType.GET_AGENTS,
    });
    const res = await AGENT_API.getAgents(params);
    dispatch({
      type: AgentActionType.GET_AGENTS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: AgentActionType.LOAD_FAILED,
      payload: e,
    });
  }
};
const getAgentById = (agentId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: AgentActionType.GET_AGENT,
    });
    const res = await AGENT_API.getAgentDetails(agentId);
    dispatch({
      type: AgentActionType.GET_AGENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: AgentActionType.LOAD_FAILED,
      payload: e,
    });
  }
};
const updateAgentInformation = (data: object) => (dispatch: any) => {
  dispatch({
    type: AgentActionType.UPDATING_AGENT_INFORMATION,
  });
  return AGENT_API.postUpdateAgentInformation(data).then(response => {
    dispatch({
      type: AgentActionType.UPDATE_AGENT_INFORMATION_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};
const createAgentInformation = (data: object) => async (dispatch: any) => {
  dispatch({
    type: AgentActionType.CREATING_AGENT_INFORMATION,
  });
  return AGENT_API.postCreateAgentInformation(data).then(response => {
    dispatch({
      type: AgentActionType.CREATE_AGENT_INFORMATION_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

export {
  getAgents,
  getAgentById,
  updateAgentInformation,
  createAgentInformation,
};
