import axios from './axios';
import config from './config';

const FEEDBACKS_API = {
  getFeedbacks: (params: any) =>
    axios({
      url: `${config.endpoint.feedback}`,
      method: 'GET',
      params,
    }),
  exportCsv: (params: any) =>
    axios({
      url: `${config.endpoint.feedback}/csv`,
      method: 'GET',
      params,
    }),
};

export default FEEDBACKS_API;
