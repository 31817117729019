import axios from './axios';
import config from './config';

const CATEGORY_API = {
  getCategories: (params: any) =>
    axios({
      url: `${config.endpoint.categories}`,
      method: 'GET',
      params,
    }),
  getCategoriesDetails: (categoriesId: string | undefined) =>
    axios({
      url: `${config.endpoint.categories}/${categoriesId}`,
      method: 'GET',
    }),
};

export default CATEGORY_API;
