import axios from './axios';
import config from './config';

const TASK_APPROVAL_API = {
  getTaskApprovals: (params: any) =>
    axios({
      url: `${config.endpoint.taskApproval}`,
      method: 'GET',
      params,
    }),

  postUpdateStatus: (data: object) =>
    axios({
      url: `${config.endpoint.taskApproval}/status`,
      method: 'POST',
      data,
    }),
};

export default TASK_APPROVAL_API;
