import { TagActionType } from '../../actions/ActionTypes';

interface TagState {
  tagLoader: boolean;
  tags: object;
  tag: any;
  assignedValue: any;
  isError: boolean;
  assignError: boolean;
  filterTypes: object;
  dynamicItems: any;
  dynamicLoader: boolean;
  assigned: boolean;
}

const initialState: TagState = {
  tagLoader: false,
  tags: {},
  tag: {},
  assignedValue: {},
  isError: false,
  assignError: false,
  filterTypes: {},
  dynamicItems: [],
  dynamicLoader: false,
  assigned: false,
};

const reducer = (state: TagState = initialState, action: any = {}) => {
  switch (action.type) {
    // Getting tag grid
    case TagActionType.GET_TAGS: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
        tag: {},
        tagLoader: false,
      };
    }

    // Getting assigned tag details
    case TagActionType.GET_ASSIGNED_TAG_VALUES: {
      return {
        ...state,
        tagLoader: true,
      };
    }

    case TagActionType.GET_ASSIGNED_TAG_VALUES_SUCCESS: {
      return {
        ...state,
        assignedValue: action.payload,
        tagLoader: false,
      };
    }

    // Getting tag details
    case TagActionType.GET_TAG: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.GET_TAG_SUCCESS: {
      return {
        ...state,
        tag: action.payload,
        tagLoader: false,
      };
    }

    // Creating a new tag
    case TagActionType.CREATE_TAG: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.CREATE_TAG_SUCCESS: {
      return {
        ...state,
        tagLoader: false,
      };
    }

    // Updating a tag
    case TagActionType.UPDATE_TAG: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        tagLoader: false,
      };
    }

    // Assigning a tag
    case TagActionType.ASSIGN_TAG: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.ASSIGN_TAG_SUCCESS: {
      action.payload.data.isNew = true;
      return {
        ...state,
        assignedValue: {
          ...state.assignedValue,
          results: [action.payload.data, ...state.assignedValue.results],
          count: state.assignedValue.count + 1,
        },
        tagLoader: false,
        assignError: false,
      };
    }

    // Unassigning a tag
    case TagActionType.UNASSIGN_TAG: {
      return {
        ...state,
        tagLoader: true,
      };
    }
    case TagActionType.UNASSIGN_TAG_SUCCESS: {
      return {
        ...state,
        assignedValue: {
          ...state.assignedValue,
          results: [
            ...state.assignedValue.results.filter(
              (record: any) => record.id !== action.payload.data.type_id[0]
            ),
          ],
          count: state.assignedValue.count - 1,
        },
        tagLoader: false,
      };
    }
    case TagActionType.ASSIGN_TAG_FAIL: {
      return {
        ...state,
        assignError: true,
      };
    }

    case TagActionType.UNASSIGN_TAG_FAIL: {
      return {
        ...state,
        assignError: true,
      };
    }

    case TagActionType.LOAD_FAILED: {
      return {
        ...state,
        tagLoader: false,
      };
    }
    case TagActionType.SEARCH_TAG_FIELDS: {
      return {
        ...state,
        filterTypes: {},
      };
    }
    case TagActionType.SEARCH_TAG_FIELDS_SUCCESS: {
      return {
        ...state,
        filterTypes: action.payload.data.data,
      };
    }
    case TagActionType.SEARCH_TAG_FIELDS_FAILURE: {
      return {
        ...state,
        filterTypes: {},
      };
    }
    case TagActionType.SEARCH_RELATED_ITEMS: {
      return {
        ...state,
        dynamicItems: [],
        dynamicLoader: true,
      };
    }
    case TagActionType.SEARCH_RELATED_ITEMS_SUCCESS: {
      return {
        ...state,
        dynamicItems: action.payload,
        dynamicLoader: false,
      };
    }
    case TagActionType.SEARCH_RELATED_ITEMS_FAILURE: {
      return {
        ...state,
        dynamicItems: [],
        dynamicLoader: false,
      };
    }
    case TagActionType.ASSIGN_ITEM: {
      return {
        ...state,
        assigned: false,
      };
    }
    case TagActionType.ASSIGN_ITEM_SUCCESS: {
      return {
        ...state,
        assigned: true,
        assignedValue: {
          ...state.assignedValue,
          results: [...action.payload.data, ...state.assignedValue.results],
          count: state.assignedValue.count + action.payload.data.length,
        },
        tag: {
          ...state.tag,
          products: [...action.payload.data, ...state.tag.products],
        },
      };
    }
    case TagActionType.ASSIGN_ITEM_FAILURE: {
      return {
        ...state,
        assigned: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
