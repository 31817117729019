/* eslint-disable import/prefer-default-export */

export const finalAttributes: any = [
  {
    attribute_id: 1,
    itemName: 'Monthly Rental (INR)',
    id: 1,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Rent',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 2,
    itemName: 'Rent Share',
    id: 2,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Rent',
    value: '0',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 3,
    itemName: 'Rent Accrual Date',
    id: 3,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Rent',
    value: '7',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 4,
    itemName: 'Area (in sq. ft.)',
    id: 4,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Area and footfall',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 11,
    itemName: 'Inventory Norm. SqFt.',
    id: 11,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Area and footfall',
    value: '1500',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 12,
    itemName: 'Daily footfall',
    id: 12,
    input_type: 'string',
    is_required: 0,
    status: 1,
    name: 'Area and footfall',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 13,
    itemName: 'Monthly Income',
    id: 13,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Area and footfall',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 5,
    itemName: 'Base daily sales',
    id: 5,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Commercials',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 6,
    itemName: 'Base gross margin (base_gross_margin_pc)',
    id: 6,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Commercials',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 7,
    itemName: 'Base MRP inventory',
    id: 7,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Commercials',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 8,
    itemName: 'Current MRP inventory',
    id: 8,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Commercials',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 9,
    itemName: 'Unit Rate',
    id: 9,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Electricity',
    value: '',
    date_from: '',
    date_to: '',
  },
  {
    attribute_id: 10,
    itemName: 'Share',
    id: 10,
    input_type: 'number',
    is_required: 0,
    status: 1,
    name: 'Electricity',
    value: '05',
    date_from: '',
    date_to: '',
  },
];

export const rootSubmenuKeys = [
  'Dashboard',
  'Orders',
  'Products',
  'Stores',
  'Rule Engine',
  'Permissions',
  'Settings',
  'Tags',
  'Customers',
  'Stock Audits',
  'Agent',
  'Report',
  'Downloads',
  'Payments',
];

export const DownloadStatus = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const CauserType: any = {
  customer: 'customers',
  pos_user: 'pos-users',
  user: 'users',
};

export const OrderStatus: any = {
  cancelled: 'red',
  fulfilled: 'green',
  hold: 'orange',
  pending: 'blue',
  returned: 'cyan',
  pending_payment: 'blue',
  confirmed: 'green',
  completed: 'green',
  rejected: 'red',
};

export const DisputeStatus: any = {
  won: 'green',
  lost: 'blue',
  closed: 'cyan',
  open: 'red',
  under_review: 'orange',
};

export const PaymentStatus: any = {
  pending: 'orange',
  completed: 'green',
  cancelled: 'red',
};

export const PlatformType: any = {
  pos: '#2db7f5',
  customer: '#87d068',
};

export const SourceType: any = {
  order: 'orders',
  referral: 'customers',
};
export const Driver: any = {
  1: 'Razorpay',
  2: 'Razorpay',
  3: 'Paytm',
};

// rules

export const RulesData = {
  description: '',
  ends_at: '',
  mode: '',
  name: '',
  stop: '',
  position: '',
  starts_at: '',
  status: '',
  type: 'pricing',
  businessType: 'both',
  disable: true,
};

export const LimitsData = {
  user_limit: '',
  device_limit: '',
  store_limit: '',
  total_limit: '',
  coupon_limit: '',
  terms: '',
  disable: false,
};

export const ConstraintsData = { constraints: [], disable: true };
export const CouponsData = {
  coupon: [],
  quantity: '',
  prefix: '',
  is_visible: '',
  disable: false,
};

export const ActionData = {
  max: '',
  type: '',
  value: '',
  step: '',
  quantity: '',
  min_cart_value: '',
  payment_method: 3,
  products: [],
  disable: true,
};

export const AuditData: any = {
  requested: [
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'discard',
      label: 'Discard',
    },
  ],
  approved: [
    {
      value: 'discard',
      label: 'Discard',
    },
    {
      value: 'ongoing',
      label: 'Ongoing',
    },
  ],
  discard: [],
  processing: [],
  ongoing: [
    {
      value: 'complete',
      label: 'Complete',
    },
  ],
  sign_off: [
    {
      value: 'close',
      label: 'Close',
    },
    {
      value: 'close_without_mapping',
      label: 'Close Without Mapping',
    },
  ],
  close: [],
  complete: [
    {
      value: 'ongoing',
      label: 'Ongoing',
    },
  ],
  close_without_mapping: [],
};

export const productData: any = {
  brand_id: 'brands',
  category_id: 'categories',
};
