import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import DisputeDetails from './pages/Payments/DisputeDetails';
import DriverDowntimes from './pages/Payments/DriverDowntimes';
import QRDetails from './pages/Payments/QRCodeDetails';
import QrCodes from './pages/Payments/QrCodes';
import CancellationReasons from './pages/Settings/Cancellation Reasons/CancellationReasons';
import StockValidation from './pages/StockValidation/StockValidation';
import PermissionsGate from './services/permissionGate';
import { SCOPES } from './util/permissionMapper';
import P2RDiscountID from './pages/P2RDiscountSettings/P2RDiscountID';
import P2RDiscountIdDetails from './pages/P2RDiscountSettings/P2RDiscountIdDetails';
import AssociatedDevicesDetails from './pages/Customers/AssociatedDevicesDetails';
import AssociatedDeviceId from './pages/Customers/AssociatedDeviceId';

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Orders = lazy(() => import('./pages/Orders/Orders'));
const Logs = lazy(() => import('./pages/Logs/Logs'));
const CancellationRequest = lazy(
  () => import('./pages/Orders/CancellationRequest')
);
const OrderDetails = lazy(() => import('./pages/Orders/OrderDetails'));
const SecondaryStockValidation = lazy(
  () => import('./pages/StockValidation/SecondaryStockValidation')
);
const Products = lazy(() => import('./pages/Products/AllProducts'));
const ProductDetails = lazy(() => import('./pages/Products/ProductDetails'));
const PosUser = lazy(() => import('./pages/Store/POS/PosUser'));
const PosRoles = lazy(() => import('./pages/Store/POS/PosRoles'));
const PosRoleDetails = lazy(() => import('./pages/Store/POS/PosRoleDetails'));
const Store = lazy(() => import('./pages/Store/AllStores/Store'));
const StoreUpdate = lazy(() => import('./pages/Store/AllStores/StoreUpdate'));
const StoreCreate = lazy(() => import('./pages/Store/AllStores/StoreSteps'));
const Tags = lazy(() => import('./pages/Tags/Tags'));
const CreateTag = lazy(() => import('./pages/Tags/CreateTag'));
const EditTag = lazy(() => import('./pages/Tags/EditTag'));
const ProductGroups = lazy(() => import('./pages/Products/ProductGroups'));
const ProductGroupDetails = lazy(
  () => import('./pages/Products/ProductGroupDetail')
);
const Agents = lazy(() => import('./pages/Agent/Agent'));
const Feedbacks = lazy(() => import('./pages/Feedbacks/Feedbacks'));
const AgentDetails = lazy(() => import('./pages/Agent/AgentDetails'));
const AllCustomers = lazy(() => import('./pages/Customers/AllCustomers'));
const P2RDiscount = lazy(
  () => import('./pages/P2RDiscountSettings/P2RDiscount')
);
const CartDetails = lazy(() => import('./pages/Customers/CartDetails'));
const CustomerDetails = lazy(() => import('./pages/Customers/CustomerDetails'));
const Users = lazy(() => import('./pages/Permissions/Users/Users'));
const Roles = lazy(() => import('./pages/Permissions/Roles/Roles'));
const RoleDetails = lazy(() => import('./pages/Permissions/Roles/RoleDetails'));
const AllStockAudits = lazy(() => import('./pages/StockAudits/AllStockAudits'));
const ComponentsList = lazy(
  () => import('./pages/Settings/AppComponents/ComponentsList')
);
const Stories = lazy(() => import('./pages/Stories/stories'));
const StoryDetails = lazy(() => import('./pages/Stories/StoryDetails'));
const TaskApproval = lazy(
  () => import('./pages/Settings/Task Approvals/TaskApproval')
);
const ImporterActions = lazy(() => import('./pages/Importers/ImporterActions'));
const AddImport = lazy(() => import('./pages/Importers/AddImport'));
const ChainProfile = lazy(
  () => import('./pages/Settings/Chain Profile/ChainProfile')
);
const StockAuditView = lazy(() => import('./pages/StockAudits/StockAuditView'));
const Recommendation = lazy(
  () => import('./pages/Recommendations/Recommendation')
);
const RecommendationDetails = lazy(
  () => import('./pages/Recommendations/RecommendationDetails')
);

const RecommendationCreate = lazy(
  () => import('./pages/Recommendations/RecommendationCreate')
);
const Coupons = lazy(() => import('./pages/Rule/Coupons'));
const Rules = lazy(() => import('./pages/Rule/Rules'));
const CreateRule = lazy(() => import('./pages/Rule/CreateRule'));
const Report = lazy(() => import('./pages/Report/Report'));
const Transactions = lazy(() => import('./pages/Payments/Transactions'));
const Disputes = lazy(() => import('./pages/Payments/Disputes'));
const RefundTransactions = lazy(
  () => import('./pages/Payments/RefundTransactions')
);

const TransactionsDetails = lazy(
  () => import('./pages/Payments/TransactionsDetails')
);
const RefundTransactionDetails = lazy(
  () => import('./pages/Payments/RefundTransactionsDetails')
);

const Campaigns = lazy(() => import('./pages/Campaigns/Campaigns'));
const CampaignDetails = lazy(() => import('./pages/Campaigns/CampaignDetails'));

const Category = lazy(() => import('./pages/Category/Category'));
const CategoryDetails = lazy(() => import('./pages/Category/CategoryDetails'));
const SyncErrorLogs = lazy(() => import('./pages/SyncErrorLogs/SyncErrorLogs'));
const Decoder = lazy(() => import('./pages/Decoder'));
const NotFound = lazy(() => import('./pages/NotFound'));

const AppRouter = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route
        path="/"
        element={
          <PermissionsGate scopes={[SCOPES.viewDashboard]}>
            <Dashboard />
          </PermissionsGate>
        }
      />
      <Route
        path="/orders"
        element={
          <PermissionsGate scopes={[SCOPES.viewOrders]}>
            <Orders />
          </PermissionsGate>
        }
      />
      <Route
        path="/order-cancellation-requests"
        element={
          <PermissionsGate scopes={[SCOPES.viewOrders]}>
            <CancellationRequest />
          </PermissionsGate>
        }
      />
      <Route
        path="/orders/:orderId"
        element={
          <PermissionsGate scopes={[SCOPES.viewOrders]}>
            <OrderDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/products"
        element={
          <PermissionsGate scopes={[SCOPES.viewProduct]}>
            <Products />
          </PermissionsGate>
        }
      />
      <Route
        path="/tags"
        element={
          <PermissionsGate scopes={[SCOPES.viewProductTag]}>
            <Tags />
          </PermissionsGate>
        }
      />
      <Route
        path="/tags/create"
        element={
          <PermissionsGate scopes={[SCOPES.createProductTag]}>
            <CreateTag />
          </PermissionsGate>
        }
      />
      <Route
        path="/tags/:tagId"
        element={
          <PermissionsGate scopes={[SCOPES.viewProductTag]}>
            <EditTag />
          </PermissionsGate>
        }
      />
      <Route
        path="/products/:productId"
        element={
          <PermissionsGate scopes={[SCOPES.viewProduct]}>
            <ProductDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/product-groups"
        element={
          <PermissionsGate scopes={[SCOPES.viewProductGroup]}>
            <ProductGroups />
          </PermissionsGate>
        }
      />
      <Route
        path="/product-groups/:groupId"
        element={
          <PermissionsGate scopes={[SCOPES.viewProductGroup]}>
            <ProductGroupDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/stores"
        element={
          <PermissionsGate scopes={[SCOPES.viewStore]}>
            <Store />
          </PermissionsGate>
        }
      />
      <Route
        path="/stores/create"
        element={
          <PermissionsGate scopes={[SCOPES.createStore]}>
            <StoreCreate />
          </PermissionsGate>
        }
      />
      <Route
        path="/stores/:storeId"
        element={
          <PermissionsGate scopes={[SCOPES.viewStore]}>
            <StoreUpdate />
          </PermissionsGate>
        }
      />
      <Route
        path="/pos-users"
        element={
          <PermissionsGate scopes={[SCOPES.viewPosUsers]}>
            <PosUser />
          </PermissionsGate>
        }
      />
      <Route
        path="/logs/:id/:type"
        element={
          <PermissionsGate scopes={[SCOPES.viewActivityLogs]}>
            <Logs />
          </PermissionsGate>
        }
      />
      <Route
        path="/pos-roles"
        element={
          <PermissionsGate scopes={[SCOPES.viewPosUsers]}>
            <PosRoles />
          </PermissionsGate>
        }
      />

      <Route
        path="/pos-roles/:roleId"
        element={
          <PermissionsGate scopes={[SCOPES.viewPosUsers]}>
            <PosRoleDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/agents"
        element={
          <PermissionsGate scopes={[SCOPES.viewAgent]}>
            <Agents />
          </PermissionsGate>
        }
      />
      <Route
        path="/agents/:agentId"
        element={
          <PermissionsGate scopes={[SCOPES.viewAgent]}>
            <AgentDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/customers"
        element={
          <PermissionsGate scopes={[SCOPES.viewCustomer]}>
            <AllCustomers />
          </PermissionsGate>
        }
      />
      <Route path="/cart/:id" element={<CartDetails />} />
      <Route
        path="/customers/:customerId"
        element={
          <PermissionsGate scopes={[SCOPES.viewCustomer]}>
            <CustomerDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/users"
        element={
          <PermissionsGate scopes={[SCOPES.viewUser]}>
            <Users />
          </PermissionsGate>
        }
      />
      <Route
        path="/roles"
        element={
          <PermissionsGate scopes={[SCOPES.viewRole]}>
            <Roles />
          </PermissionsGate>
        }
      />
      <Route
        path="/roles/:roleId"
        element={
          <PermissionsGate scopes={[SCOPES.viewRole]}>
            <RoleDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/payment-display-cards"
        element={
          <PermissionsGate scopes={[SCOPES.viewP2RDisplay]}>
            <P2RDiscount />
          </PermissionsGate>
        }
      />
      <Route
        path="/payment-display-cards/exceptions/:p2rdiscountId"
        element={
          <PermissionsGate scopes={[SCOPES.viewP2RDisplay]}>
            <P2RDiscountID />
          </PermissionsGate>
        }
      />
      <Route
        path="/payment-display-cards/exceptions/id/:p2rdiscountDetailsId"
        element={
          <PermissionsGate scopes={[SCOPES.viewP2RDisplay]}>
            <P2RDiscountIdDetails />
          </PermissionsGate>
        }
      />
      <Route
        path="/stock-audits"
        element={
          <PermissionsGate scopes={[SCOPES.viewAudit]}>
            <AllStockAudits />
          </PermissionsGate>
        }
      />
      <Route
        path="/stock-audits/:stockAuditId"
        element={
          <PermissionsGate scopes={[SCOPES.viewAudit]}>
            <StockAuditView />
          </PermissionsGate>
        }
      />
      <Route
        path="/stock-validation"
        element={
          <PermissionsGate scopes={[SCOPES.viewValidStock]}>
            <StockValidation />
          </PermissionsGate>
        }
      />

      <Route
        path="/secondary-stock-validation"
        element={
          <PermissionsGate scopes={[SCOPES.viewAudit]}>
            <SecondaryStockValidation />
          </PermissionsGate>
        }
      />

      <Route path="/offers-and-promotions" element={<Recommendation />} />
      <Route
        path="/offers-and-promotions/:recommendationId"
        element={<RecommendationDetails />}
      />

      <Route
        path="/offers-and-promotions/create"
        element={<RecommendationCreate />}
      />

      <Route
        path="/app-components"
        element={
          <PermissionsGate scopes={[SCOPES.viewConsumerAppComponents]}>
            <ComponentsList />
          </PermissionsGate>
        }
      />
      <Route
        path="/task-approvals"
        element={
          <PermissionsGate scopes={[SCOPES.viewTaskApproval]}>
            <TaskApproval />
          </PermissionsGate>
        }
      />
      <Route
        path="/importers"
        element={
          <PermissionsGate scopes={[SCOPES.viewImporter]}>
            <ImporterActions />
          </PermissionsGate>
        }
      />
      <Route
        path="/importers/add"
        element={
          <PermissionsGate scopes={[SCOPES.createImporter]}>
            <AddImport />
          </PermissionsGate>
        }
      />
      <Route
        path="/chain-profile"
        element={
          <PermissionsGate scopes={[SCOPES.viewChainProfile]}>
            <ChainProfile />
          </PermissionsGate>
        }
      />
      <Route
        path="/rules"
        element={
          <PermissionsGate scopes={[SCOPES.viewRule]}>
            <Rules />
          </PermissionsGate>
        }
      />
      <Route
        path="/cancellation-reasons"
        element={
          <PermissionsGate scopes={[SCOPES.cancellationReasons]}>
            <CancellationReasons />
          </PermissionsGate>
        }
      />
      <Route
        path="/rules/create"
        element={
          <PermissionsGate scopes={[SCOPES.createRule]}>
            <CreateRule />
          </PermissionsGate>
        }
      />
      <Route
        path="/rules/:ruleId"
        element={
          <PermissionsGate scopes={[SCOPES.updateRule]}>
            <CreateRule />
          </PermissionsGate>
        }
      />
      <Route
        path="/coupons"
        element={
          <PermissionsGate scopes={[SCOPES.viewCoupon]}>
            <Coupons />
          </PermissionsGate>
        }
      />
      <Route path="/stories" element={<Stories />} />
      <Route path="/stories/:storyId" element={<StoryDetails />} />
      <Route path="/reports" element={<Report />} />

      <Route path="/transactions" element={<Transactions />} />
      <Route path="/disputes" element={<Disputes />} />
      <Route path="/disputes/:disputeId" element={<DisputeDetails />} />
      <Route path="/qr-codes" element={<QrCodes />} />
      <Route path="/qr-codes/:qrId" element={<QRDetails />} />

      <Route path="/refund-transactions" element={<RefundTransactions />} />

      <Route path="/downtime" element={<DriverDowntimes />} />

      <Route
        path="/transactions/:transactionsId"
        element={<TransactionsDetails />}
      />
      <Route
        path="/refund-transactions/:transactionsId"
        element={<RefundTransactionDetails />}
      />

      <Route path="/sync-error-logs" element={<SyncErrorLogs />} />

      <Route path="/Feedbacks" element={<Feedbacks />} />

      <Route path="/Category" element={<Category />} />
      <Route path="/Category/:categoriesId" element={<CategoryDetails />} />

      <Route path="/chumbak" element={<Campaigns />} />
      <Route path="/chumbak/:campaignId" element={<CampaignDetails />} />

      <Route
        path="/customer/:device_id"
        element={<AssociatedDevicesDetails />}
      />
      <Route
        path="/customer/:device_id/:customer_id"
        element={<AssociatedDeviceId />}
      />

      <Route path="/decode" element={<Decoder />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default AppRouter;
