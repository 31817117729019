import { FC, useEffect, useState } from 'react';
import { Alert } from 'antd';
import Layout from './layout';
import AppRouter from './AppRouter';
import PublicRouter from './PublicRouter';
import { Breadcrumbs } from './components';
import { useAppSelector } from './redux';
import { getCookie, getlocalStorage } from './util';

const App: FC = () => {
  const isGoogle = getlocalStorage('ISGOOGLE');
  const profile = getlocalStorage('USER');

  const jwt = getCookie('jwt');
  const { isLoggedInUser } = useAppSelector(state => state.data);
  const [user, setUser] = useState(false);

  // const validateUser = () =>
  //   new Promise((resolve, reject) => {
  //     resolve(dispatch(appAction.validateUser()));
  //     reject();
  //   })
  //     .then(() => {
  //       setValidating(false);
  //       // setUser(true);
  //     })
  //     .catch(() => {
  //       setValidating(false);
  //       // setUser(false);
  //     });

  useEffect(() => {
    if (jwt !== null) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, [jwt, isLoggedInUser]);

  // eslint-disable-next-line no-nested-ternary
  return !jwt ? (
    <PublicRouter />
  ) : (
    <Layout>
      {isGoogle &&
      profile &&
      profile.roles.length === 0 &&
      profile.permissions.length === 0 ? (
        <Alert
          message="Warning"
          type="info"
          description={`Hi ${profile.name} , your account has been created. Please ask your manager to assign a role. `}
          showIcon
        />
      ) : (
        <>
          <Breadcrumbs />
          <AppRouter />
        </>
      )}
    </Layout>
  );
};
export default App;
