import { OrderActionType, ActivityActionType } from '../../actions/ActionTypes';

interface OrderState {
  orderLoader: boolean;
  activityLoader: boolean;
  activity: any;
  order: object;
  orders: object;
  cancelRequests: object;
  isError: boolean;
  error: object;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
  cancelSucees: boolean;
  cancelError: object;
  CSVLoader: boolean;
  CSVSuccess: boolean;
  CSVFailure: object;
  fastforwarding: boolean;
  invoice: object;
  invoiceLoader: boolean;
  invoiceError: boolean;
}

const initialState: OrderState = {
  orderLoader: false,
  activityLoader: false,
  activity: [],
  order: {},
  orders: {},
  cancelRequests: {},
  isError: false,
  error: {},
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
  cancelSucees: false,
  cancelError: {},
  CSVLoader: false,
  CSVSuccess: false,
  CSVFailure: {},
  fastforwarding: false,
  invoice: {},
  invoiceLoader: false,
  invoiceError: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state: OrderState = initialState, action: any) => {
  switch (action.type) {
    case OrderActionType.GET_ORDERS:
      return {
        ...state,
        orderLoader: true,
        orders: {},
      };
    case OrderActionType.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        orderLoader: false,
      };
    case OrderActionType.GET_ORDERS_ERROR:
      return {
        ...state,
        isError: true,
        orderLoader: false,
        error: action.payload,
      };

    case OrderActionType.GET_CANCELLATION_REQUESTS:
      return {
        ...state,
        orderLoader: true,
      };
    case OrderActionType.GET_CANCELLATION_REQUESTS_SUCCESS:
      return {
        ...state,
        cancelRequests: action.payload,
        orderLoader: false,
      };
    case OrderActionType.GET_GET_CANCELLATION_REQUESTS_ERROR:
      return {
        ...state,
        isError: true,
        orderLoader: false,
        error: action.payload,
      };

    case OrderActionType.GET_ORDER:
      return {
        ...state,
        orderLoader: true,
        isError: false,
        order: {},
      };
    case OrderActionType.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoader: false,
        isError: false,
        error: {},
      };

    case OrderActionType.GET_ORDER_ERROR:
      return {
        ...state,
        isError: true,
        orderLoader: false,
        error: action.payload,
      };
    case OrderActionType.CANCEL_ORDER:
      return {
        ...state,
        cancelSucees: false,
        cancelError: {},
      };
    case OrderActionType.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        cancelSucees: true,
        cancelError: {},
      };
    case OrderActionType.CANCEL_ORDER_ERROR:
      return {
        ...state,
        cancelError: action.payload,
      };
    case OrderActionType.EXPORT_ORDER:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case OrderActionType.EXPORT_ORDER_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case OrderActionType.EXPORT_ORDER_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case OrderActionType.RESET_EXPORT:
      return {
        ...state,
        exportSuccess: false,
      };

    // activity
    case ActivityActionType.GET_ACTIVITIES:
      return {
        ...state,
        activityLoader: true,
      };
    case ActivityActionType.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activity: action.payload,
        activityLoader: false,
      };
    case ActivityActionType.GET_ACTIVITIESS_ERROR:
      return {
        ...state,
        activityLoader: false,
        activity: {},
        error: action.payload,
      };
    case OrderActionType.EXPORT_CSV:
      return {
        ...state,
        CSVLoader: true,
        CSVFailure: {},
      };
    case OrderActionType.EXPORT_CSV_SUCCESS:
      return {
        ...state,
        CSVLoader: false,
        CSVSuccess: true,
        CSVFailure: {},
      };
    case OrderActionType.EXPORT_CSV_ERROR:
      return {
        ...state,
        CSVLoader: false,
        CSVFailure: action.payload,
      };

    case OrderActionType.RESET_CSV:
      return {
        ...state,
        CSVSuccess: false,
      };

    case OrderActionType.FASTFORWARD_TIME:
      return {
        ...state,
        fastforwarding: true,
      };
    case OrderActionType.FASTFORWARD_TIME_SUCCESS:
      return {
        ...state,
        order: { ...state.order, updated_at: action.payload.updatedTime },
        fastforwarding: false,
      };
    case OrderActionType.GET_INVOICE_DETAILS:
      return {
        ...state,
        invoiceLoader: true,
        invoice: {},
        invoiceError: false,
      };
    case OrderActionType.INVOICE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        invoice: action.payload,
        invoiceLoader: false,
        invoiceError: false,
      };
    case OrderActionType.INVOICE_DOWNLOAD_FAILED:
      return {
        ...state,
        invoiceLoader: false,
        invoiceError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
