import { StoriesActionTypes } from '../../actions/ActionTypes';

interface StoriesState {
  stories: Object;
  selectedStory: Object;
  image: object;
  loader: boolean;
  imgLoader: any;
  isError: boolean;
  newStorySuccess: boolean;
  addImageSuccess: boolean;
  updateStorySuccess: boolean;
  updateImageSuccess: boolean;
  error: Object;
}

const initialState: StoriesState = {
  stories: [],
  selectedStory: [],
  image: {},
  loader: false,
  imgLoader: false,
  isError: false,
  newStorySuccess: false,
  addImageSuccess: false,
  updateStorySuccess: false,
  updateImageSuccess: false,
  error: {},
};

const reducer = (state: StoriesState = initialState, action: any = {}) => {
  switch (action.type) {
    case StoriesActionTypes.START_FETCH_STORIES: {
      return {
        ...state,
        loader: true,
      };
    }
    case StoriesActionTypes.FINISH_FETCH_STORIES: {
      return {
        ...state,
        stories: action.payload,
        loader: false,
      };
    }

    case StoriesActionTypes.START_FETCH_STORY_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case StoriesActionTypes.FINISH_FETCH_STORY_DETAILS: {
      return {
        ...state,
        selectedStory: action.payload,
        loader: false,
      };
    }

    case StoriesActionTypes.START_FETCH_STORY_IMAGE: {
      return {
        ...state,
        imgLoader: true,
      };
    }
    case StoriesActionTypes.FINISH_FETCH_STORY_IMAGE: {
      return {
        ...state,
        image: action.payload,
        imgLoader: false,
      };
    }

    case StoriesActionTypes.START_CREATE_STORY: {
      return {
        ...state,
      };
    }
    case StoriesActionTypes.FINISH_CREATE_STORY: {
      return {
        ...state,
        newStorySuccess: true,
      };
    }

    case StoriesActionTypes.START_UPDATE_STORY: {
      return {
        ...state,
      };
    }
    case StoriesActionTypes.FINISH_UPDATE_STORY: {
      return {
        ...state,
        updateStorySuccess: true,
      };
    }

    case StoriesActionTypes.START_ADD_IMAGE: {
      return {
        ...state,
      };
    }
    case StoriesActionTypes.FINISH_ADD_IMAGE: {
      return {
        ...state,
        addImageSuccess: true,
      };
    }

    case StoriesActionTypes.START_UPDATE_IMAGE: {
      return {
        ...state,
      };
    }
    case StoriesActionTypes.FINISH_UPDATE_IMAGE: {
      return {
        ...state,
        updateImageSuccess: true,
      };
    }

    case StoriesActionTypes.LOAD_FAILED: {
      return {
        ...state,
        loader: false,
        imgLoader: false,
        isError: true,
        error: action.payload,
        newStorySuccess: false,
        addImageSuccess: false,
        updateStorySuccess: false,
        updateImageSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
