import { TaskApprovalActionType } from '../../actions/ActionTypes';

interface TaskApprovalState {
  tasks: any;
  taskLoader: boolean;
}

const initialState: TaskApprovalState = {
  tasks: {},
  taskLoader: false,
};

const reducer = (state: TaskApprovalState = initialState, action: any = {}) => {
  switch (action.type) {
    // Get task approval grid
    case TaskApprovalActionType.GET_TASK_APPROVALS: {
      return {
        ...state,
        taskLoader: true,
      };
    }
    case TaskApprovalActionType.GET_TASK_APPROVALS_SUCCESS: {
      return {
        ...state,
        tasks: action.payload,
        taskLoader: false,
      };
    }

    // Update task status
    case TaskApprovalActionType.UPDATE_STATUS: {
      return {
        ...state,
        taskLoader: true,
      };
    }
    case TaskApprovalActionType.UPDATE_STATUS_SUCCESS: {
      const index = state.tasks.results.findIndex(
        (storePrice: any) => storePrice.id === action.payload.id
      );
      const results = [...state.tasks.results];
      results[index].status = action.payload.status;
      results[index].isNew = true;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          results,
        },
        taskLoader: false,
      };
    }

    case TaskApprovalActionType.LOAD_FAILED: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: action.payload,
        },
        taskLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
