import SEARCH_API from '../../../api/searchAPI';
import TAG_API from '../../../api/tagAPI';
import { TagActionType } from '../ActionTypes';

const getTags = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.GET_TAGS,
    });
    const res = await TAG_API.getTags(params);
    dispatch({
      type: TagActionType.GET_TAGS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getAssignedTagDetails =
  (type: any, typeId: any, params: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: TagActionType.GET_ASSIGNED_TAG_VALUES,
      });
      const res = await TAG_API.getAssignedTagTypeDetails(type, typeId, params);
      dispatch({
        type: TagActionType.GET_ASSIGNED_TAG_VALUES_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TagActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getTagById = (tagId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.GET_TAG,
    });
    const res = await TAG_API.getTagDetails(tagId);
    dispatch({
      type: TagActionType.GET_TAG_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const createTag = (data: object) => async (dispatch: any) => {
  dispatch({
    type: TagActionType.CREATE_TAG,
  });
  return TAG_API.postCreateTag(data).then(response => {
    dispatch({
      type: TagActionType.CREATE_TAG_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

const updateTag = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.UPDATE_TAG,
    });
    await TAG_API.postUpdateTag(data);
    dispatch({
      type: TagActionType.UPDATE_TAG_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const assignValue = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.ASSIGN_TAG,
    });
    const res = await TAG_API.assignTag(data);
    dispatch({
      type: TagActionType.ASSIGN_TAG_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.ASSIGN_TAG_FAIL,
      payload: e,
    });
  }
};

const unassignValue = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.UNASSIGN_TAG,
    });
    const res = await TAG_API.unassignTag(data);
    dispatch({
      type: TagActionType.UNASSIGN_TAG_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.UNASSIGN_TAG_FAIL,
      payload: e,
    });
  }
};

const searchFields = (type: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.SEARCH_TAG_FIELDS,
    });
    const res = await SEARCH_API.searchGeneric('dynamic-tag-fields', { type });
    dispatch({
      type: TagActionType.SEARCH_TAG_FIELDS_SUCCESS,
      payload: res,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.SEARCH_TAG_FIELDS_FAILURE,
      payload: e,
    });
  }
};

const searchDynamicItems = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.SEARCH_RELATED_ITEMS,
    });
    let url = '';
    if (data.filters) {
      url = `type=${data.type}&id=${data.id}&filters=${data.filters}`;
    } else {
      url = `type=${data.type}&id=${data.id}`;
    }
    const res = await TAG_API.searchDynamicItems(url);

    dispatch({
      type: TagActionType.SEARCH_RELATED_ITEMS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.SEARCH_RELATED_ITEMS_FAILURE,
      payload: e,
    });
  }
};

const assignItems = (data: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: TagActionType.ASSIGN_ITEM,
    });
    const res = await TAG_API.assignItems(data);
    dispatch({
      type: TagActionType.ASSIGN_ITEM_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: TagActionType.ASSIGN_ITEM_FAILURE,
      payload: e,
    });
  }
};

export {
  getTags,
  getAssignedTagDetails,
  getTagById,
  createTag,
  updateTag,
  assignValue,
  unassignValue,
  searchFields,
  searchDynamicItems,
  assignItems,
};
