import React, { useEffect } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Card, Descriptions } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  getDisputeDetails,
  getRefundTransactionDetails,
} from '../../redux/actions/payment/paymentAction';
import { formatDate } from '../../util';

const DisputeDetails: any = () => {
  const { disputeId } = useParams();
  const { dispute } = useAppSelector(state => state.transactions);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDisputeDetails(`${disputeId}`));
  }, []);
  return (
    <Card title="Dispute Details" bodyStyle={{ padding: 0 }}>
      <Helmet>
        <title>{`Disputes: ${disputeId}`}</title>
      </Helmet>
      <Descriptions
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Id">{dispute.id}</Descriptions.Item>
        <Descriptions.Item label="Amount in rupees">
          {parseFloat(dispute.amount)}
        </Descriptions.Item>
        <Descriptions.Item label="Deducted Amount">
          {parseFloat(dispute.amount_deducted)}
        </Descriptions.Item>
        <Descriptions.Item label="Payment Id">
          {dispute.payment_id}
        </Descriptions.Item>
        <Descriptions.Item label="Dispute Id">
          {dispute.dispute_id}
        </Descriptions.Item>
        <Descriptions.Item label="Entity">{dispute.entity}</Descriptions.Item>

        <Descriptions.Item label="Status">{dispute.status}</Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDate(dispute.created_at, 'lll')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default DisputeDetails;
