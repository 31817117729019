import { StockValidationActionType } from '../../actions/ActionTypes';

interface StoreProductState {
  storeProductItems: any;
  loader: boolean;
  failure: any;
  defaultStoreId: any;
  defaultProductId: any;
  CSVloader: boolean;
  CSVsuccess: boolean;
  CSVfailure: any;
  secondaryStockValidation: any;
  secStockLoader: boolean;
  CSVProductloader: boolean;
  CSVProductsuccess: boolean;
  CSVProductfailure: any;
  secDefaultStoreId: any;
  secDefaultProductId: any;
}

const initialState: StoreProductState = {
  storeProductItems: {},
  loader: false,
  failure: {},
  defaultStoreId: null,
  defaultProductId: null,
  CSVloader: false,
  CSVsuccess: false,
  CSVfailure: {},
  secondaryStockValidation: {},
  secStockLoader: false,
  CSVProductloader: false,
  CSVProductsuccess: false,
  CSVProductfailure: {},
  secDefaultStoreId: {},
  secDefaultProductId: {},
};

const reducer = (state: StoreProductState = initialState, action: any = {}) => {
  switch (action.type) {
    case StockValidationActionType.GET_PRODUCT_DATA_FOR_STORE:
      return {
        ...state,
        loader: true,
        defaultStoreId: action.payload.store_id,
        defaultProductId: action.payload.product_id,
      };
    case StockValidationActionType.GET_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        storeProductItems: action.payload.data,
        loader: false,
      };
    case StockValidationActionType.GET_PRODUCT_DATA_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false,
      };
    case StockValidationActionType.GET_CSV_DATA_FOR_STORE:
      return {
        ...state,
        CSVloader: true,
        CSVsuccess: false,
      };
    case StockValidationActionType.GET_CSV_DATA_SUCCESS:
      return {
        ...state,
        CSVloader: false,
        CSVsuccess: true,
        CSVfailure: {},
      };
    case StockValidationActionType.GET_CSV_DATA_FAILURE:
      return {
        ...state,
        CSVloader: false,
        CSVsuccess: false,
        CSVfailure: action.payload,
      };
    case StockValidationActionType.RESET_INPUT:
      return {
        ...state,
        defaultStoreId: null,
        defaultProductId: null,
        storeProductItems: {},
      };
    case StockValidationActionType.RESET_CSV_LOADER:
      return {
        ...state,
        CSVloader: false,
        CSVsuccess: false,
        CSVfailure: {},
      };
    case StockValidationActionType.RESET_CSV_PRODUCT_LOADER:
      return {
        ...state,
        CSVProductloader: false,
        CSVProductsuccess: false,
        CSVProductfailure: {},
      };
    case StockValidationActionType.GET_SEC_STOCK_VALIDATION: {
      return {
        ...state,
        secStockLoader: true,
        defaultStoreId: action.payload.store_id,
        defaultProductId: action.payload.product_id,
      };
    }
    case StockValidationActionType.GET_SEC_STOCK_VALIDATION_SUCCESS: {
      return {
        ...state,
        secondaryStockValidation: action.payload.data,
        secStockLoader: false,
      };
    }
    case StockValidationActionType.GET_SEC_STOCK_VALIDATION_FAILURE:
      return {
        ...state,
        secStockLoader: false,
        failure: action.payload,
      };

    case StockValidationActionType.SEC_RESET_INPUT:
      return {
        ...state,
        secDefaultStoreId: null,
        secDefaultProductId: null,
        secondaryStockValidation: {},
      };
    case StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT:
      return {
        ...state,
        CSVProductloader: true,
        CSVProductsuccess: false,
      };
    case StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        CSVProductloader: false,
        CSVProductsuccess: true,
        CSVProductfailure: {},
      };
    case StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT_FAILURE:
      return {
        ...state,
        CSVProductloader: false,
        CSVProductsuccess: false,
        CSVProductfailure: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
