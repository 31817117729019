import axios from './axios';
import config from './config';

const RULE_API = {
  getRules: (params: any) =>
    axios({
      url: `${config.endpoint.rule}/rules`,
      method: 'GET',
      params,
    }),
  getRule: (ruleId: string) =>
    axios({
      url: `${config.endpoint.rule}/${ruleId}`,
      method: 'GET',
    }),
  getCoupons: (params: any) =>
    axios({
      url: `${config.endpoint.rule}/coupons`,
      method: 'GET',
      params,
    }),
  getRuleCoupon: (ruleId: any, params: any) =>
    axios({
      url: `${config.endpoint.rule}/${ruleId}/coupons`,
      method: 'GET',
      params,
    }),

  postUpdateCoupon: (data: any) =>
    axios({
      url: `${config.endpoint.rule}/coupons/update`,
      method: 'POST',
      data,
    }),
  createRule: (data: any) =>
    axios({
      url: `${config.endpoint.rule}/create`,
      method: 'POST',
      data,
    }),
  updateRule: (data: any) =>
    axios({
      url: `${config.endpoint.rule}/update`,
      method: 'POST',
      data,
    }),
  exportRules: (params: any) =>
    axios({
      url: `${config.endpoint.rule}/rules-csv`,
      method: 'GET',
      params,
    }),
  exportCoupons: (params: any) =>
    axios({
      url: `${config.endpoint.rule}/coupons-csv`,
      method: 'GET',
      params,
    }),
  exportCoupon: (params: any) =>
    axios({
      url: `${config.endpoint.rule}/${params.id}/coupons-csv`,
      method: 'GET',
      params,
    }),
};

export default RULE_API;
