/* eslint-disable default-param-last */
import { PosActionType } from '../../actions/ActionTypes';

interface PosReducer {
  posLoader: boolean;
  isError: boolean;
  submitPosLoader: boolean;
  createdsuccess: boolean;
  updatedsuccess: boolean;
  error: object;
  posroles: any;
  allPosRoles: object;
  roleLoader: boolean;
  role: object;
  permissionsList: object;
}

const initialState: PosReducer = {
  posLoader: false,
  createdsuccess: false,
  updatedsuccess: false,
  submitPosLoader: false,
  isError: false,
  error: {},
  posroles: {},
  allPosRoles: {},
  roleLoader: false,
  role: {},
  permissionsList: {},
};

const reducer = (state: PosReducer = initialState, action: any) => {
  switch (action.type) {
    case PosActionType.GET_ALL_POS_USER:
      return {
        ...state,
        posLoader: true,
      };
    case PosActionType.GET_ALL_POS_ROLES_SUCCESS:
      return {
        ...state,
        posroles: action.payload,
        posLoader: false,
      };
    case PosActionType.GET_ALL_POS_ROLES_FAILURE:
      return {
        ...state,
        isError: true,
        posLoader: false,
        error: action.payload,
      };
    case PosActionType.CREATE_POS_ROLE: {
      return {
        ...state,
        submitPosLoader: true,
      };
    }
    case PosActionType.CREATE_POS_ROLE_SUCCESS: {
      action.payload.isNew = true;
      return {
        ...state,
        posroles: {
          ...state.posroles,
          results: [action.payload, ...state.posroles.results],
          count: state.posroles.count + 1,
        },
        createdsuccess: true,
        submitPosLoader: false,
      };
    }
    case PosActionType.CREATE_POS_ROLE_FAILURE: {
      return {
        ...state,
        success: false,
        submitPosLoader: false,
        isError: true,
        error: action.payload,
      };
    }
    case PosActionType.GET_POS_ROLES_SUCCESS: {
      return {
        ...state,
        allPosRoles: action.payload.data,
      };
    }
    case PosActionType.GET_POS_ROLES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case PosActionType.GET_POS_PERMISSIONS: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case PosActionType.GET_POS_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissionsList: action.payload,
        roleLoader: false,
      };
    }

    // Getting role by id
    case PosActionType.GET_POS_ROLE: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case PosActionType.GET_POS_ROLE_SUCCESS: {
      return {
        ...state,
        role: action.payload,
        roleLoader: false,
      };
    }
    case PosActionType.UPDATE_POS_ROLE: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case PosActionType.UPDATE_POS_ROLE_SUCCESS: {
      return {
        ...state,
        roleLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
