import { CancellationReasonsType } from '../../actions/ActionTypes';

interface cancellationReasonsState {
  reasons: any;
  reasonsLoader: boolean;
  isError: boolean;
  postLoader: boolean;
}
const initialState: cancellationReasonsState = {
  reasons: {},
  reasonsLoader: false,
  isError: false,
  postLoader: false,
};

const reducer = (
  state: cancellationReasonsState = initialState,
  action: any = {}
) => {
  switch (action.type) {
    case CancellationReasonsType.GET_CANCELLATION_REASONS: {
      return {
        ...state,
        reasonsLoader: true,
      };
    }
    case CancellationReasonsType.GET_CANCELLATION_REASONS_SUCCESS: {
      return {
        ...state,
        reasonsLoader: false,
        reasons: action.payload,
      };
    }
    case CancellationReasonsType.GET_CANCELLATION_REASONS_FAILURE: {
      return {
        ...state,
        reasonsLoader: true,
        isError: true,
      };
    }
    case CancellationReasonsType.POST_CANCELLATION_REASON: {
      return {
        ...state,
        postLoader: true,
      };
    }
    case CancellationReasonsType.POST_CANCELLATION_REASON_SUCCESS: {
      return {
        ...state,
        postLoader: false,
      };
    }
    case CancellationReasonsType.UPDATE_CANCELLATION_REASON: {
      return {
        ...state,
        postLoader: true,
      };
    }
    case CancellationReasonsType.UPDATE_CANCELLATION_REASON_SUCCESS: {
      return {
        ...state,
        postLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
