import React from 'react';
import {
  BugOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import {
  PageHeader,
  Layout,
  Row,
  Col,
  Button,
  Modal,
  Affix,
  Space,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../components';
import { useAppDispatch } from '../redux';
import { appAction } from '../redux/actions';

interface HeaderProps {
  collapsed?: boolean;
  toggle?: () => void;
}

const { Header } = Layout;

const headerStyle = {
  background: '#fff',
  padding: 0,
};
const AppHeader: React.FC<HeaderProps> = ({
  collapsed,
  toggle,
}: HeaderProps) => {
  const { confirm } = Modal;
  const dispatch = useAppDispatch();

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          resolve(dispatch(appAction.logoutUser()));
          reject();
        })
          .then(() => {
            successNotification({
              message: 'Success!!',
              description: 'Logged out successfully!',
              placement: 'bottomLeft',
            });
          })
          .catch(err => {
            errorNotification({
              message: 'Error!',
              description: `${err.message}`,
              placement: 'bottomLeft',
            });
          });
      },
    });
  };

  return (
    <Affix>
      <Header style={headerStyle}>
        <Row>
          <Col span={16}>
            <PageHeader
              title={
                <div>
                  {React.createElement(
                    collapsed ? MenuOutlined : MenuFoldOutlined,
                    {
                      className: 'trigger',
                      onClick: toggle,
                    }
                  )}{' '}
                  1K Kirana Bazar
                </div>
              }
            />
          </Col>
          <Col
            span={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: '1rem',
            }}
          >
            <Space size="large">
              {process.env.REACT_APP_NODE_ENV === 'production' && (
                <Tooltip title="Feedback & Requirements">
                  <Button
                    type="link"
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1j5RyiibbRIn6ZpyJsZDNE18Pevg2Axf35B9z_JSzhRc/edit?usp=sharing"
                    icon={<BugOutlined spin />}
                  />
                </Tooltip>
              )}
              <Button
                shape="circle"
                icon={<LogoutOutlined />}
                htmlType="submit"
                className="login-form-button"
                danger
                onClick={showConfirm}
              />
            </Space>
          </Col>
        </Row>
      </Header>
    </Affix>
  );
};

export default AppHeader;
