import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PAYMENTAPI from '../../api/paymentAPI';
import {
  AsyncSelect,
  errorNotification,
  successNotification,
} from '../../components';

const GenerateQR: any = (props: { setVisible: any; storeId: string }) => {
  const { setVisible, storeId } = props;
  const [isFixedAmount, setFixedAmount] = useState(true);
  const [createLoader, setCreateLoader] = useState(false);
  const [form] = Form.useForm();
  const [qrUsage, setQRUsage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      store_id: storeId,
      store_type: 'pos_store_id',
      driver: 1,
    });
  }, []);

  const formSubmit = (value: any) => {
    const additionalInfo: any = {};
    const extraInfo = form.getFieldValue('additional_info');
    if (extraInfo) {
      extraInfo.forEach((obj: any) => {
        additionalInfo[obj.Title] = obj.Description;
      });
    }
    const payload = {
      ...value,
      is_fixed_amount: qrUsage !== 'multiple_use',
      source_id: form.getFieldValue('source_id') || '',
      additional_info: additionalInfo,
    };

    setCreateLoader(true);

    PAYMENTAPI.createQrCode(payload)
      .then(resp => {
        successNotification({
          message: 'Success!!',
          description: 'Form submitted successfully!',
          placement: 'bottomLeft',
        });
        form.resetFields();
        setCreateLoader(false);
        setVisible(false);
        navigate(`/qr-codes/${resp.data.data.response.id}`);
      })
      .catch((err: any) => {});
  };

  return (
    <Drawer
      visible
      width={700}
      closable
      onClose={() => {
        setVisible(false);
        form.resetFields();
      }}
      extra={
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
          htmlType="submit"
          loading={createLoader}
        >
          Generate QR
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={formSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="payment_amount"
              label="Amount"
              rules={[
                { required: true, message: 'Please enter amount.' },
                {
                  type: 'number',
                  min: qrUsage === 'multiple_use' ? 0 : 1,
                  max: 500000,
                },
              ]}
            >
              <InputNumber
                disabled={qrUsage === 'multiple_use'}
                placeholder="Please enter amount."
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="store_id"
              label="Store Id"
              rules={[{ required: true, message: 'Please enter Store Id' }]}
            >
              <Input
                placeholder="Please enter Store Id."
                value={storeId}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="source_id"
              label="Source id"
              rules={[{ message: 'Please enter source id.' }]}
            >
              <Input placeholder="Please enter source id." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="store_type"
              label="Store type"
              rules={[{ required: true, message: 'Please enter Store type' }]}
            >
              <Select disabled>
                <Select.Option value="nt_store_id">nt_store_id</Select.Option>
                <Select.Option value="pos_store_id">pos_store_id</Select.Option>
                <Select.Option value="bd_store_id">bd_store_id</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="driver"
              label="Driver"
              rules={[{ required: true, message: 'Please enter Driver id.' }]}
            >
              <Select>
                <Select.Option value={1}>Razorpay ICICI(Primary)</Select.Option>
                <Select.Option value={2}>Razorpay(Secondary)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="usage"
              label="Usage"
              rules={[{ required: true, message: 'Please enter qr usage.' }]}
            >
              <Select
                onChange={value => {
                  setQRUsage(value);
                  if (value === 'multiple_use') {
                    form.setFieldsValue({
                      payment_amount: 0,
                    });
                    setFixedAmount(false);
                  }
                }}
              >
                <Select.Option value="single_use">Single Use</Select.Option>
                <Select.Option value="multiple_use">Multiple Use</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="type"
              label="QR type"
              rules={[{ required: true, message: 'Please enter qr type.' }]}
            >
              <Select>
                <Select.Option value="upi_qr">Upi QR</Select.Option>
                <Select.Option value="bharat_qr">Bharat QR</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="additional_info">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} style={{ display: 'flex' }} gutter={16}>
                  <Col span={10}>
                    <Form.Item
                      {...restField}
                      name={[name, 'Title']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing title',
                        },
                        {
                          pattern: /^[a-zA-Z_]*$/,
                          message:
                            'Title should not contain any spaces or special characters.',
                        },
                      ]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      {...restField}
                      name={[name, 'Description']}
                      rules={[
                        { required: true, message: 'Missing Description' },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>
                  <Col
                    span={4}
                    style={{ marginTop: '5px', fontSize: '1rem', color: 'red' }}
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  disabled={
                    form.getFieldValue('additional_info') &&
                    form.getFieldValue('additional_info').length > 4
                  }
                  icon={<PlusOutlined />}
                >
                  Additional Info
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

export default GenerateQR;
