/* eslint-disable default-param-last */
import { StoreActionType } from '../../actions/ActionTypes';

interface StoreReducer {
  store: Object;
  loading: boolean;
  error: Object;
  isError: Object;
  attributes: Object;
  storeSuccess: boolean;
  storeError: Object;
  attributesSuccess: boolean;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
  inventory: object;
  storeLoader: boolean;
  stockLedgers: Object;
  stockLedgersLoader: boolean;
  shipment: object;
  verifysuccess: boolean;
  shipmentLoader: boolean;
  Return: object;
  returnLoader: boolean;
  isInventoryUpdated: any;
  posPaymentLoader: boolean;
  posPayment: any;
  ledgerLoader: boolean;
  ledger: any;
  creatingStore: boolean;
  saleSummary: Object;
  summaryLoader: boolean;
  incomeSummary: Object;
  incomeLoader: boolean;
  paymentLoader: boolean;
  paymentInfo: Object;
}

const initialState: StoreReducer = {
  store: {},
  loading: false,
  error: false,
  isError: {},
  attributes: {},
  storeSuccess: false,
  storeError: {},
  attributesSuccess: false,
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
  inventory: {},
  shipment: {},
  storeLoader: false,
  stockLedgers: {},
  stockLedgersLoader: false,
  verifysuccess: false,
  shipmentLoader: false,
  Return: {},
  returnLoader: false,
  isInventoryUpdated: null,
  posPaymentLoader: false,
  posPayment: {},
  ledgerLoader: false,
  ledger: {},
  creatingStore: false,
  saleSummary: {},
  summaryLoader: false,
  incomeSummary: {},
  incomeLoader: false,
  paymentLoader: false,
  paymentInfo: {},
};

const reducer = (state: StoreReducer = initialState, action: any) => {
  switch (action.type) {
    case StoreActionType.GET_STORES:
      return {
        ...state,
        loading: true,
      };
    case StoreActionType.GET_STORES_SUCCESS:
      return {
        ...state,
        store: action.payload,
        loading: false,
      };
    case StoreActionType.GET_STORES_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        isError: action.payload,
      };
    case StoreActionType.GET_STORE: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case StoreActionType.GET_STORE_SUCCESS: {
      return {
        ...state,
        store: action.payload.data,
        loading: false,
      };
    }
    case StoreActionType.GET_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case StoreActionType.GET_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        attributes: action.payload.data,
      };
    }
    case StoreActionType.CREATE_STORE: {
      return {
        ...state,
        storeSuccess: false,
        creatingStore: true,
        storeError: {},
      };
    }
    case StoreActionType.CREATE_STORE_SUCCESS: {
      return {
        ...state,
        storeSuccess: true,
        creatingStore: false,
        storeError: {},
      };
    }
    case StoreActionType.CREATE_STORE_FAILURE: {
      return {
        ...state,
        storeError: action.payload,
        storeSuccess: false,
        creatingStore: false,
      };
    }
    case StoreActionType.UPDATE_STORE_SUCCESS: {
      return {
        ...state,
        store: action.payload.data,
        storeSuccess: true,
      };
    }
    case StoreActionType.UPDATE_STORE_FAILURE: {
      return {
        ...state,
        storeError: action.payload,
      };
    }
    case StoreActionType.UPDATE_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        attributesSuccess: true,
      };
    }
    case StoreActionType.UPDATE_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        storeError: action.payload,
      };
    }
    case StoreActionType.RESET_STORE: {
      return {
        ...state,
        storeError: {},
        storeSuccess: false,
        attributesSuccess: false,
        exportSuccess: false,
      };
    }
    case StoreActionType.EXPORT_ORDER:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case StoreActionType.EXPORT_ORDER_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case StoreActionType.EXPORT_ORDER_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case StoreActionType.GET_STORE_INVENTORY: {
      return {
        ...state,
        storeLoader: true,
      };
    }

    case StoreActionType.GET_STORE_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: action.payload,
        storeLoader: false,
      };
    }

    case StoreActionType.GET_STORE_RETURN: {
      return {
        ...state,
        storeLoader: true,
      };
    }

    case StoreActionType.GET_STORE_RETURN_SUCCESS: {
      return {
        ...state,
        Return: action.payload,
        storeLoader: false,
      };
    }
    case StoreActionType.GET_STORE_INVENTORY_SHIPMENT: {
      return {
        ...state,
        storeLoader: true,
      };
    }

    case StoreActionType.GET_STORE_INVENTORY_SHIPMENT_SUCCESS: {
      return {
        ...state,
        shipment: action.payload,
        storeLoader: false,
      };
    }
    case StoreActionType.VERIFY_INVENTORY_SHIPMENT: {
      return {
        ...state,
        shipmentLoader: true,
        isInventoryUpdated: null,
      };
    }
    case StoreActionType.VERIFY_INVENTORY_SHIPMENT_SUCCESS: {
      return {
        ...state,
        isInventoryUpdated: true,
        verifysuccess: true,
        shipmentLoader: false,
      };
    }
    case StoreActionType.VERIFY_INVENTORY_SHIPMENT_SUCCESS_FAIL: {
      return {
        ...state,
        isInventoryUpdated: false,
        verifysuccess: true,
        shipmentLoader: false,
      };
    }
    case StoreActionType.VERIFY_INVENTORY_SHIPMENT_FAILED: {
      return {
        ...state,
        verifysuccess: false,
        shipmentLoader: false,
        isInventoryUpdated: null,
        error: action.payload,
      };
    }

    case StoreActionType.VERIFY_RETURN: {
      return {
        ...state,
        returnLoader: true,
        isInventoryUpdated: null,
      };
    }
    case StoreActionType.VERIFY_RETURN_SUCCESS: {
      return {
        ...state,
        isInventoryUpdated: true,
        verifysuccess: true,
        returnLoader: false,
      };
    }
    case StoreActionType.VERIFY_RETURN_SUCCESS_FAIL: {
      return {
        ...state,
        isInventoryUpdated: false,
        verifysuccess: true,
        returnLoader: false,
      };
    }
    case StoreActionType.VERIFY_RETURN_FAILED: {
      return {
        ...state,
        verifysuccess: false,
        returnLoader: false,
        isInventoryUpdated: null,
        error: action.payload,
      };
    }

    case StoreActionType.RESET_SHIPMENT: {
      return {
        ...state,
        verifysuccess: false,
        error: {},
        isInventoryUpdated: null,
      };
    }
    case StoreActionType.RESET_RETURN: {
      return {
        ...state,
        verifysuccess: false,
        error: {},
        isInventoryUpdated: null,
      };
    }
    case StoreActionType.GET_STOCK_LEDGER: {
      return {
        ...state,
        stockLedgersLoader: true,
      };
    }

    case StoreActionType.GET_STOCK_LEDGER_SUCCESS: {
      return {
        ...state,
        stockLedgersLoader: false,
        stockLedgers: action.payload,
      };
    }

    case StoreActionType.GET_STOCK_LEDGER_ERROR: {
      return {
        ...state,
        stockLedgersLoader: false,
      };
    }

    case StoreActionType.CLEAR_STOCK_LEDGER: {
      return {
        ...state,
        stockLedgersLoader: false,
        stockLedgers: {},
      };
    }

    case StoreActionType.LOAD_FAILED: {
      return {
        ...state,
        store: {
          ...state.store,
          error: action.payload,
        },
        storeLoader: false,
      };
    }
    case StoreActionType.EXPORT_CSV:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case StoreActionType.EXPORT_CSV_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case StoreActionType.EXPORT_CSV_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case StoreActionType.RESET_EXPORT:
      return {
        ...state,
        exportSuccess: false,
        exportFailure: {},
      };

    case StoreActionType.GET_POS_PAYMENT_LOGS: {
      return {
        ...state,
        posPaymentLoader: true,
      };
    }

    case StoreActionType.GET_POS_PAYMENT_LOGS_SUCCESS: {
      return {
        ...state,
        posPayment: action.payload,
        posPaymentLoader: false,
      };
    }

    case StoreActionType.GET_POS_PAYMENT_LOGS_ERROR: {
      return {
        ...state,
        posPaymentLoader: false,
      };
    }
    case StoreActionType.GET_STORE_LEDGER:
      return {
        ...state,
        ledgerLoader: true,
      };
    case StoreActionType.GET_STORE_LEDGER_SUCCESS:
      return {
        ...state,
        ledgerLoader: false,

        ledger: action.payload,
      };
    case StoreActionType.GET_STORE_LEDGER_ERROR:
      return {
        ...state,
        ledgerLoader: false,
      };

    case StoreActionType.GET_SALE_SUMMARY:
      return {
        ...state,
        summaryLoader: true,
      };
    case StoreActionType.GET_SALE_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryLoader: false,

        saleSummary: action.payload,
      };
    case StoreActionType.GET_SALE_SUMMARY_ERROR:
      return {
        ...state,
        summaryLoader: false,
      };

    case StoreActionType.GET_INCOME_SUMMARY:
      return {
        ...state,
        incomeLoader: true,
      };
    case StoreActionType.GET_INCOME_SUMMARY_SUCCESS:
      return {
        ...state,
        incomeLoader: false,

        incomeSummary: action.payload,
      };
    case StoreActionType.GET_INCOME_SUMMARY_ERROR:
      return {
        ...state,
        incomeLoader: false,
      };

    case StoreActionType.GET_PAYMENT_INFO:
      return {
        ...state,
        paymentLoader: true,
      };
    case StoreActionType.GET_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        paymentLoader: false,

        paymentInfo: action.payload,
      };
    case StoreActionType.GET_PAYMENT_INFO_ERROR:
      return {
        ...state,
        paymentLoader: false,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
