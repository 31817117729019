import DASHBOARD_API from '../../../api/dashboardAPI';
import { DashboardActionType } from '../ActionTypes';

const getDashboardOrderStats = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_ORDER_STATS,
    });
    const res: any = await DASHBOARD_API.getDashboardStats(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_ORDER_STATS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getDashboardStoreStats = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_STORE_STATS,
    });
    const res: any = await DASHBOARD_API.getDashboardStats(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_STORE_STATS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getDashboardCustomerStats = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_CUSTOMER_STATS,
    });
    const res: any = await DASHBOARD_API.getDashboardStats(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_CUSTOMER_STATS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getDashboardOrderGraph = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_ORDER_GRAPH,
    });
    const res: any = await DASHBOARD_API.getDashboardGraph(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_ORDER_GRAPH,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getStoreGraphData = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_STORE_GRAPH_DATA,
    });
    const res: any = await DASHBOARD_API.getDashboardGraph(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_STORE_GRAPH_DATA,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getCustomersGraphData = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_CUSTOMERS_GRAPH_DATA,
    });
    const res: any = await DASHBOARD_API.getDashboardGraph(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_CUSTOMERS_GRAPH_DATA,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getOrderStatusGraphData = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_ORDER_STATUS_GRAPH_DATA,
    });
    const res: any = await DASHBOARD_API.getDashboardGraph(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_ORDER_STATUS_GRAPH_DATA,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getAverageOrderGraph = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DashboardActionType.START_FETCH_AVERAGE_ORDER_GRAPH,
    });
    const res: any = await DASHBOARD_API.getAverageOrderGraph(params);
    dispatch({
      type: DashboardActionType.FINISH_FETCH_AVERAGE_ORDER_GRAPH,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: DashboardActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

export default {
  getDashboardOrderStats,
  getDashboardCustomerStats,
  getDashboardStoreStats,
  getStoreGraphData,
  getCustomersGraphData,
  getDashboardOrderGraph,
  getOrderStatusGraphData,
  getAverageOrderGraph,
};
