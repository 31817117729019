/* eslint-disable no-shadow */
import { UserAddOutlined } from '@ant-design/icons';
import { Col, Row, Typography, Button, Form } from 'antd';
import get from 'lodash.get';
import { useEffect, useState } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import {
  Cardtable,
  successNotification,
  errorNotification,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux';
import { p2rDiscountAction, storeAction } from '../../redux/actions';
import DrawerP2RDiscount from './P2RDiscountForm';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    showSearch: true,
    type: 'input_id',
    width: 100,
    sorter: (a: any, b: any) => a.id - b.id,
    render: (text: string, row: any) => (
      <Link to={`/payment-display-cards/exceptions/id/${row.id}`}>{text}</Link>
    ),
  },
  {
    title: 'Display Card Id',
    dataIndex: 'display_card_id',
    type: 'input',
    width: 150,
  },
  {
    title: 'Discount Percentage',
    dataIndex: 'discount_percentage',
    type: 'input',
    width: 150,
  },
  {
    title: 'Discount By Store Percentage',
    dataIndex: 'discount_by_store_percentage',
    type: 'input',
    width: 200,
  },
  {
    title: 'Is Disabled',
    dataIndex: 'is_disabled',
    type: 'input',
    width: 100,
  },

  {
    title: 'Store Name',
    dataIndex: 'store.name',
    showSearch: true,
    type: 'input',
    width: 180,
    render: (text: any, row: any) => get(row, 'store.name', ''),
  },
  {
    title: 'Store Id',
    dataIndex: 'store.id',
    showSearch: true,
    type: 'input',
    width: 100,
    render: (text: any, row: any) => get(row, 'store.id', ''),
  },
];

const P2RDiscountID = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { p2rdiscountId } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        dispatch(storeAction.getStores({}));
      } catch (error) {
        // console.error('Error fetching store data:', error);
      }
    };
    fetchStoreData();
  }, [dispatch]);

  const { p2rloader, getP2RDiscountId } = useAppSelector(
    state => state.p2rdiscount
  );
  const IS_DISABLE = 0;
  const handleEdit = () => {
    setVisible(true);
    const displayCardId = p2rdiscountId;

    form.setFieldsValue({
      display_card_id: displayCardId ?? undefined,
      is_disabled: IS_DISABLE,
    });
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const getData = (params: object) => {
    dispatch(p2rDiscountAction.getp2rdiscountById(`${p2rdiscountId}`, params));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(p2rDiscountAction.getp2rdiscountById(p2rdiscountId, {}));
      } catch (error) {
        // console.error('Error fetching P2RDiscount data:', error);
      }
    };

    fetchData();
  }, [dispatch, p2rdiscountId]);
  const onFinish = (value: any) => {
    // eslint-disable-next-line no-param-reassign
    value.id = getP2RDiscountId.id;
    setLoading(true);

    value.discount_percentage = parseFloat(value.discount_percentage);
    value.discount_by_store_percentage = parseFloat(
      value.discount_by_store_percentage
    );
    value.display_card_id = parseInt(value.display_card_id, 10);
    value.is_disabled = value.is_disabled === '1' || value.is_disabled === 1;
    const storeId = value.store_id ? parseInt(value.store_id.key, 10) : null;
    value.store_id = storeId;

    new Promise((resolve, reject) => {
      resolve(dispatch(p2rDiscountAction.createP2RDiscountid(value)));
      reject();
    })
      .then(() => {
        getData({});
        setVisible(false);
        setLoading(false);
        successNotification({
          message: 'Success!!',
          description: 'Added successfully!',
          placement: 'bottomLeft',
        });
        form.resetFields();
      })
      .catch(err => {
        setLoading(false);
        setVisible(true);
        errorNotification({
          message: 'Error!',
          description: `${err.message}`,
          placement: 'bottomLeft',
        });
      });
  };

  const heading = () => (
    <Row justify="space-between">
      <Helmet>
        <title>{`p2rdiscountId: ${p2rdiscountId}`}</title>
      </Helmet>

      <Col>
        <Typography.Text strong>p2rDiscount</Typography.Text>{' '}
      </Col>

      <Row justify="space-between">
        <Col>
          <Button icon={<UserAddOutlined />} onClick={handleEdit}>
            Create New Exception
          </Button>
        </Col>
      </Row>
    </Row>
  );

  return (
    <>
      <DrawerP2RDiscount
        onClose={onClose}
        visible={visible}
        form={form}
        onFinish={onFinish}
        loading={loading}
        isEdit={isEdit}
        getData={getData}
      />
      <Cardtable
        total={get(getP2RDiscountId, 'count', 0)}
        getData={getData}
        // @ts-ignore
        title={heading}
        loading={p2rloader}
        rowKey={(row: any) => row.name}
        dataSource={get(getP2RDiscountId, 'results', [])}
        assignedKeys={get(getP2RDiscountId, 'assignedKeys', [])}
        columns={columns}
        showPagination
      />
    </>
  );
};

export default P2RDiscountID;
