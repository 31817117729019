import CAMPAIGN_API from '../../../api/campaignAPI';
import { CampaignActionType } from '../ActionTypes';

const getCampaigns = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionType.GET_CAMPAIGNS,
    });
    const res = await CAMPAIGN_API.getCampaigns(params);
    dispatch({
      type: CampaignActionType.GET_CAMPAIGNS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: CampaignActionType.GET_CAMPAIGNS_FAILED,
      payload: e,
    });
  }
};

const getCampaign =
  (campaignID: string | undefined) => async (dispatch: any) => {
    try {
      dispatch({
        type: CampaignActionType.GET_CAMPAIGN,
      });
      const res = await CAMPAIGN_API.getCampaignDetails(campaignID);
      dispatch({
        type: CampaignActionType.GET_CAMPAIGN_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: CampaignActionType.GET_CAMPAIGN_FAILED,
        payload: e,
      });
    }
  };

const createCampaign = (campaignData: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionType.POST_CAMPAIGN,
    });
    const res = await CAMPAIGN_API.postCampaignData(campaignData);
    dispatch({
      type: CampaignActionType.POST_CAMPAIGN_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: CampaignActionType.POST_CAMPAIGN_FAILED,
      payload: e,
    });
  }
};

const updateCampaign =
  (campaignData: object, campaignId: number) => async (dispatch: any) => {
    try {
      dispatch({
        type: CampaignActionType.POST_CAMPAIGN,
      });
      const res = await CAMPAIGN_API.updateCampaignData(
        campaignData,
        campaignId
      );
      dispatch({
        type: CampaignActionType.POST_CAMPAIGN_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: CampaignActionType.POST_CAMPAIGN_FAILED,
        payload: e,
      });
    }
  };

export { getCampaigns, getCampaign, createCampaign, updateCampaign };
