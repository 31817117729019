import { CustomerActionType } from '../../actions/ActionTypes';

interface CustomerState {
  overallStats: Object;
  allCustomers: Object;
  accountInfo: object;
  loyaltyPoints: Object;
  monthlyLoyaltyPoints: Object;
  orders: Object;
  usedCoupons: Object;
  associatedDevices: Object;
  associatedDevicesId: Object;
  associatedBlocklist: Object;
  associatedDevicesIdDetail: Object;
  tags: Object;
  carts: Object;
  stores: Object;
  supportTickets: Object;
  customerAddress: Object;
  loader: boolean;
  exportLoader: boolean;
  exportSuccess: boolean;
  isError: boolean;
  error: Object;
  cartDetail: object;
  cartLoader: boolean;
}

const initialState: CustomerState = {
  overallStats: {},
  allCustomers: {},
  accountInfo: {},
  loyaltyPoints: {},
  monthlyLoyaltyPoints: {},
  orders: {},
  usedCoupons: {},
  associatedDevices: {},
  associatedDevicesId: {},
  associatedDevicesIdDetail: {},
  associatedBlocklist: {},
  tags: {},
  carts: {},
  stores: {},
  supportTickets: {},
  customerAddress: {},
  loader: false,
  exportLoader: false,
  exportSuccess: false,
  isError: false,
  error: {},
  cartDetail: {},
  cartLoader: false,
};

const reducer = (state: CustomerState = initialState, action: any = {}) => {
  switch (action.type) {
    case CustomerActionType.START_FETCH_OVERALL_STATS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_OVERALL_STATS: {
      return {
        ...state,
        overallStats: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ALL_CUSTOMERS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ALL_CUSTOMERS: {
      return {
        ...state,
        allCustomers: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ACCOUNT_INFO: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.payload.data,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ALL_ORDERS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ALL_ORDERS: {
      return {
        ...state,
        orders: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ASSOCIATED_TAGS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ASSOCIATED_TAGS: {
      return {
        ...state,
        tags: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_UPDATE_ACCOUNT_INFO: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_UPDATE_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.payload.data,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_LOYALTY_POINTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_LOYALTY_POINTS: {
      return {
        ...state,
        loyaltyPoints: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_MONTHLY_LOYALTY_POINTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_MONTHLY_LOYALTY_POINTS: {
      return {
        ...state,
        monthlyLoyaltyPoints: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_CUSTOMER_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_CUSTOMER_ADDRESS: {
      return {
        ...state,
        customerAddress: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ASSOCIATED_DEVICES: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES: {
      return {
        ...state,
        associatedDevices: action.payload,
        loader: false,
      };
    }
    case CustomerActionType.START_FETCH_ASSOCIATED_DEVICES_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES_ID: {
      return {
        ...state,
        associatedDevicesId: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ASSOCIATED_DEVICES_ID_DETAIL: {
      return {
        ...state,
        loader: true,
      };
    }

    case CustomerActionType.FINISH_FETCH_ASSOCIATED_DEVICES_ID_DETAIL: {
      return {
        ...state,
        associatedDevicesIdDetail: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.UPDATE_ASSOCIATED_DEVICES_ID: {
      return {
        ...state,
        loader: true,
      };
    }

    case CustomerActionType.UPDATE_ASSOCIATED_DEVICES_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_SUPPORT_TICKETS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_SUPPORT_TICKETS: {
      return {
        ...state,
        supportTickets: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.EXPORT_LOYALTY_POINTS:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case CustomerActionType.EXPORT_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case CustomerActionType.EXPORT_LOYALTY_POINTS_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };

    case CustomerActionType.START_FETCH_ASSOCIATED_STORES: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ASSOCIATED_STORES: {
      return {
        ...state,
        stores: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_ALL_CARTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_ALL_CARTS: {
      return {
        ...state,
        carts: action.payload,
        loader: false,
      };
    }

    case CustomerActionType.START_FETCH_USED_COUPONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CustomerActionType.FINISH_FETCH_USED_COUPONS: {
      return {
        ...state,
        usedCoupons: action.payload,
        loader: false,
      };
    }
    case CustomerActionType.GET_CART_DETAILS: {
      return {
        ...state,
        cartLoader: true,
      };
    }
    case CustomerActionType.GET_CART_DETAILS_SUCCESS: {
      return {
        ...state,
        cartDetail: action.payload,
        cartLoader: false,
      };
    }
    case CustomerActionType.GET_CART_DETAILS_ERROR: {
      return {
        ...state,
        cartLoader: false,
        error: action.payload,
      };
    }

    case 'RESET': {
      return {
        ...state,
        loader: false,
        exportLoader: false,
        exportSuccess: false,
        isError: false,
      };
    }

    case CustomerActionType.LOAD_FAILED: {
      return {
        ...state,
        loader: false,
        isError: true,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
