import { StockValidationActionType } from '../ActionTypes';
import STOCK_VALIDATION_API from '../../../api/stockValidationAPI';

const getRecords = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StockValidationActionType.GET_PRODUCT_DATA_FOR_STORE,
      payload: params,
    });
    const res = await STOCK_VALIDATION_API.getStockRecords(params);
    dispatch({
      type: StockValidationActionType.GET_PRODUCT_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StockValidationActionType.GET_PRODUCT_DATA_FAILURE,
      payload: e,
    });
  }
};

const exportCSV = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_FOR_STORE,
    });
    const res = await STOCK_VALIDATION_API.exportCSV(params);
    window.open(res.data.url);
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_FAILURE,
      payload: e,
    });
  }
};

const getStockProductSalesReport = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: StockValidationActionType.GET_SEC_STOCK_VALIDATION,
      payload: params,
    });
    const res: any = await STOCK_VALIDATION_API.getStockProductSalesReport(
      params
    );
    dispatch({
      type: StockValidationActionType.GET_SEC_STOCK_VALIDATION_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StockValidationActionType.GET_SEC_STOCK_VALIDATION_FAILURE,
      payload: e,
    });
  }
};
const exportProductCSV = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT,
    });
    const res = await STOCK_VALIDATION_API.exportProductCSV(params);
    window.open(res.data.data.url);
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: StockValidationActionType.GET_CSV_DATA_FOR_PRODUCT_FAILURE,
      payload: e,
    });
  }
};

const resetInputs = () => (dispatch: any) => {
  dispatch({
    type: StockValidationActionType.RESET_INPUT,
  });
};
const secResetInputs = () => (dispatch: any) => {
  dispatch({
    type: StockValidationActionType.SEC_RESET_INPUT,
  });
};

const resetExport = () => (dispatch: any) => {
  dispatch({
    type: StockValidationActionType.RESET_CSV_LOADER,
  });
};

const secResetExport = () => (dispatch: any) => {
  dispatch({
    type: StockValidationActionType.RESET_CSV_PRODUCT_LOADER,
  });
};
export default {
  getRecords,
  resetInputs,
  exportCSV,
  resetExport,
  getStockProductSalesReport,
  exportProductCSV,
  secResetInputs,
  secResetExport,
};
