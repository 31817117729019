import { UserActionType } from '../../actions/ActionTypes';

interface UserState {
  userLoader: boolean;
  users: any;
  user: object;
  isError: boolean;
}

const initialState: UserState = {
  userLoader: false,
  users: {},
  user: {},
  isError: false,
};

const reducer = (state: UserState = initialState, action: any = {}) => {
  switch (action.type) {
    // Getting users grid
    case UserActionType.GET_USERS: {
      return {
        ...state,
        userLoader: true,
      };
    }
    case UserActionType.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        userLoader: false,
      };
    }

    // Getting user by id
    case UserActionType.GET_USER: {
      return {
        ...state,
        userLoader: true,
      };
    }
    case UserActionType.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        userLoader: false,
      };
    }

    // Creating a new user
    case UserActionType.CREATE_USER: {
      return {
        ...state,
        userLoader: true,
      };
    }
    case UserActionType.CREATE_USER_SUCCESS: {
      action.payload.isNew = true;
      return {
        ...state,
        users: {
          ...state.users,
          results: [action.payload, ...state.users.results],
          count: state.users.count + 1,
        },
        userLoader: false,
      };
    }

    // Updating a new user
    case UserActionType.UPDATE_USER: {
      return {
        ...state,
        userLoader: true,
      };
    }
    case UserActionType.UPDATE_USER_SUCCESS: {
      const index = state.users.results.findIndex(
        (user: any) => user.id === action.payload.id
      );
      action.payload.isNew = true;
      const results = [...state.users.results];
      results[index] = action.payload;
      results[index].roles = action.payload.roles.map((role: any) => role.name);
      return {
        ...state,
        users: {
          ...state.users,
          results,
        },

        userLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
