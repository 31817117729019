/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactChild, ReactChildren, ReactElement, useState } from 'react';
import { Layout as ThemeLayout } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import AppHeader from './Header';
import AppSidebar from './Sidebar';
import AppLogo from '../assets/images/logo.png';

const { Sider, Content } = ThemeLayout;

interface ChildrenProps {
  children:
    | ReactChild[]
    | ReactChildren
    | ReactChildren[]
    | ReactElement<any, any>;
}
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Layout = ({ children }: ChildrenProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => setCollapsed(!collapsed);

  return (
    <ThemeLayout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={null}
        theme="dark"
        collapsed={collapsed}
        collapsible
        breakpoint="lg"
        collapsedWidth={isMobile ? 0 : 80}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'sticky',
          top: 0,
          left: 0,
        }}
      >
        <LogoWrapper>
          <img
            src={AppLogo}
            style={{ width: collapsed ? 55 : 55 }}
            alt="1K Kirana Bazaar"
          />
        </LogoWrapper>
        <AppSidebar collapsed={collapsed} />
      </Sider>
      <ThemeLayout className="site-layout">
        <AppHeader toggle={toggleSidebar} collapsed={collapsed} />
        <Content style={{ margin: '25px 15px', padding: 0 }}>
          {children}
        </Content>
      </ThemeLayout>
    </ThemeLayout>
  );
};
export default Layout;
