import { Key } from 'react';
import axios from './axios';
import config from './config';

const PRODUCT_API = {
  getProducts: (params: any) =>
    axios({
      url: `${config.endpoint.product}`,
      method: 'GET',
      params,
    }),

  getProductGroups: (params: any) =>
    axios({
      url: `${config.endpoint.product}/groups/all`,
      method: 'GET',
      params,
    }),

  getProductDetails: (productId: string) =>
    axios({
      url: `${config.endpoint.product}/${productId}`,
      method: 'GET',
    }),

  getProductStores: (params: any) =>
    axios({
      url: `${config.endpoint.product}/${params.product_id}/stores`,
      method: 'GET',
      params,
    }),

  getProductInventory: (params: any) =>
    axios({
      url: `${config.endpoint.product}/${params.product_id}/inventories`,
      method: 'GET',
      params,
    }),

  postUpdateProductInformation: (data: object) =>
    axios({
      url: `${config.endpoint.product}/update`,
      method: 'POST',
      data,
    }),

  postUpdateStorePrice: (productId: Key, data: object) =>
    axios({
      url: `${config.endpoint.product}/${productId}/stores/update`,
      method: 'POST',
      data,
    }),

  getProductGroupDetails: (productGroupId: string) =>
    axios({
      url: `${config.endpoint.product}/groups/${productGroupId}`,
      method: 'GET',
    }),
  exportOrder: (params: any) =>
    axios({
      url: `${config.endpoint.product}/generate-csv`,
      method: 'GET',
      params,
    }),
  getProductTags: (params: any) =>
    axios({
      url: `${config.endpoint.product}/${params.product_id}/tags`,
      method: 'GET',
      params,
    }),
  getProductRules: (params: any) =>
    axios({
      url: `${config.endpoint.product}/${params.product_id}/rules`,
      method: 'GET',
    }),
};

export default PRODUCT_API;
