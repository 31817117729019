import axios from './axios';
import config from './config';

const POS_API = {
  getPos: (posId: string) =>
    axios({
      url: `${config.endpoint.pos}/${posId}`,
      method: 'GET',
    }),
  getAllPos: (params: object) =>
    axios({
      url: `${config.endpoint.pos}`,
      method: 'GET',
      params,
    }),
  updatePos: (data: object) =>
    axios({
      url: `${config.endpoint.pos}/update`,
      method: 'POST',
      data,
    }),
  createPos: (data: object) =>
    axios({
      url: `${config.endpoint.pos}/create`,
      method: 'POST',
      data,
    }),
  getAllPosRoles: (params: object) =>
    axios({
      url: `${config.endpoint.posRole}`,
      method: 'GET',
      params,
    }),
  createPosRole: (data: object) =>
    axios({
      url: `${config.endpoint.posRole}/create`,
      method: 'POST',
      data,
    }),
  getPosRoles: () =>
    axios({
      url: `${config.endpoint.posRole}/all`,
    }),
  resetPosAuth: (posId: string) =>
    axios({
      url: `${config.endpoint.pos}/${posId}/reset-auth`,
      method: 'GET',
    }),
  getPermissions: () =>
    axios({
      url: `${config.endpoint.posRole}/permissions`,
      method: 'GET',
    }),

  getRole: (roleId: string) =>
    axios({
      url: `${config.endpoint.posRole}/${roleId}`,
      method: 'GET',
    }),
  updateRole: (data: object) =>
    axios({
      url: `${config.endpoint.posRole}/update`,
      method: 'POST',
      data,
    }),
};

export default POS_API;
