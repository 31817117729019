import axios from './axios';
import config from './config';

const USER_API = {
  getUsers: (params: any) =>
    axios({
      url: `${config.endpoint.users}`,
      method: 'GET',
      params,
    }),

  getUser: (userId: string) =>
    axios({
      url: `${config.endpoint.users}/${userId}`,
      method: 'GET',
    }),

  createUser: (data: object) =>
    axios({
      url: `${config.endpoint.users}/create`,
      method: 'POST',
      data,
    }),

  updateUser: (data: object) =>
    axios({
      url: `${config.endpoint.users}/update`,
      method: 'PATCH',
      data,
    }),
};

export default USER_API;
