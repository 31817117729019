import { CampaignActionType } from '../../actions/ActionTypes';

interface CampaignState {
  campaigns: object;
  campaign: object;
  loading: boolean;
  error: object;
  createCampaignLoading: boolean;
  createCampaignSuccess: boolean;
  updateCampaignLoading: boolean;
  updateCampaignSuccess: boolean;
}

const initialState: CampaignState = {
  campaigns: {},
  campaign: {},
  loading: false,
  error: {},
  createCampaignLoading: false,
  createCampaignSuccess: false,
  updateCampaignLoading: false,
  updateCampaignSuccess: false,
};

const reducer = (state: CampaignState = initialState, action: any = {}) => {
  switch (action.type) {
    case CampaignActionType.GET_CAMPAIGNS: {
      return {
        ...state,
        loading: true,
      };
    }
    case CampaignActionType.GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
      };
    }
    case CampaignActionType.GET_CAMPAIGNS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case CampaignActionType.GET_CAMPAIGN: {
      return {
        ...state,
        loading: true,
      };
    }
    case CampaignActionType.GET_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaign: action.payload,
        loading: false,
      };
    }
    case CampaignActionType.GET_CAMPAIGN_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CampaignActionType.POST_CAMPAIGN: {
      return {
        ...state,
        createCampaignLoading: true,
      };
    }
    case CampaignActionType.POST_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        createCampaignLoading: false,
        createCampaignSuccess: true,
      };
    }
    case CampaignActionType.POST_CAMPAIGN_FAILED: {
      return {
        ...state,
        createCampaignLoading: false,
        error: action.payload,
      };
    }
    case CampaignActionType.PUT_CAMPAIGN: {
      return {
        ...state,
        updateCampaignLoading: true,
      };
    }
    case CampaignActionType.PUT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        updateCampaignLoading: false,
        updateCampaignSuccess: true,
      };
    }
    case CampaignActionType.PUT_CAMPAIGN_FAILED: {
      return {
        ...state,
        updateCampaignLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
