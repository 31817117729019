import axios from './axios';
import config from './config';

const CUSTOMER_API = {
  getStats: (customerId: String) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/stats`,
      method: 'GET',
    }),

  getCustomers: (params: any) =>
    axios({
      url: `${config.endpoint.customer}`,
      method: 'GET',
      params,
    }),

  getCustomerDetails: (customerId: String) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}`,
      method: 'GET',
    }),

  updateAccountInformation: (data: Object) =>
    axios({
      url: `${config.endpoint.customer}/update`,
      method: 'POST',
      data,
    }),

  getLoyaltyPoints: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/points`,
      method: 'GET',
      params,
    }),

  getMonthlyLoyaltyPoints: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/points-calculate`,
      method: 'GET',
      params,
    }),

  getAllOrders: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/orders`,
      method: 'GET',
      params,
    }),
  /* eslint-disable camelcase */
  getUsedCoupons: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/coupon`,
      method: 'GET',
      params,
    }),

  getAssociatedDevices: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/device`,
      method: 'GET',
      params,
    }),

  getAssociatedDeviceId: (device_id: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${device_id}/customers`,
      method: 'GET',
      params,
    }),

  getAssociatedDeviceIdDetail: (
    customer_id: String,
    device_id: String,
    params: Object = {}
  ) =>
    axios({
      url: `${config.endpoint.customer}/device/${device_id}/${customer_id}`,
      method: 'GET',
      params,
    }),

  updateAssociatedDeviceId: (
    data: object,
    device_id: String,
    customer_id: String
  ) =>
    axios({
      url: `${config.endpoint.customer}/${device_id}/${customer_id}`,
      method: 'POST',
      data,
    }),

  getAssociatedTags: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/tags`,
      method: 'GET',
      params,
    }),

  getAllCarts: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/carts`,
      method: 'GET',
      params,
    }),

  getAssociatedStores: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/stores`,
      method: 'GET',
      params,
    }),

  getSupportTickets: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/tickets`,
      method: 'GET',
      params,
    }),

  getCustomerAddress: (customerId: String, params: Object = {}) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/address`,
      method: 'GET',
      params,
    }),

  exportLoyaltyPoints: (customerId: String, params: any) =>
    axios({
      url: `${config.endpoint.customer}/${customerId}/loyalty-points-csv`,
      method: 'GET',
      params,
    }),

  getCartDetails: (cartId: String | undefined) =>
    axios({
      url: `${config.endpoint.cart}/${cartId}`,
      method: 'GET',
    }),
};

export default CUSTOMER_API;
