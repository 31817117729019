/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import moment from 'moment';

export const toFixed = (num: string) => Number(num).toFixed(2);

export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const orderStatus = (status: string) => {
  switch (status) {
    case 'fulfilled': {
      return 'green';
    }
    case 'cancelled': {
      return 'red';
    }
    case 'placed': {
      return 'blue';
    }
    default: {
      return 'cyan';
    }
  }
};

export const formatDate = (date: string, format: string) =>
  moment(date).format(format);

export const formatArray = (array: []) => {
  const newArray: any = [];
  if (array && array.length) {
    array.forEach((obj: any) => {
      newArray.push({
        key: obj.id || obj,
        label: obj.name || obj.title || obj,
      });
    });
  }
  return newArray;
};

export const generateFilterString = (searchHearders: any) => {
  let searchString = '';
  for (const [key, value] of Object.entries(searchHearders)) {
    if (searchString && value) {
      searchString += ',';
    }
    if (value) searchString += value && `${key}:${value}`;
  }
  return searchString;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

export const formatPhone = (contactNumber: string) => {
  let phone = contactNumber;
  if (!contactNumber.startsWith('+91')) {
    phone = `+91${contactNumber}`;
  }
  return phone;
};

export const isAdmin = () => {
  const user: any = localStorage.getItem('USER');
  const { roles } = user;
  return roles.includes('admin');
};

export const hasPermission = (permission: string) => {
  const user: any = localStorage.getItem('USER');
  const { permissions } = user;
  return permissions.includes(`${permission}`);
};

export const getUserRole = () => {
  const user: any = localStorage.getItem('USER');
  if (user) {
    const { roles } = JSON.parse(user);
    return roles[0];
  }
  return false;
};

export const snakeToCamel = (str: any) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group: any) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );

export const setLocalStorage = (name: string, data: any) => {
  const response = JSON.stringify(data);
  localStorage.setItem(name, response);
};

export const getlocalStorage = (name: string) => {
  const response = localStorage.getItem(name);
  return response ? JSON.parse(response) : null;
};

export const removelocalStorage = (name: string) =>
  localStorage.removeItem(name);

export const crypt = (salt: string, text: any) => {
  const textToChars = (res: string) => res.split('').map(c => c.charCodeAt(0));
  const byteHex = (n: any) => `0${Number(n).toString(16)}`.substr(-2);
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a, b) => a ^ b, code); // eslint-disable-line no-bitwise
  const newText = text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
  return newText;
};

export const decrypt = (salt: string, encoded: any) => {
  const textToChars = (text: string) =>
    text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a, b) => a ^ b, code); // eslint-disable-line no-bitwise
  return encoded
    .match(/.{1,2}/g)
    .map((hex: any) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: any) => String.fromCharCode(charCode))
    .join('');
};

export const createUrlParams = (filters: string, page: any) => {
  let urlParams = '';
  const curUrl = new URLSearchParams(window.location.search);
  const tabParam = Object.fromEntries(curUrl.entries()).tab;
  if (tabParam) {
    urlParams = `?tab=${tabParam}`;
  }

  if (filters && filters.length) {
    if (urlParams.length) {
      urlParams = `${urlParams}&page=${page}`;
    } else {
      urlParams = `?page=${page}`;
    }
    const filtParams = filters.split(',');
    if (filtParams.length > 0) {
      for (let i = 0; i < filtParams.length; i += 1) {
        const params = filtParams[i] ? filtParams[i].split(':') : [];
        if (params.length === 3) {
          urlParams = `${urlParams}&${params[0]}=${params[2]}`;
        }
      }
    }
  }

  return urlParams;
};

export const setFilters: any = (identifier: any) => {
  const curUrl = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(curUrl.entries());
  let filters = '';
  let isFirst = true;
  const plainUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  const APPLIED_HEADERS =
    getlocalStorage(`headers_${plainUrl}${identifier}`) || {};

  const APPLIED_PARAMS = getlocalStorage(`params_${plainUrl}${identifier}`) || {
    page: 1,
    ordering: null,
    filters: '',
    limit: 20,
  };

  const keys = Object.keys(urlParams);
  for (let i = 0; i < keys.length; i += 1) {
    if (keys[i] !== 'page' && keys[i] !== 'tab' && keys[i] !== 'limit') {
      filters += `${isFirst ? '' : ','}${keys[i]}:like:${urlParams[keys[i]]}`;
      APPLIED_HEADERS[keys[i]] = `like:${urlParams[keys[i]]}`;
      APPLIED_PARAMS.filters = filters;
      isFirst = false;
    }
    if (keys[i] === 'page') {
      APPLIED_PARAMS.page = +urlParams[keys[i]];
    }
  }

  setLocalStorage(`params_${plainUrl}${identifier}`, APPLIED_PARAMS);
  setLocalStorage(`headers_${plainUrl}${identifier}`, APPLIED_HEADERS);
};

export const getLastComponent = () => {
  const plainUrlComponents =
    `${window.location.protocol}//${window.location.host}${window.location.pathname}`.split(
      '/'
    );
  plainUrlComponents.splice(0, 3);
  for (let i = 0; i < plainUrlComponents.length; i += 1) {
    if (plainUrlComponents[i].toLocaleLowerCase() === 'logs') {
      return `Logs: ${plainUrlComponents[i + 1]}`;
    }
  }
  for (let i = plainUrlComponents.length - 1; i >= 0; i -= 1) {
    if (plainUrlComponents[i] && plainUrlComponents[i].length) {
      return (
        plainUrlComponents[i].charAt(0).toUpperCase() +
        plainUrlComponents[i].slice(1)
      );
    }
  }
  return 'Dashboard';
};

export const isUrlVideo = (path: string) => {
  if (!path) return false;
  const videos = ['mp4', '3gp', 'ogg'];
  const url = new URL(path);
  const extension = url.pathname.split('.')[1];
  if (videos.includes(extension)) {
    return true;
  }
  return false;
};
