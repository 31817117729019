import { Button, Popconfirm } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

const ExportButton = (params: any) => {
  const { param, exportLoader, exportHandler, title } = params;

  const confirm = () => {
    if (param) {
      exportHandler();
    }
  };
  const cancel = () => {};
  return (
    <Popconfirm
      title="Are you sure want to Generate csv?"
      onConfirm={confirm}
      placement="left"
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="default"
        loading={exportLoader}
        icon={<FileExcelOutlined />}
      >
        {title}
      </Button>
    </Popconfirm>
  );
};

export default ExportButton;
