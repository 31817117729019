import { RoleActionType } from '../../actions/ActionTypes';

interface RoleState {
  roleLoader: boolean;
  roles: object;
  role: object;
  permissionsList: object;
  isError: boolean;
}

const initialState: RoleState = {
  roleLoader: false,
  roles: {},
  role: {},
  permissionsList: {},
  isError: false,
};

const reducer = (state: RoleState = initialState, action: any = {}) => {
  switch (action.type) {
    // Getting roles grid
    case RoleActionType.GET_ROLES: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
        roleLoader: false,
      };
    }

    // Getting all roles
    case RoleActionType.GET_ALL_ROLES: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.GET_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
        roleLoader: false,
      };
    }

    // Getting all permissions
    case RoleActionType.GET_PERMISSIONS: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.GET_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissionsList: action.payload,
        roleLoader: false,
      };
    }

    // Getting role by id
    case RoleActionType.GET_ROLE: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.GET_ROLE_SUCCESS: {
      return {
        ...state,
        role: action.payload,
        roleLoader: false,
      };
    }

    // Creating a new role
    case RoleActionType.CREATE_ROLE: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleLoader: false,
      };
    }

    // Updating a role
    case RoleActionType.UPDATE_ROLE: {
      return {
        ...state,
        roleLoader: true,
      };
    }
    case RoleActionType.UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        roleLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
