/* eslint-disable func-names */
import { Key } from 'react';
import PRODUCT_API from '../../../api/productAPI';
import { ProductActionType } from '../ActionTypes';

const getProducts = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GET_PRODUCTS,
    });
    const res = await PRODUCT_API.getProducts(params);
    dispatch({
      type: ProductActionType.LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getProductGroups = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GET_PRODUCT_GROUPS,
    });
    const res = await PRODUCT_API.getProductGroups(params);
    dispatch({
      type: ProductActionType.LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getProductById = (productId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GET_PRODUCT,
    });
    const res = await PRODUCT_API.getProductDetails(productId);
    dispatch({
      type: ProductActionType.LOAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getProductStoresById = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GETTING_STORE_PRICES,
    });
    const res = await PRODUCT_API.getProductStores(params);
    dispatch({
      type: ProductActionType.GET_STORE_PRICES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getProductInventoryById = (params: {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GET_PRODUCT_INVENTORY,
    });
    const res = await PRODUCT_API.getProductInventory(params);
    dispatch({
      type: ProductActionType.GET_PRODUCT_INVENTORY_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateProductInformation = (data: object) => (dispatch: any) => {
  dispatch({
    type: ProductActionType.UPDATING_PRODUCT_INFORMATION,
  });
  return PRODUCT_API.postUpdateProductInformation(data).then(response => {
    dispatch({
      type: ProductActionType.UPDATE_PRODUCT_INFORMATION_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

const UpdateStorePriceById =
  (productId: Key, data: object) => async (dispatch: any) => {
    try {
      dispatch({
        type: ProductActionType.UPDATE_STORE_PRICE,
      });
      const res = await PRODUCT_API.postUpdateStorePrice(productId, data);
      dispatch({
        type: ProductActionType.UPDATE_STORE_PRICE_SUCCESS,
        payload: res.data.data,
      });
    } catch (e: any) {
      dispatch({
        type: ProductActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const getProductGroupDetailById =
  (productGroupId: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: ProductActionType.GET_PRODUCT_GROUP_DETAILS,
      });
      const res = await PRODUCT_API.getProductGroupDetails(productGroupId);
      dispatch({
        type: ProductActionType.LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: ProductActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const exportProduct = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.EXPORT_PRODUCT,
    });
    const res = await PRODUCT_API.exportOrder(params);
    dispatch({
      type: ProductActionType.EXPORT_PRODUCT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.EXPORT_PRODUCT_ERROR,
      payload: e,
    });
  }
};

const getProductTagsById = (productId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GETTING_TAGS,
    });
    const res = await PRODUCT_API.getProductTags(productId);
    dispatch({
      type: ProductActionType.GET_TAGS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.TAGS_LOAD_FAILED,
      payload: e,
    });
  }
};

const getProductRulesById = (productId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ProductActionType.GET_RULES,
    });
    const res = await PRODUCT_API.getProductRules(productId);
    dispatch({
      type: ProductActionType.GET_RULES_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ProductActionType.GET_RULES_FAILURE,
      payload: e,
    });
  }
};

const resetExport = (dispatch: any) => {
  dispatch({
    type: ProductActionType.RESET_EXPORT,
  });
};

export {
  exportProduct,
  resetExport,
  getProducts,
  getProductGroups,
  getProductById,
  getProductStoresById,
  getProductInventoryById,
  updateProductInformation,
  UpdateStorePriceById,
  getProductGroupDetailById,
  getProductTagsById,
  getProductRulesById,
};
