import P2RDISCOUNT_API from '../../../api/p2rDiscountAPI';
import { P2RDiscountType } from '../ActionTypes';

const getP2RDiscount = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: P2RDiscountType.START_FETCH_P2RDISCOUNT,
    });
    const modifiedParams = { ...params, ordering: null };
    const res = await P2RDISCOUNT_API.getP2RDiscount(modifiedParams);
    dispatch({
      type: P2RDiscountType.FINISH_FETCH_P2RDISCOUNT,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: P2RDiscountType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getp2rdiscountById =
  (p2rdiscountId: any, params: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: P2RDiscountType.START_FETCH_P2RDISCOUNT_ID,
      });
      const modifiedParams = { ...params, ordering: null };
      const res = await P2RDISCOUNT_API.getP2RDiscountid(
        p2rdiscountId,
        modifiedParams
      );
      dispatch({
        type: P2RDiscountType.FINISH_FETCH_P2RDISCOUNT_ID,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: P2RDiscountType.LOAD_FAILED,
        payload: e,
      });
    }
  };
const getp2rdiscountIdDetails =
  (p2rdiscountDetailsId: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: P2RDiscountType.START_FETCH_P2RDISCOUNT_ID_DETAILS,
      });
      const res = await P2RDISCOUNT_API.getP2RDiscountidDetails(
        p2rdiscountDetailsId
      );
      dispatch({
        type: P2RDiscountType.FINISH_FETCH_P2RDISCOUNT_ID_DETAILS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: P2RDiscountType.LOAD_FAILED,
        payload: e,
      });
    }
  };

const createP2RDiscountid = (data: object) => (dispatch: any) => {
  dispatch({
    type: P2RDiscountType.CREATE_P2RDISCOUNT_ID,
  });
  return P2RDISCOUNT_API.postCreateP2RDiscountid(data).then(response => {
    dispatch({
      type: P2RDiscountType.CREATE_P2RDISCOUNT_ID_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};
const updateP2RDiscountid = (data: object) => (dispatch: any) => {
  dispatch({
    type: P2RDiscountType.UPDATE_P2RDISCOUNT_ID,
  });
  return P2RDISCOUNT_API.postUpdateP2RDiscountid(data).then(response => {
    dispatch({
      type: P2RDiscountType.UPDATE_P2RDISCOUNT_ID_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

export {
  getP2RDiscount,
  getp2rdiscountById,
  createP2RDiscountid,
  updateP2RDiscountid,
  getp2rdiscountIdDetails,
};
