import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form } from 'antd';
import { useEffect, useState } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import { errorNotification, successNotification } from '../../components';
import { p2rDiscountAction } from '../../redux/actions';
import DrawerP2RDiscount from './P2RDiscountForm';

const P2RDiscountIdDetails = () => {
  const { p2rdiscountDetailsId } = useParams();
  const { getP2RDiscountIdDetails } = useAppSelector(
    state => state.p2rdiscount
  );
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      p2rDiscountAction.getp2rdiscountIdDetails(`${p2rdiscountDetailsId}`)
    );
  }, []);

  const handleEdit = () => {
    setVisible(true);
    const displayCardId = getP2RDiscountIdDetails.display_card_id;
    const storeId = getP2RDiscountIdDetails.store.id;
    const discountpercentage = getP2RDiscountIdDetails.discount_percentage;
    const discountbystoreid =
      getP2RDiscountIdDetails.discount_by_store_percentage;
    const isdisabled = getP2RDiscountIdDetails.is_disabled;
    form.setFieldsValue({
      display_card_id: displayCardId ?? undefined,
      store_id: storeId ?? undefined,
      discount_by_store_percentage: discountbystoreid ?? undefined,
      discount_percentage: discountpercentage ?? undefined,
      is_disabled: isdisabled ?? undefined,
    });
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (value: any) => {
    // eslint-disable-next-line no-param-reassign
    value.id = getP2RDiscountIdDetails.id;
    setLoading(true);
    value.discount_percentage = parseFloat(value.discount_percentage);
    value.discount_by_store_percentage = parseFloat(
      value.discount_by_store_percentage
    );
    value.display_card_id = parseInt(value.display_card_id, 10);
    value.is_disabled = value.is_disabled === '1' || value.is_disabled === 1;

    new Promise((resolve, reject) => {
      resolve(dispatch(p2rDiscountAction.updateP2RDiscountid(value)));
      reject();
    })
      .then(() => {
        dispatch(
          p2rDiscountAction.getp2rdiscountIdDetails(`${p2rdiscountDetailsId}`)
        );
        setIsEdit(false);
        setVisible(false);
        setLoading(false);
        successNotification({
          message: 'Success!!',
          description: 'Updated successfully!',
          placement: 'bottomLeft',
        });
        form.resetFields();
      })
      .catch(err => {
        setLoading(false);
        setVisible(true);
        errorNotification({
          message: 'Error!',
          description: `${err.message}`,
          placement: 'bottomLeft',
        });
      });
  };

  return (
    <Card
      title="P2R Discount Details"
      bodyStyle={{ padding: 0 }}
      extra={
        <Button icon={<EditOutlined />} type="link" onClick={handleEdit} />
      }
    >
      <Helmet>
        <title>{`P2R Discount Id Details: ${getP2RDiscountIdDetails.id}`}</title>
      </Helmet>
      <DrawerP2RDiscount
        onClose={onClose}
        visible={visible}
        form={form}
        onFinish={onFinish}
        loading={loading}
        isEdit={isEdit}
        getP2RDiscountIdDetails={getP2RDiscountIdDetails}
      />
      <Descriptions
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Id">
          {getP2RDiscountIdDetails.id}
        </Descriptions.Item>
        <Descriptions.Item label="Display Card Id">
          {getP2RDiscountIdDetails.display_card_id}
        </Descriptions.Item>
        <Descriptions.Item label="Discount Percentage">
          {getP2RDiscountIdDetails.discount_percentage}
        </Descriptions.Item>
        <Descriptions.Item label="Discount By Store Percentage">
          {getP2RDiscountIdDetails.discount_by_store_percentage}
        </Descriptions.Item>
        <Descriptions.Item label="Store Id">
          {getP2RDiscountIdDetails?.store?.id}
        </Descriptions.Item>
        <Descriptions.Item label="Store Name">
          {getP2RDiscountIdDetails?.store?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Is Disabled">
          {getP2RDiscountIdDetails.is_disabled === 1 ? 'Active' : 'Inactive'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default P2RDiscountIdDetails;
