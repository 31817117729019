import IMPORTER_ACTIONS_API from '../../../api/importerActionsApi';
import { ImporterActionsTypes } from '../ActionTypes';

const getAllImporters = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ImporterActionsTypes.START_FETCH_ALL_IMPORTERS,
    });
    const res = await IMPORTER_ACTIONS_API.getAllImporters(params);
    dispatch({
      type: ImporterActionsTypes.FINISH_FETCH_ALL_IMPORTERS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: ImporterActionsTypes.LOAD_FAILED,
      payload: e,
    });
  }
};

const addNewImport = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: ImporterActionsTypes.START_ADD_NEW_IMPORT,
    });
    const res = await IMPORTER_ACTIONS_API.addNewImport(data);
    dispatch({
      type: ImporterActionsTypes.FINISH_ADD_NEW_IMPORT,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: ImporterActionsTypes.LOAD_FAILED,
      payload: e,
    });
  }
};

export { getAllImporters, addNewImport };
