/* eslint-disable default-param-last */
import { PosActionType } from '../../actions/ActionTypes';

interface PosReducer {
  posLoader: boolean;
  isError: boolean;
  submitPosLoader: boolean;
  resetAuthSuccess: boolean;
  createdsuccess: boolean;
  updatedsuccess: boolean;
  posUser: object;
  error: object;
  posUsers: any;
}

const initialState: PosReducer = {
  posLoader: false,
  resetAuthSuccess: false,
  createdsuccess: false,
  updatedsuccess: false,
  submitPosLoader: false,
  isError: false,
  posUser: {},
  error: {},
  posUsers: {},
};

const reducer = (state: PosReducer = initialState, action: any) => {
  switch (action.type) {
    case PosActionType.GET_ALL_POS_USER:
      return {
        ...state,
        posLoader: true,
      };
    case PosActionType.GET_ALL_POS_USER_SUCCESS:
      return {
        ...state,
        posUsers: action.payload,
        posLoader: false,
      };
    case PosActionType.GET_ALL_POS_USER_FAILURE:
      return {
        ...state,
        isError: true,
        posLoader: false,
        error: action.payload,
      };
    case PosActionType.GET_POS_USER: {
      return {
        ...state,
        posLoader: true,
        error: {},
      };
    }
    case PosActionType.GET_POS_USER_SUCCESS: {
      return {
        ...state,
        posUser: action.payload,
        posLoader: false,
      };
    }
    case PosActionType.GET_POS_USER_FAILURE: {
      return {
        ...state,
        posLoader: false,
        isError: true,
        error: action.payload,
      };
    }
    case PosActionType.CREATE_POS: {
      return {
        ...state,
        submitPosLoader: true,
      };
    }
    case PosActionType.CREATE_POS_SUCCESS: {
      action.payload.isNew = true;
      return {
        ...state,
        posUsers: {
          ...state.posUsers,
          results: [action.payload, ...state.posUsers.results],
          count: state.posUsers.count + 1,
        },
        createdsuccess: true,
        submitPosLoader: false,
      };
    }

    case PosActionType.CREATE_POS_FAILURE: {
      return {
        ...state,
        success: false,
        submitPosLoader: false,
        isError: true,
        error: action.payload,
      };
    }
    case PosActionType.UPDATE_POS: {
      return {
        ...state,
        submitPosLoader: true,
      };
    }
    case PosActionType.UPDATE_POS_SUCCESS: {
      const index = state.posUsers.results.findIndex(
        (posuser: any) => posuser.id === action.payload.id
      );
      action.payload.isNew = true;
      const results = [...state.posUsers.results];
      results[index] = action.payload;
      return {
        ...state,
        posUsers: {
          ...state.posUsers,
          results,
        },
        updatedsuccess: true,
        submitPosLoader: false,
      };
    }
    case PosActionType.UPDATE_POS_FAILURE: {
      return {
        ...state,
        success: false,
        isError: true,
        submitPosLoader: false,
        error: action.payload,
      };
    }
    case PosActionType.RESET_POS:
      return {
        ...state,
        isError: false,
        createdsuccess: false,
        updatedsuccess: false,
        resetAuthSuccess: false,
        error: {},
      };
    case PosActionType.RESET_POS_AUTH_SUCCESS:
      return {
        ...state,
        isError: false,
        resetAuthSuccess: true,
        error: {},
      };
    case PosActionType.RESET_POS_AUTH_FAILURE:
      return {
        ...state,
        resetAuthSuccess: false,
        isError: true,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
