import { useState } from 'react';
import { Col, Row, Typography, Button } from 'antd';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  getTransactions,
  getUpdatePaymentStatus,
  resetPaymentStatus,
} from '../../redux/actions/payment/paymentAction';
import { Cardtable, successNotification } from '../../components';
import { formatDate } from '../../util';

const Transactions = (props: { qrId?: string }) => {
  const { qrId } = props;
  const { transactions, transactionsLoader, paymentLoading, paymentUpdate } =
    useAppSelector(state => state.transactions);
  const [verifyId, setVerifyId] = useState<any>();
  const [verifyIdfalse, setVerifyIdfalse] = useState<any>();

  const dispatch = useAppDispatch();
  const getData = (transactionsId: any) => {
    dispatch(getTransactions(transactionsId, qrId));
  };

  const verifyPaymentStatus = (returnId: string) => {
    dispatch(getUpdatePaymentStatus(returnId, true, 'transaction'));
    setVerifyIdfalse('');
    setVerifyId(returnId);
  };

  const verifyPaymentStatusFalse = (returnId: string) => {
    dispatch(getUpdatePaymentStatus(returnId, false, 'transaction'));
    setVerifyId('');
    setVerifyIdfalse(returnId);
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      type: 'input',
      width: 200,
      render: (text: string, row: any) => (
        <Link to={`/transactions/${row.id}`}>{text}</Link>
      ),
    },

    {
      title: 'Receipt',
      dataIndex: 'receipt',
      type: 'input',
      width: 200,
    },

    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      type: 'input',
      width: 150,
    },

    {
      title: 'Store Id',
      dataIndex: 'store_id',
      type: 'input',
      width: 100,
    },

    {
      title: 'Created At',
      dataIndex: 'created_at',
      type: 'date',
      render: (text: string) => formatDate(text, 'lll'),
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      type: 'input',
      width: 100,
    },
    {
      title: 'Recheck Status',
      type: 'input',
      key: 'action',
      render: (text: string, record: any) => (
        <Button
          onClick={() => verifyPaymentStatus(record.id.toString())}
          loading={verifyId === record.id && paymentLoading}
          disabled={
            record.gateway_order_id && record.gateway_order_id.includes('qr_')
          }
        >
          recheck
        </Button>
      ),
      width: 150,
    },
    {
      title: 'Recheck Status False',
      type: 'input',
      key: 'action',
      render: (text: string, record: any) => (
        <Button
          onClick={() => verifyPaymentStatusFalse(record.id.toString())}
          loading={verifyIdfalse === record.id && paymentLoading}
          disabled={
            record.gateway_order_id && record.gateway_order_id.includes('qr_')
          }
        >
          recheck
        </Button>
      ),
      width: 150,
    },
  ];

  const heading = () => (
    <Row justify="space-between">
      <Col>
        <Typography.Text strong>Transactions</Typography.Text>
      </Col>
    </Row>
  );

  if (paymentUpdate) {
    const key = 'Success';
    const args = {
      message: 'Success!!',
      key,
      description: 'rechecked payment state successfully!',
      duration: 5,
      style: {
        height: 110,
        width: 300,
        padding: 30,
      },
    };
    const params = {
      page: 1,
      ordering: null,
      filters: '',
      limit: 20,
    };
    successNotification(args);
    dispatch(resetPaymentStatus);
    getData(params);
  }

  return (
    <Cardtable
      columns={qrId ? columns.slice(0, 6) : columns}
      total={get(transactions, 'count', 0)}
      getData={getData}
      // @ts-ignore
      title={heading}
      loading={transactionsLoader}
      rowKey="id"
      dataSource={get(transactions, 'results', [])}
      assignedKeys={get(transactions, 'assignedKeys', [])}
      rowClassName={(row: any) => row.isNew === true && `fade-out`}
      showPagination
      expandable={{
        expandedRowRender: (record: any) => JSON.stringify(record.response),
        rowExpandable: (record: any) => record.response !== null,
      }}
    />
  );
};

export default Transactions;
