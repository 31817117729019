import { FeedbacksActionType } from '../../actions/ActionTypes';

interface FeedbackState {
  feedbacks: object;
  feedbackLoader: boolean;
  error: object;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
}
const initialState: FeedbackState = {
  feedbacks: {},
  feedbackLoader: false,
  error: {},
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
};

const reducer = (state: FeedbackState = initialState, action: any = {}) => {
  switch (action.type) {
    case FeedbacksActionType.GET_FEEDBACKS: {
      return {
        ...state,
        feedbackLoader: true,
      };
    }
    case FeedbacksActionType.GET_FEEDBACKS_SUCCESS: {
      return {
        ...state,
        feedbacks: action.payload,
        feedbackLoader: false,
      };
    }
    case FeedbacksActionType.GET_FEEDBACKS_FAILURE: {
      return {
        ...state,
        feedbackLoader: false,
        error: action.payload,
      };
    }
    case FeedbacksActionType.GENERATE_CSV:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case FeedbacksActionType.GENERATE_CSV_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case FeedbacksActionType.GENERATE_CSV_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case FeedbacksActionType.RESET_EXPORT:
      return {
        ...state,
        exportSuccess: false,
      };
    default: {
      return state;
    }
  }
};
export default reducer;
