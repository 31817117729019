import { getCookie } from '../util';
import axios from './axios';
import config from './config';

const ORDER_API = {
  getOrders: (params: any) =>
    axios({
      url: `${config.endpoint.order}/`,
      method: 'GET',
      params,
    }),

  getCancelRequests: (params: any) =>
    axios({
      url: `${config.endpoint.order}/order-cancellation-requests-grid`,
      method: 'GET',
      params,
    }),
  getOrderDetails: (orderId: string | undefined) =>
    axios({
      url: `${config.endpoint.order}/${orderId}`,
      method: 'GET',
    }),
  cancelOrder: (orderId: string | undefined) =>
    axios({
      url: `${config.endpoint.order}/${orderId}/cancel-order`,
      method: 'GET',
    }),
  exportOrder: (params: any) =>
    axios({
      url: `${config.endpoint.order}/generate-csv`,
      method: 'GET',
      params,
    }),

  getOrderActivity: (orderId: any, type: string) =>
    axios({
      url: `/activity-logs/${type}/${orderId}`,
      method: 'GET',
    }),
  exportCancellationRequests: (params: any) =>
    axios({
      url: `${config.endpoint.order}/cancel-csv`,
      method: 'GET',
      params,
    }),
  fastForwardUpdatedAt: (orderID: string) =>
    axios({
      url: `${config.endpoint.order}/${orderID}/fast-forward`,
      method: 'GET',
    }),
};
export default ORDER_API;
// eslint-disable-next-line import/prefer-default-export
