/* eslint-disable prefer-destructuring */
import { CategoryActionType } from '../../actions/ActionTypes';

interface CategoryState {
  category: object;
  categoryDetails: object;
  categoryLoader: boolean;
  isError: boolean;
}

const initialState: CategoryState = {
  category: {},
  categoryDetails: {},
  categoryLoader: false,
  isError: false,
};

const reducer = (state: CategoryState = initialState, action: any = {}) => {
  switch (action.type) {
    case CategoryActionType.GET_CATEGORY: {
      return {
        ...state,
        categoryLoader: true,
      };
    }
    case CategoryActionType.GET_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: action.payload,
        categoryLoader: false,
      };
    }
    case CategoryActionType.LOAD_FAILED: {
      return {
        ...state,
        category: {
          ...state.category,
        },
        isError: true,
        categoryLoader: false,
      };
    }
    case CategoryActionType.GET_CATEGORY_DETAIL: {
      return {
        ...state,
        categoryLoader: true,
      };
    }
    case CategoryActionType.GET_CATEGORY_DETAIL_SUCCESS: {
      return {
        ...state,
        categoryDetails: action.payload,
        categoryLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
