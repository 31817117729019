import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Login = lazy(() => import('./pages/Login/Login'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const NotFound = lazy(() => import('./pages/NotFound'));

const PublicRouter = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/*" element={<Navigate to="/" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default PublicRouter;
