import axios from './axios';
import config from './config';

const AUTH_API = {
  postLoginUser: (data: object) =>
    axios({
      url: `${config.endpoint.login}`,
      method: 'POST',
      data,
    }),

  validateUser: () =>
    axios({
      url: `${config.endpoint.user}/validate`,
      method: 'GET',
    }),

  socialAuth: (params: any) =>
    axios({
      url: `${config.endpoint.auth}/callback`,
      method: 'GET',
      params,
    }),

  logoutUser: () =>
    axios({
      url: `${config.endpoint.logout}`,
      method: 'GET',
    }),

  generateResetToken: (data: object) =>
    axios({
      url: `${config.endpoint.generateToken}`,
      method: 'POST',
      data,
    }),
  resetPassword: (data: any) =>
    axios({
      url: `${config.endpoint.resetPassword}/${data.token}`,
      method: 'POST',
      data,
    }),
};

// eslint-disable-next-line import/prefer-default-export
export default AUTH_API;
