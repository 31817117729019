import { useEffect, useState } from 'react';
import { Form, Button, Card, Input, Select, Row, Col, Space, Tag } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import { customerActions } from '../../redux/actions';
import PermissionsGate from '../../services/permissionGate';
import { errorNotification, successNotification } from '../../components';

interface AssociatedDeviceIdData {
  id: number;
  customer_id: any;
  device_id: any;
  blocklist: any;
  status: number;
  last_login: number;
  created_at: number;
  mobile_number: number;
}
/* eslint-disable camelcase */
const AssociatedDeviceId = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { customer_id, device_id } = useParams();
  const { associatedDevicesIdDetail } = useAppSelector(state => state.customer);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(
      customerActions.getAssociatedDevicesIdDetail(
        `${customer_id}`,
        `${device_id}`
      )
    );
  }, []);

  const { id } = associatedDevicesIdDetail;

  useEffect(() => {
    form.setFieldsValue(associatedDevicesIdDetail);
  }, [associatedDevicesIdDetail]);

  const onFinish = (value: AssociatedDeviceIdData) => {
    setLoading(true);
    value.id = id;
    new Promise((resolve, reject) => {
      resolve(
        dispatch(
          customerActions.updateAssociatedDeviceId(
            value,
            device_id,
            customer_id
          )
        )
      );
      reject();
    })
      .then(() => {
        setLoading(false);
        navigate(`/customer/${device_id}`);
        successNotification({
          message: 'Success!!',
          description: 'Record updated successfully!',
          placement: 'bottomLeft',
        });
      })
      .catch(err => {
        setLoading(false);
        navigate(`/customer/${device_id}`);
        errorNotification({
          message: 'Error!',
          description: `${err.message}`,
          placement: 'bottomLeft',
        });
      });
  };
  return (
    <Card
      title={<Tag color="blue">{customer_id}</Tag>}
      extra={
        <Row justify="space-between">
          <Col>
            <Space size="large">
              <PermissionsGate RenderError={() => ''}>
                <Button
                  key="1"
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={form.submit}
                  loading={loading}
                >
                  Update
                </Button>
              </PermissionsGate>
            </Space>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        name="Primary information"
        layout="vertical"
      >
        <Row gutter={12}>
          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item label="Customer Id" name="customer_id">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item label="ID" name="id">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item label="Device Id" name="device_id">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item
              label="Blocklist"
              name="blocklist"
              rules={[{ required: true, message: 'Please input status!' }]}
            >
              <Select>
                <Select.Option value={1}>Blocked</Select.Option>
                <Select.Option value={0}>Not Blocked</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item label="Status" name="status">
              <Select disabled>
                <Select.Option value={1}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: '24' }}
            sm={{ span: '24' }}
            md={{ span: '12' }}
            lg={{ span: '12' }}
            xl={{ span: '12' }}
            xxl={{ span: '12' }}
          >
            <Form.Item label="Mobile No." name="mobile_number">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default AssociatedDeviceId;
