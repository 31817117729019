import axios from './axios';
import config from './config';

const STATE_API = {
  getStates: () =>
    axios({
      url: `${config.endpoint.states}`,
      method: 'GET',
    }),
  getCities: (stateId: string) =>
    axios({
      url: `${config.endpoint.cities}/${stateId}`,
      method: 'GET',
    }),
  getPincodes: (cityId: string) =>
    axios({
      url: `${config.endpoint.pincodes}/${cityId}`,
      method: 'GET',
    }),
};
export default STATE_API;
