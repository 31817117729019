/* eslint-disable default-param-last */
import { PosDeviceActionType } from '../../actions/ActionTypes';

interface PosDeviceReducer {
  posDevice: any;
  posLoader: boolean;
  posChart: any;
}
const initialState: PosDeviceReducer = {
  posDevice: {},
  posLoader: true,
  posChart: {},
};

const reducer = (state: PosDeviceReducer = initialState, action: any) => {
  switch (action.type) {
    case PosDeviceActionType.GET_POS_ACTIVE_DEVICE:
      return {
        ...state,
        posLoader: true,
      };
    case PosDeviceActionType.GET_POS_ACTIVE_DEVICE_SUCCESS:
      return {
        ...state,
        posDevice: action.payload,
        posLoader: false,
      };
    case PosDeviceActionType.GET_POS_ACTIVE_DEVICE_FAILURE:
      return {
        ...state,
        posLoader: false,
      };

    case PosDeviceActionType.GET_POS_DEVICE_CHART:
      return {
        ...state,
        posLoader: true,
      };
    case PosDeviceActionType.GET_POS_DEVICE_CHART_SUCCESS:
      return {
        ...state,
        posChart: action.payload,
        posLoader: false,
      };
    case PosDeviceActionType.GET_POS_DEVICE_CHART_FAILURE:
      return {
        ...state,
        posLoader: false,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
