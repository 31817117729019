import axios from './axios';
import config from './config';

const AGENT_API = {
  getAgents: (params: any) =>
    axios({
      url: `${config.endpoint.agent}`,
      method: 'GET',
      params,
    }),
  getAgentDetails: (agentId: string | undefined) =>
    axios({
      url: `${config.endpoint.agent}/${agentId}`,
      method: 'GET',
    }),
  postUpdateAgentInformation: (data: object) =>
    axios({
      url: `${config.endpoint.agent}/update`,
      method: 'POST',
      data,
    }),
  postCreateAgentInformation: (data: object) =>
    axios({
      url: `${config.endpoint.agent}/create`,
      method: 'POST',
      data,
    }),
};

export default AGENT_API;
