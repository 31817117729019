import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  getDisputeDetails,
  getQRDetails,
  getRefundTransactionDetails,
  resetQRDetails,
} from '../../redux/actions/payment/paymentAction';
import { formatDate } from '../../util';
import PAYMENTAPI from '../../api/paymentAPI';
import { Cardtable } from '../../components';
import Transactions from './Transactions';

const DisputeDetails: any = () => {
  const { qrId } = useParams();
  const { qrCode, qrCodeLoader } = useAppSelector(state => state.transactions);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [closeLoader, setCloseLoader] = useState(false);

  useEffect(() => {
    dispatch(resetQRDetails());
    dispatch(getQRDetails(qrId));
  }, []);

  return (
    <>
      <Card
        title="QR Details"
        extra={
          <Popconfirm
            title="Are you sure to close this QR?"
            onConfirm={async () => {
              setCloseLoader(true);
              await PAYMENTAPI.closeQRCode(qrId).then(resp =>
                setCloseLoader(false)
              );
              dispatch(getQRDetails(qrId));
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              loading={closeLoader}
              disabled={qrCode && qrCode.status !== 'active'}
              danger
            >
              Close QR
            </Button>
          </Popconfirm>
        }
        bodyStyle={{ padding: 0 }}
        loading={qrCodeLoader}
      >
        <Helmet>
          <title>{`QR Code: ${qrId}`}</title>
        </Helmet>
        {qrCode && (
          <Row>
            {qrCode.status === 'active' ? (
              ''
            ) : (
              <Alert
                message="The QR Code is inactive and hence will not accept any payment. Kindly generate a new QR code."
                type="error"
                style={{ width: '100%' }}
              />
            )}
          </Row>
        )}
        {qrCode && (
          <Row gutter={16} style={{ alignItems: 'center' }}>
            <Col span={6}>
              <div style={{ textAlign: 'center' }}>
                <Image
                  preview={{ visible: false }}
                  width={200}
                  src={qrCode.image_url}
                  onClick={() => setVisible(true)}
                />
                <div style={{ display: 'none' }}>
                  <Image.PreviewGroup
                    preview={{
                      visible,
                      onVisibleChange: vis => setVisible(vis),
                    }}
                  >
                    <Image src={qrCode.image_url} />
                  </Image.PreviewGroup>
                </div>
              </div>
            </Col>
            <Col span={18}>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Id">{qrCode.id}</Descriptions.Item>
                <Descriptions.Item label="Amount in rupees">
                  {parseFloat(qrCode.payment_amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Store Id">
                  {qrCode.api_request.notes.store_id}
                </Descriptions.Item>
                <Descriptions.Item label="Store type">
                  {qrCode.api_request.notes.store_type}
                </Descriptions.Item>
                <Descriptions.Item label="Source Id">
                  {qrCode.api_request.notes.source_id}
                </Descriptions.Item>
                <Descriptions.Item label="Driver Id">
                  {qrCode.api_request.notes.driver}
                </Descriptions.Item>
                <Descriptions.Item label="QR Id">
                  {qrCode.qr_id}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {qrCode.status}
                </Descriptions.Item>
                <Descriptions.Item label="QR type">
                  {qrCode.type}
                </Descriptions.Item>
                <Descriptions.Item label="Usage">
                  {qrCode.api_request.usage}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
      </Card>
      {qrCode && <Transactions qrId={qrCode.qr_id} />}
    </>
  );
};

export default DisputeDetails;
