import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './assets/less/index.less';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BrowserTracing } from '@sentry/tracing';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux';
import { getlocalStorage } from './util';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  const profile = getlocalStorage('USER');
  if (profile && profile.id) {
    Sentry.setUser({
      user_id: profile.id,
      user_email: profile.email,
      user_name: profile.name,
    });
  }
  Sentry.init({
    environment: 'production',
    autoSessionTracking: true,
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ChunkLoadError',
      'SyntaxError',
      'Network Error',
    ],
  });

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: 'web1k-admin.firebaseapp.com',
    projectId: 'web1k-admin',
    storageBucket: 'web1k-admin.appspot.com',
    messagingSenderId: process.env.REACT_APP_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={enUS}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
