import axios from './axios';
import config from './config';

const SEARCH_API = {
  searchGeneric: (url: string, params: any) =>
    axios({
      url: `${config.endpoint.search}/${url}`,
      method: 'GET',
      params,
    }),
};

export default SEARCH_API;
