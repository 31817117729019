import axios from './axios';
import config from './config';

const P2RDISCOUNT_API = {
  getP2RDiscount: (params: any) =>
    axios({
      url: `${config.endpoint.p2rdiscount}/`,
      method: 'GET',
      params,
    }),

  getP2RDiscountid: (p2rdiscountId: any, params: any) =>
    axios({
      url: `${config.endpoint.p2rdiscount}/exceptions/${p2rdiscountId}`,
      method: 'GET',
      params,
    }),
  getP2RDiscountidDetails: (p2rdiscountDetailsId: any) =>
    axios({
      url: `${config.endpoint.p2rdiscount}/exceptions/id/${p2rdiscountDetailsId}`,
      method: 'GET',
    }),
  postCreateP2RDiscountid: (data: object) =>
    axios({
      url: `${config.endpoint.p2rdiscount}/exceptions/add`,
      method: 'POST',
      data,
    }),
  postUpdateP2RDiscountid: (data: object) =>
    axios({
      url: `${config.endpoint.p2rdiscount}/exceptions/update`,
      method: 'POST',
      data,
    }),
};
export default P2RDISCOUNT_API;
