import { useState } from 'react';
import { Col, Row, Typography, Button } from 'antd';
import get from 'lodash.get';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux';
import { getQrCodes } from '../../redux/actions/payment/paymentAction';
import { Cardtable } from '../../components';
import { formatDate, isEmptyObject } from '../../util';
import GenerateQR from './GenerateQR';

const QrCodes = () => {
  const { store } = useAppSelector(state => state.store);
  const storeObject = useParams();
  const { qrcodes, qrcodesLoader } = useAppSelector(
    state => state.transactions
  );

  const dispatch = useAppDispatch();
  const getData = (params: any) => {
    if (!isEmptyObject(storeObject)) {
      dispatch(getQrCodes(storeObject.storeId, params));
    } else {
      dispatch(getQrCodes(undefined, params));
    }
  };
  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      type: 'input',
      width: 200,
      render: (text: string, row: any) => (
        <Button
          type="link"
          style={{ whiteSpace: 'normal', padding: '0', textAlign: 'left' }}
          onClick={() => {
            navigate(`/qr-codes/${text}`);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'QR',
      dataIndex: 'qr_id',
      type: 'input',
      width: 150,
      render: (text: string, row: any) => (
        <Button
          type="link"
          href={row.image_url}
          target="_blank"
          style={{ whiteSpace: 'normal', padding: '0', textAlign: 'left' }}
        >
          Download
        </Button>
      ),
    },

    {
      title: 'QR Type',
      dataIndex: 'type',
      type: 'input',
      width: 150,
    },

    {
      title: 'Store Id',
      dataIndex: 'api_request',
      type: 'input',
      width: 150,
      render: (obj: any) => (
        <Button
          type="link"
          style={{ whiteSpace: 'normal', padding: '0', textAlign: 'left' }}
          onClick={() => {
            navigate(`/stores/${obj?.notes?.store_id}`);
          }}
        >
          {obj?.notes?.store_id}
        </Button>
      ),
    },

    {
      title: 'Created At',
      dataIndex: 'created_at',
      type: 'date',
      render: (text: string) => formatDate(text, 'lll'),
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      type: 'input',
      width: 100,
    },
  ];

  const heading = () => (
    <Row justify="space-between">
      <Col>
        <Typography.Text strong>QR Codes</Typography.Text>
      </Col>
      {!isEmptyObject(storeObject) && (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setDrawerVisible(true);
            }}
            disabled={store && !store.status}
          >
            Generate QR Code
          </Button>
        </Col>
      )}
    </Row>
  );

  const getStoreColumns = (column: any) => {
    const col = Object.assign([], column);
    col.splice(3, 1);
    return col;
  };

  return (
    <>
      <Cardtable
        columns={
          !isEmptyObject(storeObject) ? getStoreColumns(columns) : columns
        }
        total={get(qrcodes, 'count', 0)}
        getData={getData}
        // @ts-ignore
        title={heading}
        loading={qrcodesLoader}
        rowKey="id"
        dataSource={get(qrcodes, 'results', [])}
        assignedKeys={get(qrcodes, 'assignedKeys', [])}
        rowClassName={(row: any) => row.isNew === true && `fade-out`}
        showPagination
      />
      {drawerVisible && (
        <GenerateQR
          storeId={storeObject.storeId}
          setVisible={setDrawerVisible}
        />
      )}
    </>
  );
};

export default QrCodes;
