import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import get from 'lodash.get';
import { Tag } from 'antd';
import { useAppSelector, useAppDispatch } from '../../redux';
import { customerActions } from '../../redux/actions';
import { formatDate } from '../../util';
import { Cardtable } from '../../components';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text: string, row: any) => row.id,
    width: 50,
  },
  {
    title: 'customer_id',
    dataIndex: 'customer_id',
    key: 'customer_id',
    render: (text: string, row: any) => (
      <Link to={`/customer/${row.device_id}/${row.customer_id}`}>
        {row.customer_id}
      </Link>
    ),
    width: 75,
  },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    render: (text: number) => (
      <Tag color={text ? 'green' : 'red'}>
        {text === 1 ? 'Active' : 'Inactive'}
      </Tag>
    ),
    width: 50,
  },
  {
    title: 'blocklist',
    dataIndex: 'blocklist',
    key: 'blocklist',
    render: (text: number) => (
      <Tag color={text ? 'red' : 'green'}>
        {text === 1 ? 'Blocked' : 'Not Blocked'}
      </Tag>
    ),
    width: 50,
  },
  {
    title: 'last_login',
    dataIndex: 'last_login',
    key: 'last_login',
    render: (text: string, row: any) =>
      formatDate(get(row, 'last_login', ''), 'll , h:m a'),
    width: 50,
  },
  {
    title: 'created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text: string, row: any) =>
      formatDate(get(row, 'created_at', ''), 'll , h:m a'),
    width: 70,
  },
  {
    title: 'updated_at',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (text: string, row: any) =>
      formatDate(get(row, 'updated_at', ''), 'll , h:m a'),
    width: 70,
  },
  {
    title: 'mobile_number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    render: (text: string, row: any) => get(row, 'mobile_number', ''),
    width: 70,
  },
];

const AssociatedDevicesDetails: FC = () => {
  const { associatedDevicesId, loader } = useAppSelector(
    state => state.customer
  );
  const dispatch = useAppDispatch();
  /* eslint-disable camelcase */
  const { device_id } = useParams();
  const getData = (params: object) => {
    /* eslint-disable camelcase */
    dispatch(customerActions.getAssociatedDevicesId(`${device_id}`, params));
  };

  return (
    <Cardtable
      total={get(associatedDevicesId, 'count', 0)}
      getData={getData}
      // @ts-ignore
      loading={loader}
      rowKey={(row: any) => row.id}
      dataSource={get(associatedDevicesId, 'data', [])}
      assignedKeys={get(associatedDevicesId, 'assignedKeys', [])}
      columns={columns}
      identifier="associatedDevicesId"
      showPagination
    />
  );
};

export default AssociatedDevicesDetails;
