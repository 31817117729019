import { ReportActionTypes } from '../../actions/ActionTypes';

interface ReportState {
  report: any;
  reportLoader: boolean;
  isError: boolean;
}

const initialState: ReportState = {
  report: {},
  reportLoader: false,
  isError: false,
};

const reducer = (state: ReportState = initialState, action: any = {}) => {
  switch (action.type) {
    case ReportActionTypes.GET_ALL_REPORTS: {
      return {
        ...state,
        reportLoader: true,
      };
    }
    case ReportActionTypes.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        report: action.payload,
        reportLoader: false,
      };
    }
    case ReportActionTypes.LOAD_FAILED: {
      return {
        ...state,
        report: {
          ...state.report,
        },
        isError: true,
        reportLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
