import { ProductActionType } from '../../actions/ActionTypes';

interface ProductState {
  productLoader: boolean;
  product: object;
  storePrice: any;
  inventory: object;
  isError: boolean;
  submitted: boolean;
  exportLoader: boolean;
  exportSuccess: boolean;
  exportFailure: object;
  tags: any;
  rules: object;
  ruleLoader: boolean;
  ruleFailure: boolean;
  tagLoader: boolean;
  tagError: boolean;
}

const initialState: ProductState = {
  productLoader: false,
  product: {},
  storePrice: {},
  inventory: {},
  isError: false,
  submitted: false,
  exportLoader: false,
  exportSuccess: false,
  exportFailure: {},
  tags: [],
  rules: {},
  ruleLoader: false,
  ruleFailure: false,
  tagLoader: false,
  tagError: false,
};

const reducer = (state: ProductState = initialState, action: any = {}) => {
  switch (action.type) {
    case ProductActionType.GET_PRODUCTS: {
      return {
        ...state,
        productLoader: true,
      };
    }
    case ProductActionType.GET_PRODUCT_GROUPS: {
      return {
        ...state,
        productLoader: true,
      };
    }
    case ProductActionType.GET_PRODUCT: {
      return {
        ...state,
        productLoader: true,
      };
    }

    // store prices start
    case ProductActionType.GETTING_STORE_PRICES: {
      return {
        ...state,
        productLoader: true,
      };
    }
    case ProductActionType.GET_STORE_PRICES_SUCCESS: {
      return {
        ...state,
        productLoader: false,
        storePrice: action.payload,
      };
    }
    case ProductActionType.UPDATE_STORE_PRICE_SUCCESS: {
      const index = state.storePrice.results.findIndex(
        (storePrice: any) => storePrice.id === action.payload.id
      );
      const results = [...state.storePrice.results];
      results[index] = action.payload;
      return {
        ...state,
        storePrice: {
          ...state.storePrice,
          results,
        },
        productLoader: false,
      };
    }
    // store prices end
    case ProductActionType.GET_PRODUCT_INVENTORY: {
      return {
        ...state,
        productLoader: true,
      };
    }

    case ProductActionType.GET_PRODUCT_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: action.payload,
        productLoader: false,
      };
    }
    case ProductActionType.GETTING_TAGS: {
      return {
        ...state,
        tagLoader: true,
      };
    }

    case ProductActionType.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
        tagLoader: false,
      };
    }

    case ProductActionType.TAGS_LOAD_FAILED: {
      return {
        ...state,
        tags: {
          ...state.tags,
          error: action.payload,
        },
        tagError: true,
        tagLoader: false,
      };
    }
    case ProductActionType.GET_PRODUCT_GROUP_DETAILS: {
      return {
        ...state,
        productLoader: true,
      };
    }
    case ProductActionType.UPDATING_PRODUCT_INFORMATION: {
      return {
        ...state,
        productLoader: true,
      };
    }

    case ProductActionType.UPDATE_PRODUCT_INFORMATION_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        productLoader: false,
        isError: false,
        submitted: true,
      };
    }
    case ProductActionType.UPDATE_STORE_PRICE: {
      return {
        ...state,
        productLoader: true,
      };
    }

    case ProductActionType.LOAD_SUCCESS: {
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
        productLoader: false,
      };
    }

    case ProductActionType.LOAD_FAILED: {
      return {
        ...state,
        product: {
          ...state.product,
          error: action.payload,
        },
        productLoader: false,
        isError: true,
        submitted: false,
      };
    }
    case ProductActionType.EXPORT_PRODUCT:
      return {
        ...state,
        exportLoader: true,
        exportFailure: {},
      };
    case ProductActionType.EXPORT_PRODUCT_SUCCESS:
      return {
        ...state,
        exportLoader: false,
        exportSuccess: true,
        exportFailure: {},
      };
    case ProductActionType.EXPORT_PRODUCT_ERROR:
      return {
        ...state,
        exportLoader: false,
        exportFailure: action.payload,
      };
    case ProductActionType.RESET_EXPORT:
      return {
        ...state,
        exportSuccess: false,
        exportFailure: {},
      };
    case ProductActionType.GET_RULES: {
      return {
        ...state,
        ruleLoader: true,
      };
    }
    case ProductActionType.GET_RULES_SUCCESS: {
      return {
        ...state,
        rules: action.payload,
        ruleLoader: false,
      };
    }

    case ProductActionType.GET_RULES_FAILURE: {
      return {
        ...state,
        ruleFailure: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
