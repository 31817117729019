import FEEDBACKS_API from '../../../api/feedbackAPI';
import { FeedbacksActionType } from '../ActionTypes';

const getFeedbacks = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS,
    });
    const res = await FEEDBACKS_API.getFeedbacks(params);
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS_FAILURE,
      payload: e,
    });
  }
};
const exportCsv = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS_CSV,
    });
    const res = await FEEDBACKS_API.exportCsv(params);
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS_CSV_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: FeedbacksActionType.GET_FEEDBACKS_CSV_ERROR,
      payload: e,
    });
  }
};
const resetExport = (dispatch: any) => {
  dispatch({
    type: FeedbacksActionType.RESET_EXPORT,
  });
};
export { getFeedbacks, exportCsv, resetExport };
