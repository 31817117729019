import axios from './axios';
import config from './config';

const STOCK_VALIDATION_API = {
  getStockRecords: (params: any) =>
    axios({
      url: `${config.endpoint.stockValidate}/${params.store_id}/${params.product_id}`,
      method: 'GET',
      params,
    }),
  exportCSV: (params: any) =>
    axios({
      url: `${config.endpoint.stockValidate}/xcel/${params.store_id}/${params.product_id}`,
      method: 'GET',
      params,
    }),
  getStockProductSalesReport: (params: any) =>
    axios({
      url: `${config.endpoint.stockProductSalesReport}/${params.store_id}/${params.product_id}`,
      method: 'GET',
    }),
  exportProductCSV: (params: any) =>
    axios({
      url: `${config.endpoint.stockProductSalesReport}/xcel/${params.store_id}/${params.product_id}`,
      method: 'GET',
      params,
    }),
};

export default STOCK_VALIDATION_API;
