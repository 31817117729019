import RECOMMENDATION_API from '../../../api/recommendationsAPI';
import { RecommendationActionTypes } from '../ActionTypes';

const getRecommendations = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RecommendationActionTypes.GET_RECOMMENDATION,
    });
    const res = await RECOMMENDATION_API.getRecommendations(params);
    dispatch({
      type: RecommendationActionTypes.GET_RECOMMENDATION_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RecommendationActionTypes.GET_RECOMMENDATION_FAILURE,
      payload: e,
    });
  }
};

const getRecommendationDetail =
  (recommendationId: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: RecommendationActionTypes.GET_RECOMMENDATION_DETAIL,
      });
      const res = await RECOMMENDATION_API.getRecommendationDetail(
        recommendationId
      );
      dispatch({
        type: RecommendationActionTypes.GET_RECOMMENDATION_DETAIL_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: RecommendationActionTypes.GET_RECOMMENDATION_DETAIL_FAILURE,
        payload: e,
      });
    }
  };

const createRecommendations = (data: any) => async (dispatch: any) => {
  dispatch({
    type: RecommendationActionTypes.CREATE_RECOMMENDATION_SUCCESS,
  });
  return RECOMMENDATION_API.createRecommendation(data).then(response => {
    if (response) {
      dispatch({
        type: RecommendationActionTypes.CREATE_RECOMMENDATION_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: RecommendationActionTypes.CREATE_RECOMMENDATION_FAILURE,
        payload: response,
      });
    }
    return response;
  });
};

const updateRecommendations = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS,
    });

    const res = await RECOMMENDATION_API.updateRecommendation(data);

    dispatch({
      type: RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: RecommendationActionTypes.UPDATE_RECOMMENDATION_FAILURE,
      payload: e,
    });
  }
};
const resetRecommendation = (dispatch: any) => {
  dispatch({
    type: RecommendationActionTypes.RESET_RECOMMENDATION,
  });
};

export default {
  getRecommendations,
  getRecommendationDetail,
  updateRecommendations,
  createRecommendations,
  resetRecommendation,
};
