/* eslint-disable react/jsx-no-useless-fragment */
import { cloneElement } from 'react';
import { Result } from 'antd';
import { PERMISSIONS } from '../util/permissionMapper';
import { getUserRole } from '../util';

const hasPermission = ({
  permissions,
  scopes,
}: {
  permissions: any;
  scopes: any;
}) => {
  const scopesMap: any = {};
  scopes.forEach((scope: any) => {
    scopesMap[scope] = true;
  });
  return permissions.some(
    (permission: string | number) => scopesMap[permission]
  );
};
const PermissionsGate = ({
  children,
  RenderError = () => (
    <Result
      status="403"
      title="Unauthorized"
      subTitle="Sorry, you are not authorized to access this page."
    />
  ),
  errorProps = null,
  scopes = [],
}: {
  children?: any;
  RenderError?: any;
  errorProps?: any;
  scopes?: any;
}) => {
  const role = getUserRole();
  const permissions = PERMISSIONS[role];
  let permissionGranted = false;
  if (permissions) {
    permissionGranted = hasPermission({ permissions, scopes });
  }
  if (role === 'admin' || role === 'Admin') {
    permissionGranted = true;
  }

  if (!permissionGranted && !errorProps) return <RenderError />;

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  return <>{children}</>;
};

export default PermissionsGate;
