import CATEGORY_API from '../../../api/categoryAPI';
import { CategoryActionType } from '../ActionTypes';

const getCategories = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CategoryActionType.GET_CATEGORY,
    });
    const res = await CATEGORY_API.getCategories(params);
    dispatch({
      type: CategoryActionType.GET_CATEGORY_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CategoryActionType.LOAD_FAILED,
      payload: e,
    });
  }
};
const getCategoriesDetails =
  (categoriesId: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: CategoryActionType.GET_CATEGORY_DETAIL,
      });
      const res = await CATEGORY_API.getCategoriesDetails(categoriesId);
      dispatch({
        type: CategoryActionType.GET_CATEGORY_DETAIL_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: CategoryActionType.LOAD_FAILED,
        payload: e,
      });
    }
  };

export { getCategories, getCategoriesDetails };
