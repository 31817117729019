import axios from './axios';
import config from './config';

const STORE_API = {
  getStores: (params: any) =>
    axios({
      url: `${config.endpoint.store}/`,
      method: 'GET',
      params,
    }),
  getStore: (storeId: string) =>
    axios({
      url: `${config.endpoint.store}/${storeId}`,
      method: 'GET',
    }),
  createStore: (data: Object) =>
    axios({
      url: `${config.endpoint.store}/create`,
      method: 'POST',
      data,
    }),
  updateStore: (data: Object) =>
    axios({
      url: `${config.endpoint.store}/update`,
      method: 'POST',
      data,
    }),
  getAttributes: () =>
    axios({
      url: `${config.endpoint.attributes}`,
      method: 'GET',
    }),
  updateAttributes: (data: Object) =>
    axios({
      url: `${config.endpoint.store}/attribute`,
      method: 'POST',
      data,
    }),
  exportStore: (params: any) =>
    axios({
      url: `${config.endpoint.store}/generate-csv`,
      method: 'GET',
      params,
    }),
  getStoreInventory: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.store_id}/inventories`,
      method: 'GET',
      params,
    }),
  getStoreInventoryShipment: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.store_id}/inventory-shipments`,
      method: 'GET',
      params,
    }),
  getStoreReturn: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.store_id}/inventory-returns`,
      method: 'GET',
      params,
    }),
  verifyInventoryShipment: (params: any) =>
    axios({
      url: `${config.endpoint.store}/verify-shipments`,
      method: 'GET',
      params,
    }),
  verifyReturn: (params: any) =>
    axios({
      url: `${config.endpoint.store}/verify-returns`,
      method: 'GET',
      params,
    }),
  getStockLedger: (storeId: string, productID: string) =>
    axios({
      url: `${config.endpoint.store}/${storeId}/ledger/${productID}`,
      method: 'GET',
    }),
  getInventoryCsv: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.storeId}/inventories-csv`,
      method: 'GET',
      params,
    }),
  getPosPaymentLog: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.storeId}/retailer/pos-payment/logs`,
      params,
    }),
  getStoreLedger: (params: any) =>
    axios({
      url: `${config.endpoint.store}/${params.storeId}/transaction/store-ledger`,
      method: 'GET',
      params,
    }),
  getSaleSummary: (storeId: any, params: any) =>
    axios({
      url: `${config.endpoint.store}/${storeId}/sales`,
      method: 'GET',
      params,
    }),
  getIncomeSummary: (storeId: any, params: any) =>
    axios({
      url: `${config.endpoint.store}/${storeId}/income`,
      method: 'GET',
      params,
    }),
  getStorePaymentInfo: (storeId: any) =>
    axios({
      url: `${config.endpoint.store}/${storeId}/payment-info`,
      method: 'GET',
    }),
};

// eslint-disable-next-line import/prefer-default-export
export default STORE_API;
