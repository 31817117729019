import USER_API from '../../../api/userAPI';
import { UserActionType } from '../ActionTypes';

const getUsers = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UserActionType.GET_USERS,
    });
    const res = await USER_API.getUsers(params);
    dispatch({
      type: UserActionType.GET_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UserActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const getUserById = (userId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: UserActionType.GET_USER,
    });
    const res = await USER_API.getUser(userId);
    dispatch({
      type: UserActionType.GET_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: UserActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const createUser = (data: object) => async (dispatch: any) => {
  dispatch({
    type: UserActionType.CREATE_USER,
  });
  await USER_API.createUser(data).then(response => {
    dispatch({
      type: UserActionType.CREATE_USER_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

const updateUser = (data: object) => async (dispatch: any) => {
  dispatch({
    type: UserActionType.UPDATE_USER,
  });
  return USER_API.updateUser(data).then(response => {
    dispatch({
      type: UserActionType.UPDATE_USER_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

export { getUsers, getUserById, createUser, updateUser };
