import axios from './axios';
import config from './config';

const TAG_API = {
  getTags: (params: any) =>
    axios({
      url: `${config.endpoint.tag}`,
      method: 'GET',
      params,
    }),

  getAssignedTagTypeDetails: (type: any, tagId: any, params: any) =>
    axios({
      url: `${config.endpoint.tag}/${type}/${tagId}`,
      method: 'GET',
      params,
    }),

  getTagDetails: (tagId: string) =>
    axios({
      url: `${config.endpoint.tag}/${tagId}`,
      method: 'GET',
    }),

  postCreateTag: (data: object) =>
    axios({
      url: `${config.endpoint.tag}/create`,
      method: 'POST',
      data,
    }),

  postUpdateTag: (data: object) =>
    axios({
      url: `${config.endpoint.tag}/update`,
      method: 'POST',
      data,
    }),

  deleteTag: (data: object) =>
    axios({
      url: `${config.endpoint.tag}/delete`,
      method: 'POST',
      data,
    }),

  assignTag: (data: object) =>
    axios({
      url: `${config.endpoint.tag}/assign`,
      method: 'POST',
      data,
    }),

  unassignTag: (data: object) =>
    axios({
      url: `${config.endpoint.tag}/unassign`,
      method: 'POST',
      data,
    }),

  searchDynamicItems: (url: any) =>
    axios({
      url: `${config.endpoint.tag}/related-entities?${url}`,
      method: 'GET',
    }),

  assignItems: (data: any) =>
    axios({
      url: `${config.endpoint.tag}/assign-all`,
      method: 'POST',
      data,
    }),
};

export default TAG_API;
