/* eslint-disable default-param-last */
import { SyncErrorLogActionType } from '../../actions/ActionTypes';

interface SyncErrorLogsReducer {
  syncErrorLogs: Object;
  loading: boolean;
  error: Object;
}

const initialState: SyncErrorLogsReducer = {
  syncErrorLogs: {},
  loading: false,
  error: false,
};

const reducer = (state: SyncErrorLogsReducer = initialState, action: any) => {
  switch (action.type) {
    case SyncErrorLogActionType.GET_SYNC_ERROR_LOGS:
      return {
        ...state,
        loading: true,
      };
    case SyncErrorLogActionType.GET_SYNC_ERROR_LOGS_SUCCESS:
      return {
        ...state,
        syncErrorLogs: action.payload,
        loading: false,
      };
    case SyncErrorLogActionType.GET_SYNC_ERROR_LOGS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        isError: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
