/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { Avatar, Badge, Card, Menu } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { rootSubmenuKeys } from '../util/constants';

import MenuItems from './menuItems';
import { getLastComponent } from '../util';

interface SidebarProps {
  collapsed?: boolean;
}

const AppSidebar: React.FC<SidebarProps> = ({ collapsed }: SidebarProps) => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(['Dashboard']);
  const { Meta } = Card;
  const user: any = localStorage.getItem('USER');
  const data = JSON.parse(user);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (location.pathname === '/') {
      setOpenKeys(['Dashboard']);
    }
    setTitle(getLastComponent());
  }, [location]);

  const currentMenuKey = (key: any) => {
    if (location.pathname.search(key) >= 0) {
      return location.pathname;
    }
    return key;
  };

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = ({ item }: any) => {
    if (item.props.title) {
      setOpenKeys([item.props.title]);
    }
    setTitle(getLastComponent());
  };

  const isOnline = window.navigator.onLine;

  const OLD_URL =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? `https://webv0.1knetworks.com`
      : process.env.REACT_APP_NODE_ENV === 'uat'
      ? `https://webv0-api-uat.1knetworks.com/`
      : `https://stage.1knetworks.com/`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      {data && (
        <Meta
          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
          title={
            <Badge
              color={isOnline ? 'green' : 'red'}
              text={
                <span style={{ color: '#A5ADB5', fontSize: 12 }}>
                  {!collapsed && data.name}
                </span>
              }
            />
          }
          description={
            <span style={{ color: '#A5ADB5', fontSize: 10 }}>
              {!collapsed && data.email}
            </span>
          }
          style={{
            margin: `0.5rem ${collapsed ? `15px` : '0'}`,
            padding: '0.5rem',
          }}
        />
      )}

      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={onClick}
        selectedKeys={[location.pathname]}
      >
        {MenuItems.map(link =>
          link.children ? (
            <Menu.SubMenu
              key={currentMenuKey(link.title)}
              icon={link.icon}
              title={link.title}
            >
              {link.children.map(child => (
                <Menu.Item
                  key={currentMenuKey(child.url)}
                  className={location.pathname === child.url ? 'active' : ''}
                >
                  {child.title}
                  <Link to={child.url} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={link.url !== '/' ? currentMenuKey(link.url) : link.url}
              icon={link.icon}
              title={link.title}
            >
              <Link to={link.url}>{link.title}</Link>
            </Menu.Item>
          )
        )}

        <Menu.Item icon={<DashboardOutlined />} danger>
          <a target="_blank" href={OLD_URL} rel="noreferrer">
            Old Panel
          </a>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default AppSidebar;
