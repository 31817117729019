import { Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import PermissionsGate from '../../services/permissionGate';
import { AsyncSelect } from '../../components';
import { SCOPES } from '../../util/permissionMapper';
import { useAppSelector } from '../../redux';
/* eslint-disable */

const DrawerP2RDiscount = ({
  onClose,
  visible,
  form,
  onFinish,
  loading,
  isEdit,
  displayCardId,
}: any) => {
  const { getP2RDiscountId } = useAppSelector(state => state.p2rdiscount);

  const validateDiscountByStorePercentage = (
    _: any,
    value: any,
    callback: any
  ) => {
    const discountPercentage = form.getFieldValue('discount_percentage');
    const numericValue = parseFloat(value);
    if (numericValue >= discountPercentage) {
      callback(
        'Discount By Store Percentage should not be greater than Discount Percentage'
      );
    } else {
      callback();
    }
  };

  return (
    <Drawer
      title="P2RDiscount Details"
      placement="right"
      destroyOnClose
      size={window.innerWidth > 768 ? 'large' : 'default'}
      closable={false}
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={form.submit}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {isEdit ? 'Save ' : 'Update '}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          {isEdit && (
            <Col span={12}>
              <PermissionsGate
                scopes={[SCOPES.updatePrimaryStoreId]}
                RenderError={() => (
                  <Form.Item
                    label="Stores"
                    name="store_id"
                    rules={[
                      { required: true, message: 'Please input your store!' },
                    ]}
                  >
                    <AsyncSelect
                      /* eslint-disable */
                      placeholder="type to search store"
                      url="stores"
                    />
                  </Form.Item>
                )}
              >
                {isEdit && (
                  <Form.Item
                    label="Store"
                    name="store_id"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your store!',
                      },
                    ]}
                  >
                    <AsyncSelect
                      /* eslint-disable */

                      placeholder="type to search store"
                      url="stores"
                    />
                  </Form.Item>
                )}
              </PermissionsGate>
            </Col>
          )}
          {!isEdit && (
            <Col span={12}>
              <Form.Item
                label="Store Id"
                name="store_id"
                rules={[
                  {
                    required: !isEdit,
                    message: 'Please input your store!',
                  },
                ]}
              >
                <AsyncSelect
                  /* eslint-disable */
                  disabled={!isEdit}
                  placeholder={
                    getP2RDiscountId?.store?.name || 'Type to search store'
                  }
                  url="stores"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="display_card_id"
              label="Display Card Id"
              initialValue={displayCardId}
              rules={[
                { required: true, message: ' Please Enter Display Card Id' },
              ]}
            >
              <Input
                style={{ width: '100%' }}
                disabled
                placeholder="display card id"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="discount_percentage"
              label="Discount Percentage"
              rules={[
                { required: true, message: 'Please enter discount percentage' },
              ]}
            >
              <Input
                onKeyPress={event => {
                  if (!/^\d*\.?\d*$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Please Enter Discount Percentage"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="discount_by_store_percentage"
              label="Discount By Store Percentage"
              rules={[
                {
                  required: true,
                  message: 'Please enter discount by store percentage',
                },
                { validator: validateDiscountByStorePercentage },
              ]}
            >
              <Input
                onKeyPress={event => {
                  if (!/^\d*\.?\d*$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={form.getFieldValue('discount_by_store_percentage')}
                placeholder={
                  isEdit
                    ? 'Please Enter Discount By Store Percentage'
                    : form.getFieldValue('discount_by_store_percentage')
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="is_disabled"
              label="Is Disabled"
              initialValue={isEdit ?? 0}
              hidden={isEdit}
            >
              <Select disabled={isEdit}>
                <Select.Option value={1}>True</Select.Option>
                <Select.Option value={0}>False</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default DrawerP2RDiscount;
