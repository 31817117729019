import TASK_APPROVAL_API from '../../../api/taskApprovalAPI';
import { TaskApprovalActionType } from '../ActionTypes';

const getTaskApprovals = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TaskApprovalActionType.GET_TASK_APPROVALS,
    });
    const res = await TASK_APPROVAL_API.getTaskApprovals(params);
    dispatch({
      type: TaskApprovalActionType.GET_TASK_APPROVALS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: TaskApprovalActionType.LOAD_FAILED,
      payload: e,
    });
  }
};

const updateStatusById = (data: object) => (dispatch: any) => {
  dispatch({
    type: TaskApprovalActionType.UPDATE_STATUS,
  });
  TASK_APPROVAL_API.postUpdateStatus(data).then(response => {
    dispatch({
      type: TaskApprovalActionType.UPDATE_STATUS_SUCCESS,
      payload: response.data.data,
    });
    return response;
  });
};

export { getTaskApprovals, updateStatusById };
