import axios from './axios';
import config from './config';

const UTIL_API = {
  getSignedURL: (url: string) =>
    axios({
      url: `${config.endpoint.util}/sign`,
      method: 'GET',
      params: { url_key: url },
    }),
};

// eslint-disable-next-line import/prefer-default-export
export default UTIL_API;
