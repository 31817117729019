import { AppActionType } from '../../actions/ActionTypes';

interface AppState {
  isLoggedInUser: boolean;
  loading: boolean;
  users: object;
  googleLoading: boolean;
  googleLoginData: object;
  error: object;
}

const initialState: AppState = {
  isLoggedInUser: false,
  loading: false,
  users: {},
  googleLoading: false,
  googleLoginData: {},
  error: {},
};

// eslint-disable-next-line default-param-last
const reducer = (state: AppState = initialState, action: any) => {
  switch (action.type) {
    case AppActionType.LOGIN_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case AppActionType.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        isLoggedInUser: true,
      };
    }
    case AppActionType.LOGOUT_USER: {
      return {
        ...state,
        loading: false,
        isLoggedInUser: false,
      };
    }
    case AppActionType.USERS_ERROR: {
      return {
        ...state,
        loading: false,
        isLoggedInUser: false,
      };
    }
    case AppActionType.VALIDATING_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case AppActionType.USER_VALIDATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    }
    case AppActionType.GOOGLE_LOGIN: {
      return {
        ...state,
        googleLoading: true,
      };
    }
    case AppActionType.GOOGLE_LOGIN_SUCCESS: {
      return {
        ...state,
        googleLoading: false,
        googleLoginData: action.payload,
      };
    }
    case AppActionType.GOOGLE_LOGIN_FAILURE: {
      return {
        ...state,
        googleLoading: false,
        error: action.payload,
      };
    }
    case AppActionType.GENERATE_RESET_TOKEN: {
      return {
        ...state,
        loading: true,
      };
    }
    case AppActionType.GENERATE_RESET_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AppActionType.GENERATE_RESET_TOKEN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case AppActionType.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case AppActionType.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AppActionType.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
