import { P2RDiscountType } from '../../actions/ActionTypes';

interface p2rDiscountState {
  getP2RDisount: Object;
  p2rloader: boolean;
  getP2RDiscountId: Object;
  getP2RDiscountIdDetails: Object;
}
const initialState: p2rDiscountState = {
  getP2RDisount: {},
  p2rloader: false,
  getP2RDiscountId: {},
  getP2RDiscountIdDetails: {},
};

const reducer = (state: p2rDiscountState = initialState, action: any = {}) => {
  switch (action.type) {
    case P2RDiscountType.START_FETCH_P2RDISCOUNT: {
      return {
        ...state,
        getP2RDisount: {},
        p2rloader: true,
      };
    }
    case P2RDiscountType.FINISH_FETCH_P2RDISCOUNT: {
      return {
        ...state,
        getP2RDisount: action.payload,
        p2rloader: false,
      };
    }
    case P2RDiscountType.LOAD_FAILED: {
      return {
        ...state,
        p2rloader: false,
        //   isError: true,
        error: action.payload,
      };
    }

    case P2RDiscountType.START_FETCH_P2RDISCOUNT_ID: {
      return {
        ...state,
        p2rloader: true,
      };
    }
    case P2RDiscountType.FINISH_FETCH_P2RDISCOUNT_ID: {
      return {
        ...state,
        getP2RDiscountId: action.payload,
        p2rloader: false,
      };
    }

    case P2RDiscountType.CREATE_P2RDISCOUNT_ID: {
      return {
        ...state,
        p2rloader: false,
      };
    }
    case P2RDiscountType.CREATE_P2RDISCOUNT_ID_SUCCESS: {
      return {
        ...state,
        //   postP2RDiscountId: action.payload,
        p2rloader: false,
      };
    }

    case P2RDiscountType.UPDATE_P2RDISCOUNT_ID: {
      return {
        ...state,
        p2rloader: false,
      };
    }
    case P2RDiscountType.UPDATE_P2RDISCOUNT_ID_SUCCESS: {
      return {
        ...state,
        //   postP2RDiscountId: action.payload,
        p2rloader: false,
      };
    }

    case P2RDiscountType.START_FETCH_P2RDISCOUNT_ID_DETAILS: {
      return {
        ...state,
        p2rloader: true,
      };
    }
    case P2RDiscountType.FINISH_FETCH_P2RDISCOUNT_ID_DETAILS: {
      return {
        ...state,
        getP2RDiscountIdDetails: action.payload,
        p2rloader: false,
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
