/* eslint-disable default-param-last */
import { StateActionType } from '../../actions/ActionTypes';

interface StateReducer {
  states: Object;
  stateLoader: boolean;
  stateError: Object;
  cities: any[];
  cityLoader: boolean;
  cityError: Object;
  pincodes: any[];
  pincodeLoader: boolean;
  pincodeError: Object;
}

const initialState: StateReducer = {
  states: {},
  stateLoader: false,
  stateError: false,
  cities: [],
  cityLoader: false,
  cityError: false,
  pincodes: [],
  pincodeLoader: false,
  pincodeError: false,
};

const reducer = (states: StateReducer = initialState, action: any) => {
  switch (action.type) {
    case StateActionType.GET_STATE:
      return {
        ...states,
        states: action.payload,
        stateLoader: false,
      };
    case StateActionType.GET_STATE_ERROR:
      return {
        ...states,
        stateError: true,
        stateLoader: false,
      };
    case StateActionType.STATE_LOADER_UPDATE:
      return {
        ...states,
        stateLoader: true,
      };
    case StateActionType.GET_CITY:
      return {
        ...states,
        cities: action.payload,
        cityLoader: false,
      };
    case StateActionType.GET_CITY_ERROR:
      return {
        ...states,
        cityError: true,
        cityLoader: false,
      };
    case StateActionType.CITY_LOADER_UPDATE:
      return {
        ...states,
        cityLoader: true,
      };
    case StateActionType.GET_PINCODE:
      return {
        ...states,
        pincodes: action.payload,
        pincodeLoader: false,
      };
    case StateActionType.ADD_PINCODE:
      return {
        ...states,
        pincodes: action.payload,
      };
    case StateActionType.GET_PINCODE_ERROR:
      return {
        ...states,
        pincodeError: true,
        cityLoader: false,
      };
    case StateActionType.PINCODE_LOADER_UPDATE:
      return {
        ...states,
        pincodeLoader: true,
      };
    default: {
      return states;
    }
  }
};

export default reducer;
