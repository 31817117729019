/* eslint-disable func-names */
import { SyncErrorLogActionType } from '../ActionTypes';
import SYNC_ERROR_LOG_API from '../../../api/syncErrorLogAPI';

const getSyncErrorLogs = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SyncErrorLogActionType.GET_SYNC_ERROR_LOGS,
    });
    const res = await SYNC_ERROR_LOG_API.getSyncErrorLogs(params);
    dispatch({
      type: SyncErrorLogActionType.GET_SYNC_ERROR_LOGS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: SyncErrorLogActionType.GET_SYNC_ERROR_LOGS_ERROR,
      payload: e,
    });
  }
};

const resetSyncErrorLogs = (dispatch: any) => {
  dispatch({
    type: SyncErrorLogActionType.RESET_SYNC_ERROR_LOGS,
  });
};

export { getSyncErrorLogs, resetSyncErrorLogs };
