/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import { FC } from 'react';
import { Descriptions, Button, Typography, Tag } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { getlocalStorage, isEmptyObject, removelocalStorage } from '../../util';
import { successNotification } from '../Notifications';

interface Props {
  getData: any;
  identifier?: string;
  isParamSuitable: boolean;
}

const Filters: FC<Props> = ({ getData, identifier, isParamSuitable }: any) => {
  const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const filters = getlocalStorage(`headers_${url}${identifier}`);
  let filterkey: any = [];
  let values = [];
  let dataIndex: any = [];
  if (filters && !isEmptyObject(filters)) {
    for (const [key, value] of Object.entries(filters)) {
      const newValue = `${value}`.replace(/like:|equal:/gi, '');
      dataIndex.push(key);
      const keyNew = key.split('.');
      filterkey.push(keyNew);
      values.push(newValue);
    }
  }

  const filterName = (name: string) => {
    if (name.length > 4 && name.slice(0, 4) === 'date') {
      return name.slice(5);
    }
    switch (name) {
      case '1': {
        return 'Active';
      }
      case '0': {
        return 'Inactive';
      }
      default: {
        return name;
      }
    }
  };

  const resetHandler = (label: any) => {
    removelocalStorage(`params_${url}${identifier}`);
    removelocalStorage(`headers_${url}${identifier}`);
    window.history.pushState('', '', url);
    getData(label);
  };

  const title = (
    <Typography.Text style={{ fontSize: 12 }}>Applied Filters</Typography.Text>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment

    filters &&
    !isEmptyObject(filters) && (
      <Descriptions
        // eslint-disable-next-line react/no-unstable-nested-components
        title={title}
        style={{
          background: 'white',
          padding: '10px 20px 0px 10px',
          marginBottom: 15,
          paddingBottom: 15,
        }}
        extra={
          <>
            <Button
              type="default"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                successNotification({
                  message: 'Success!!',
                  description: 'Copied to clipboard!',
                  placement: 'bottomLeft',
                });
              }}
              style={{ marginRight: '20px' }}
            >
              <LinkOutlined /> Copy
            </Button>
            <Button danger type="default" onClick={() => resetHandler(null)}>
              Reset
            </Button>
          </>
        }
        labelStyle={{
          fontSize: '12px',
          textTransform: 'capitalize',
          fontWeight: 600,
        }}
        contentStyle={{ fontSize: '12px', textTransform: 'capitalize' }}
        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 1 }}
      >
        {values.map((el: any, index: any) => {
          let label = '';
          if (filterkey[index].length > 1) {
            // eslint-disable-next-line prefer-destructuring
            label = filterkey[index][1];
          } else {
            label = filterkey[index];
          }
          return (
            <Descriptions.Item label={label}>
              <Tag
                style={{ fontSize: 14 }}
                closable
                onClose={() => resetHandler(dataIndex[index])}
              >
                {filterName(el)}
              </Tag>
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    )
  );
};

export default Filters;
