import { ImporterActionsTypes } from '../../actions/ActionTypes';

interface ImportersState {
  allImporters: Object;
  newImport: String;
  loader: boolean;
  error: boolean;
  importing: boolean;
  importerSuccess: boolean;
}

const initialState: ImportersState = {
  allImporters: {},
  newImport: '',
  loader: false,
  error: false,
  importing: false,
  importerSuccess: false,
};

const reducer = (state: ImportersState = initialState, action: any = {}) => {
  switch (action.type) {
    case ImporterActionsTypes.START_FETCH_ALL_IMPORTERS: {
      return {
        ...state,
        loader: true,
        importerSuccess: false,
      };
    }
    case ImporterActionsTypes.FINISH_FETCH_ALL_IMPORTERS: {
      return {
        ...state,
        allImporters: action.payload,
        loader: false,
        importerSuccess: false,
      };
    }

    case ImporterActionsTypes.START_ADD_NEW_IMPORT: {
      return {
        ...state,
        newImport: '',
        importing: true,
        importerSuccess: false,
      };
    }
    case ImporterActionsTypes.FINISH_ADD_NEW_IMPORT: {
      return {
        ...state,
        newImport: '',
        importing: false,
        importerSuccess: true,
      };
    }
    case ImporterActionsTypes.LOAD_FAILED: {
      return {
        ...state,
        newImport: '',
        importing: false,
        importerSuccess: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
