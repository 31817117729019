/* eslint-disable prefer-destructuring */
import { AgentActionType } from '../../actions/ActionTypes';

interface AgentState {
  agent: any;
  agentLoader: boolean;
  isError: boolean;
  submitted: boolean;
}

const initialState: AgentState = {
  agent: {},
  agentLoader: false,
  isError: false,
  submitted: false,
};

const reducer = (state: AgentState = initialState, action: any = {}) => {
  switch (action.type) {
    case AgentActionType.GET_AGENTS: {
      return {
        ...state,
        agentLoader: true,
      };
    }
    case AgentActionType.GET_AGENTS_SUCCESS: {
      return {
        ...state,
        agent: action.payload,
        agentLoader: false,
      };
    }
    case AgentActionType.LOAD_FAILED: {
      return {
        ...state,
        agent: {
          ...state.agent,
        },
        isError: true,
        agentLoader: false,
      };
    }
    case AgentActionType.GET_AGENT: {
      return {
        ...state,
        agentLoader: true,
      };
    }
    case AgentActionType.GET_AGENT_SUCCESS: {
      return {
        ...state,
        agent: action.payload,
        agentLoader: false,
      };
    }
    case AgentActionType.UPDATING_AGENT_INFORMATION: {
      return {
        ...state,
        agentLoader: true,
      };
    }

    case AgentActionType.UPDATE_AGENT_INFORMATION_SUCCESS: {
      return {
        ...state,
        agent: action.payload,
        agentLoader: false,
        isError: false,
        submitted: true,
      };
    }

    case AgentActionType.CREATING_AGENT_INFORMATION: {
      return {
        ...state,
      };
    }

    case AgentActionType.CREATE_AGENT_INFORMATION_SUCCESS: {
      action.payload.isNew = true;
      return {
        ...state,
        agent: {
          ...state.agent,
          results: [action.payload, ...state.agent.results],
          count: state.agent.count + 1,
        },
        agentLoader: false,
        isError: false,
        submitted: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
