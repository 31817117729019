import STATE_API from '../../../api/stateAPI';
import { StateActionType } from '../ActionTypes';

export interface State {
  stateLoader: boolean;
  isError: boolean;
  state?: {};
  states?: {};
  city?: [];
  cities?: [];
  pincode?: [];
  pincodes?: [];
}

const getStates = () => async (dispatch: any) => {
  try {
    const res = await STATE_API.getStates();
    dispatch({
      type: StateActionType.GET_STATE,
      payload: res.data,
    });
    dispatch({
      type: StateActionType.STATE_LOADER_UPDATE,
    });
  } catch (e) {
    dispatch({
      type: StateActionType.GET_STATE_ERROR,
      payload: e,
    });
  }
};

const getCities = (stateId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: StateActionType.GET_CITY,
    });
    const res = await STATE_API.getCities(stateId);
    dispatch({
      type: StateActionType.GET_CITY,
      payload: res.data,
    });
    dispatch({
      type: StateActionType.CITY_LOADER_UPDATE,
    });
  } catch (e) {
    dispatch({
      type: StateActionType.GET_CITY_ERROR,
      payload: e,
    });
  }
};

const getPincodes = (cityId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: StateActionType.GET_PINCODE,
    });
    const res = await STATE_API.getPincodes(cityId);
    dispatch({
      type: StateActionType.GET_PINCODE,
      payload: res.data.data,
    });
    dispatch({
      type: StateActionType.PINCODE_LOADER_UPDATE,
    });
  } catch (e) {
    dispatch({
      type: StateActionType.GET_PINCODE_ERROR,
      payload: e,
    });
  }
};

const addPincode =
  (pincodes: any[], pin: string, cityId: number) => async (dispatch: any) => {
    const pincode = {
      id: Math.floor(Math.random() * 10000),
      pincode: pin,
      cityId,
    };
    pincodes.push(pincode);
    dispatch({
      type: StateActionType.ADD_PINCODE,
      payload: pincodes,
    });
  };

export { getStates, getCities, addPincode, getPincodes };
